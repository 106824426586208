import { GlobalStyles, Icon } from '@andromeda'
import { DevTool } from '@hookform/devtools'
import { Autocomplete, Box, Button, Chip, FormControl, Stack, TextField, Typography } from '@mui/material'
import deepObjectCompare from '@utils/functions/deepObjectCompare'
import useDebouncedCallback from '@utils/hooks/useDebouncedCallback'
import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

const ALL_OPTION = { label: 'All', value: 'all' }

export interface iSearchFilters {
  key: string
  label: string
  isCustomField?: boolean
  options: Array<{ label: string; value: string }>
}

export const SearchFilter = ({
  filters = [],
  SearchLeftSide,
  searchPlaceholder,
  onChangeFilter,
  centered = false,
  searchKey = 'search',
  noFilterMsgText,
}: {
  filters: iSearchFilters[]
  SearchLeftSide?: JSX.Element
  searchPlaceholder?: string
  onChangeFilter: (params: any) => void
  centered?: boolean
  searchKey?: string
  noFilterMsgText?: string
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string | number }>({})
  const [queryString, setQueryString] = useState<string>('')

  const form = useForm()

  const filterables: iSearchFilters[] = useMemo(() => (showMore ? filters : filters.slice(0, 3)), [showMore, filters])

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    // custom field filters
    const cfFiltersKeys = filters.filter((filter) => filter.isCustomField).map((cf) => cf.key)
    const cfFilters: Record<string, string | number> = {}
    // always reset page to 1 when filters change
    const nextFilters: Record<string, string | number | Record<string, string | number>> = { page: 1 }

    Object.keys(newFilters).forEach((key) => {
      if (cfFiltersKeys.includes(key)) {
        cfFilters[key] = newFilters[key]
      } else {
        nextFilters[key] = newFilters[key]
      }
    })

    if (Object.keys(cfFilters).length) {
      nextFilters['custom_fields'] = cfFilters
    }

    // if filters are the same, don't broadcast the change
    if (!deepObjectCompare(selectedFilters, newFilters)) {
      onChangeFilter(nextFilters)
    }

    // set new filters for the component
    setSelectedFilters(newFilters)
  }

  const handleSearch = () => {
    const newSelectedFilters = structuredClone(selectedFilters)

    if (!queryString.trim()) {
      delete newSelectedFilters[searchKey]
    } else {
      newSelectedFilters[searchKey] = queryString
    }

    handleFilterChange(newSelectedFilters)
  }

  const debouncedHandleSearch = useDebouncedCallback(handleSearch, 500)

  const _setFilterFromDropdown = (e: any, parentKey: string) => {
    const newSelectedFilters = structuredClone(selectedFilters)

    if (e.value.toLowerCase() === 'all') {
      delete newSelectedFilters[parentKey]
    } else {
      newSelectedFilters[parentKey] = e.value
    }

    handleFilterChange(newSelectedFilters)
  }

  const _removeFilterFromDropdown = (filterKey: string) => {
    const newSelectedFilters = { ...selectedFilters }

    delete newSelectedFilters[filterKey]

    if (filterKey === searchKey) {
      setQueryString('')
    } else {
      form.resetField(filterKey, { defaultValue: ALL_OPTION })
    }

    handleFilterChange(newSelectedFilters)
  }

  const clearFilters = () => {
    form.reset(filterables.map(({ key }) => ({ [key]: ALL_OPTION })))
    setQueryString('')
    handleFilterChange({})
  }

  useEffect(() => {
    debouncedHandleSearch()
  }, [queryString])

  return (
    <Stack padding="24px" rowGap="24px">
      <Stack
        direction={{ xxs: 'column', sm: 'row' }}
        flexWrap="wrap"
        columnGap="12px"
        rowGap="12px"
        justifyContent="space-between"
        flexBasis={{ xxs: '100%', md: 'unset' }}
        marginTop="10px">
        {!!SearchLeftSide && <Box flexBasis={{ sm: '100%', md: 'calc(100% - 312px)' }}>{SearchLeftSide}</Box>}
        <Box justifyContent={'flex-end'} flexBasis={{ sm: '100%', md: '300px' }} flexGrow={1}>
          <TextField
            placeholder={searchPlaceholder}
            InputProps={{ startAdornment: <Icon.Search size={18} /> }}
            value={queryString}
            sx={{ width: '100%' }}
            onChange={(e) => setQueryString(e.target.value)}
          />
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="end">
        <Stack direction="row" justifyContent="space-between" width={centered ? '100%' : '300px'}>
          <Button variant="outlined" color="primary" onClick={clearFilters}>
            Clear Filters
          </Button>
          <Button variant="outlined" color="primary" onClick={() => setShowFilters((prev) => !prev)} startIcon={<Icon.Sliders size={15} />}>
            Filters
          </Button>
        </Stack>
      </Stack>
      {showFilters && (
        <Stack direction="row" justifyContent={centered ? 'start' : 'end'} gap="8px" flexWrap="wrap">
          {!!filters.length ? (
            <form>
              <FormProvider {...form}>
                <Stack direction="row" justifyContent={centered ? 'start' : 'end'} gap="8px" flexWrap="wrap">
                  <DevTool control={form.control} placement="top-left" />
                  {filterables.map((filter) => (
                    <Stack direction="row" key={filter.key} alignItems="center" columnGap="8px">
                      <Typography variant="h6">{filter.label}</Typography>
                      <FormControl variant="standard">
                        <Controller
                          name={filter.key}
                          control={form.control}
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              size="small"
                              sx={{ minWidth: '80px' }}
                              disableClearable
                              options={filter.options}
                              key={value}
                              renderInput={(params) => <TextField {...params} variant="standard" />}
                              defaultValue={filter.options[0]}
                              value={value}
                              getOptionLabel={(option) => option.label}
                              onChange={(e: any, val) => {
                                onChange(val)
                                _setFilterFromDropdown(val, filter.key)
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                  ))}
                </Stack>
              </FormProvider>
            </form>
          ) : (
            <Typography>{noFilterMsgText || 'No filters available.'}</Typography>
          )}

          {filters.length > 3 && (
            <Button
              variant="text"
              startIcon={showMore ? <Icon.Minus /> : <Icon.Plus />}
              onClick={() => setShowMore((prev) => !prev)}
              sx={centered ? { width: '100%' } : {}}>
              {showMore ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </Stack>
      )}

      <Stack direction="row" flexWrap="wrap" rowGap="14px" columnGap="8px" justifyContent="end">
        {Object.keys(selectedFilters).map((filterKey) => {
          const filterObj = filters.find((filter) => filter.key === filterKey)
          const filterValue =
            filterObj?.options.find((option) => option.value === selectedFilters[filterKey])?.label || selectedFilters[filterKey]
          let filterLabel = filterObj?.label || filterKey

          if (!filterObj && filterKey === searchKey) {
            filterLabel = 'Search'
          }

          return (
            <Chip
              style={{
                color: GlobalStyles.MONO_BLACK,
                backgroundColor: GlobalStyles.SLATE_100,
                fontSize: `${GlobalStyles.FONT_SIZES.TINY}px`,
                padding: '6px',
                borderRadius: '12px',
              }}
              key={filterKey}
              size="small"
              label={
                <Stack alignItems="center" direction="row" gap="5px">
                  {`${filterLabel ? `${filterLabel}:` : ''} ${filterValue}`}
                  <Icon.XCircle size={13} cursor="pointer" onClick={() => _removeFilterFromDropdown(filterKey)} />
                </Stack>
              }
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
