import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Card,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
  formControlClasses,
  formLabelClasses,
  inputBaseClasses,
  outlinedInputClasses,
  selectClasses,
  stackClasses,
  textFieldClasses,
} from '@mui/material'
import { FieldError, useController, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { ControlledSelect } from '../search/searchtag.new'
import { Textfield } from '../form/Textfield'
import { DevTool } from '@hookform/devtools'
import { useContext, useMemo } from 'react'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { Types } from '@orbit'
import { FormAutoComplete } from '../form/AutoComplete'
import { useTranslation } from '@utils/hooks/useTranslation'

export const Header = ({ icon, title, isEditMode, setEditMode }: tCustomFields & { icon: JSX.Element; title: string }) => (
  <Stack
    padding="8px 24px"
    direction={'row'}
    alignItems={'center'}
    justifyContent={'space-between'}
    borderBottom={`1px solid ${GlobalStyles.SLATE_100}`}>
    <Stack minHeight="30px" padding="0" direction="row" columnGap="12px" alignItems="center">
      {icon}
      <Typography color={GlobalStyles.SLATE_400} variant={'caption'}>
        {title.toUpperCase()}
      </Typography>
      <Tooltip text={title} />
    </Stack>
    {!isEditMode && (
      <IconButton onClick={setEditMode} style={{ height: 30, width: 30, borderRadius: 8 }} aria-label="Edit">
        <Icon.Edit2 size={12} color={GlobalStyles.PRIMARY_500} />
      </IconButton>
    )}
  </Stack>
)

export const CUSTOM_FIELD_TYPES = [
  { id: 'Textfield', value: 'Textfield' },
  { id: 'Dropdown', value: 'Dropdown' },
]
const DEFAULT_VALUE = { type: CUSTOM_FIELD_TYPES[0], required: false, enabled: true }

export const CustomField = ({
  fieldNamePrefix,
  disabled,
  filterable = false,
  onRemove,
}: {
  fieldNamePrefix: string
  disabled?: boolean
  filterable?: boolean
  onRemove?: (key: string) => void
}) => {
  const form = useFormContext()
  const fieldValues = useWatch({ control: form.control, name: `${fieldNamePrefix}.type` })
  const dropdownOptions = useWatch({ control: form.control, name: `${fieldNamePrefix}.list_options` })
  const controlledRequired = useController({ name: `${fieldNamePrefix}.required`, control: form.control })
  const controlledEnabled = useController({ name: `${fieldNamePrefix}.enabled`, control: form.control })
  const controlledFilterable = useController({ name: `${fieldNamePrefix}.filterable`, control: form.control, defaultValue: false })
  const { key } = useWatch({ control: form.control, name: fieldNamePrefix })

  const listDropdownOptions = useMemo(() => {
    return dropdownOptions?.split(',').map((option: string) => {
      const _option = option.trim()
      return { label: _option, value: _option }
    })
  }, [dropdownOptions])

  const mediaBreakpointSM = `@media (max-width: 450px)`
  const mediaBreakpointMD = `@media (max-width: 950px)`

  return (
    <Stack
      padding="12px 24px"
      sx={{
        borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
        [`.${stackClasses.root}>.${formControlClasses.root}`]: { maxWidth: 'calc(50% - 8px)' },
        [`.${formLabelClasses.root}`]: {
          fontSize: '14px',
          color: GlobalStyles.MONO_BLACK,
          minWidth: 'fit-content',
          fontWeight: 500,
        },
        [`.${formControlClasses.root} .${textFieldClasses.root}`]: { width: '100%' },
        [`.${formControlClasses.root}>.${stackClasses.root}`]: { flexDirection: 'row', minWidth: '180px', columnGap: '4px' },
        [`.${selectClasses.outlined}.${inputBaseClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: GlobalStyles.SLATE_100,
        },
        [`${mediaBreakpointMD}`]: {
          [` > .${stackClasses.root}`]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            rowGap: '12px',
          },
          [` > .${stackClasses.root} > .${formControlClasses.root}`]: {
            minWidth: '100%',
            width: '100%',
          },
        },
        [`${mediaBreakpointSM}`]: {
          [` > .${stackClasses.root} > .${formControlClasses.root}`]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            rowGap: '8px',
          },
          [` > .${stackClasses.root} > .${formControlClasses.root} > .${stackClasses.root}`]: {
            minWidth: '100%',
            width: '100%',
          },
        },
      }}
      rowGap="12px">
      <Stack direction="row" justifyContent="space-between" columnGap="8px">
        <FormControl sx={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Stack>
            <FormLabel htmlFor="display_name">Field Label</FormLabel>
            <Tooltip text="" />
          </Stack>
          <Textfield
            id="display_name"
            name={`${fieldNamePrefix}.display_name`}
            placeholder="Region Membership"
            required
            disabled={disabled}
            InputProps={{
              onBlur: () => {
                const [fieldName, _index] = fieldNamePrefix.split('.')
                const { error } = form.getFieldState(fieldName)

                if (Array.isArray(error)) {
                  for (const fieldError of error.filter((e) => !!e)) {
                    for (const [key, fieldErrorVal] of Object.entries(fieldError) as [string, FieldError][]) {
                      if (fieldErrorVal.ref && key === 'display_name' && fieldErrorVal.ref.name !== `${fieldNamePrefix}.display_name`) {
                        form.clearErrors(fieldErrorVal.ref.name)
                      }
                    }
                  }
                }

                form.trigger(fieldNamePrefix)
              },
            }}
          />
        </FormControl>
        <Stack
          flexBasis="300px"
          maxWidth="300px"
          direction="row"
          columnGap="8px"
          sx={{
            [`${mediaBreakpointMD}`]: {
              paddingLeft: '180px',
            },
            [`${mediaBreakpointSM}`]: {
              paddingLeft: '0',
            },
          }}>
          <Stack
            sx={{
              borderLeft: `1px solid ${GlobalStyles.SLATE_100}`,
              minHeight: '22px',
              [`${mediaBreakpointMD}`]: { display: 'none' },
            }}
          />
          <ControlledSelect
            noSearch
            secondary
            name={`${fieldNamePrefix}.type`}
            options={CUSTOM_FIELD_TYPES}
            style={{
              minWidth: 'auto',
              width: '100%',
            }}
            disabled={disabled}
          />
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" columnGap="8px">
        {fieldValues?.id === 'Dropdown' && (
          <FormControl sx={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <Stack>
              <FormLabel htmlFor="list_options">Dropdown options</FormLabel>
              <Tooltip text="" />
            </Stack>
            <Textfield
              id="list_options"
              name={`${fieldNamePrefix}.list_options`}
              placeholder="Asian, UK, US, Europe, Africa"
              InputProps={{ onBlur: () => form.trigger(fieldNamePrefix) }}
              disabled={disabled}
            />
          </FormControl>
        )}
        <FormControl sx={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Stack
            sx={
              fieldValues?.id === 'Dropdown'
                ? {
                    paddingLeft: '1rem',
                    paddingRight: '1.5rem',
                    [`${mediaBreakpointMD}`]: {
                      paddingLeft: '0',
                      paddingRight: '0',
                      maxWidth: '300px',
                    },
                  }
                : {}
            }>
            <FormLabel htmlFor="default_value">Default Value</FormLabel>
            <Tooltip text="" />
          </Stack>

          {fieldValues?.id === 'Dropdown' ? (
            <FormAutoComplete
              name={`${fieldNamePrefix}.default_value`}
              fieldValue="value"
              style={{ minWidth: 'auto', width: '100%' }}
              options={listDropdownOptions}
              textfieldProps={{ placeholder: 'Select an option' }}
              disabled={disabled || !dropdownOptions}
            />
          ) : (
            <Textfield id="default_value" name={`${fieldNamePrefix}.default_value`} placeholder="Europe" disabled={disabled} />
          )}
        </FormControl>
      </Stack>
      <Stack direction="row" columnGap="24px" justifyContent="space-between">
        <Stack direction="row" columnGap="24px" flexWrap="wrap" rowGap="8px">
          <FormControl sx={{ flexDirection: 'row', alignItems: 'center', columnGap: '8px' }}>
            <FormLabel htmlFor="required">Required</FormLabel>
            <Switch
              id="required"
              name={`${fieldNamePrefix}.required`}
              onChange={(e) => controlledRequired.field.onChange(e.target.checked)}
              checked={controlledRequired.field.value}
              disabled={disabled}
            />
          </FormControl>
          <FormControl sx={{ flexDirection: 'row', alignItems: 'center', columnGap: '8px' }}>
            <Stack minWidth="unset !important">
              <FormLabel htmlFor="enabled">Enabled</FormLabel>
              <Tooltip text="" />
            </Stack>
            <Switch
              id="enabled"
              name={`${fieldNamePrefix}.enabled`}
              defaultChecked
              onChange={(e) => controlledEnabled.field.onChange(e.target.checked)}
              checked={controlledEnabled.field.value}
              disabled={disabled}
            />
          </FormControl>
          {fieldValues?.id === 'Dropdown' && filterable && (
            <FormControl sx={{ flexDirection: 'row', alignItems: 'center', columnGap: '8px' }}>
              <Stack minWidth="unset !important">
                <FormLabel htmlFor="filterable">Filterable</FormLabel>
                <Tooltip text="Making the field filterable will enable this field to be selected as a filter in the talent profile search forms" />
              </Stack>
              <Switch
                id="filterable"
                name={`${fieldNamePrefix}.filterable`}
                defaultChecked
                onChange={(e) => controlledFilterable.field.onChange(e.target.checked)}
                checked={controlledFilterable.field.value}
                disabled={disabled}
              />
            </FormControl>
          )}
        </Stack>

        {!key && (
          <FormControl sx={{ flexDirection: 'row', alignItems: 'center', float: 'right' }}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              startIcon={<Icon.X size={16} />}
              onClick={() => onRemove?.(key)}
              style={{ minHeight: 'auto', padding: '3px 9px' }}
              disabled={disabled}>
              Cancel
            </Button>
          </FormControl>
        )}
      </Stack>
    </Stack>
  )
}
export const CustomFieldWrapper = ({ fieldName, ...props }: tCustomFields & { fieldName: string }) => {
  const form = useFormContext()

  const { fields, append, remove } = useFieldArray({ control: form.control, name: fieldName })
  const fieldValues = useWatch({ control: form.control, name: fieldName })
  const hasEmpty = useMemo(() => {
    return fieldValues?.some((field: Types.Workspaces.iCustomField) => {
      return !field.display_name || !field.type
    })
  }, [fieldValues])

  const { t } = useTranslation('talent')

  const getTranslatedFieldName = () => {
    if (fieldName === 'talent') {
      return t('Talent')
    }
    return fieldName
  }

  return (
    <Card>
      <Header title={`custom ${getTranslatedFieldName()} fields`} icon={<Icon.Settings size={12} />} {...props} />
      {!!fields.length &&
        fields.map((field, index) => {
          const fieldNamePrefix = `${fieldName}.${index}`

          return (
            <CustomField
              key={field.id}
              fieldNamePrefix={fieldNamePrefix}
              disabled={!props.isEditMode}
              filterable={fieldName === 'talent'}
              onRemove={() => remove(index)}
            />
          )
        })}

      <Button
        variant="text"
        startIcon={<Icon.PlusCircle size={16} />}
        sx={{ paddingLeft: '24px' }}
        onClick={() => {
          form.clearErrors(fieldName)

          if (!hasEmpty) {
            append(DEFAULT_VALUE)
          } else {
            form.trigger(fieldName, { shouldFocus: true })
          }
        }}
        disabled={!props.isEditMode}>
        Add new
      </Button>
    </Card>
  )
}

export const customFieldSchema = yup.array().of(
  yup
    .object()
    .shape({
      display_name: yup.string().required('Field label is required.'),
      type: yup.object({
        id: yup.string(),
        value: yup.string(),
      }),
      default_value: yup.string().notRequired(),
      list_options: yup
        .string()
        .when('type', (type, schema) => {
          if (type.length && type[0].id === 'Dropdown') {
            return schema.required('List options should not be empty.')
          }

          return schema
        })
        .test('unique', 'Dropdown Options must be unique', (cField, ctx) => {
          if (cField) {
            const options = cField.split(',').map((option: string) => option.trim().toLowerCase())
            const uniqueOptions = options.filter((option: string, index: number) => options.indexOf(option.toLowerCase()) === index)

            if (options.length !== uniqueOptions.length) {
              return ctx.createError({ path: ctx.path, message: 'Dropdown Options must be unique.' })
            }
          }

          return true
        }),
      required: yup.boolean(),
      enabled: yup.boolean(),
    })
    .test('unique', 'Field Label must be unique', (cField, ctx) => {
      const _display_name = cField.display_name?.toLowerCase()
      const occurances = ctx.parent.filter((field: Types.Workspaces.iCustomField) => field.display_name?.toLowerCase() === _display_name)

      if (occurances.length > 1) {
        return ctx.createError({ path: `${ctx.path}.display_name`, message: 'Field Label must be unique.' })
      }

      return true
    })
)
const validationSchema = yup.object().shape({
  talent: customFieldSchema,
  job: customFieldSchema,
  deliverable: customFieldSchema,
})

type tCustomFields = { isEditMode: boolean; setEditMode: () => void }

export const CustomFields = (props: tCustomFields) => {
  const { features } = useContext(FeatureFlagsContext)

  const form = useForm({
    resolver: yupResolver(validationSchema),
  })

  return (
    <Stack rowGap="10px">
      {features.isTalentCustomFieldsEnabled && <CustomFieldWrapper fieldName="talent" {...props} />}
      {features.isJobCustomFieldsEnabled && <CustomFieldWrapper fieldName="job" {...props} />}
      {features.isDeliverableCustomFieldsEnabled && <CustomFieldWrapper fieldName="deliverable" {...props} />}
    </Stack>
  )
}
