import { Deliverable } from './deliverable'
import { Events } from './events'
import { Invite } from './invite'
import { Location } from './location'
import { Meta } from './meta'
import { Organisation } from './organisations'
import { Talent } from './talent'
import { User } from './user'
export namespace Job {
  export interface iJob {
    eid: string
    name: string
    description: string | null
    created_at: string | null
    updated_at: string | null
    deleted_at: string | null
    organisation: { name: string; eid: string } | null
    department: { name: string; eid: string } | null
    client?: string | null
    number_of_talents_required?: string | number | null
    number_of_talents_booked: number | null
    start_datetime: string | null
    end_datetime: string | null
    job_cost?: number | null
    job_currency_code: string
    job_budget: number
    cost_mode: 'PER_JOB' | 'PER_DELIVERABLE'
    total_fee?: {
      fee_amount: number
      fee_currency_code: string
    }
    budget_type: string | null
    date_type: string | null
    shortlisted_talents?: Talent.iTalent[] | null
    event_types?: Events.iEventTypes[]
    location?: Location.iLocation
    deliverables?: Deliverable.iDeliverable[]
    permissions?: string[]
    event_type_eids?: string[]
    owner?: iJobOwner
    status: Job.StatusTypes
    is_lead?: boolean
    description_formatted?: string | null
    external_client?: iJobExternal['external_client']
    files?: iFile[]
    custom_field_data?: Record<string, string>
    has_social_campaign: boolean
  }

  export interface iJobPayload extends Omit<iJob, 'organisation' | 'department'> {
    organisation_eid: string | null
    department_eid: string | null
    custom_fields?: Record<string, string>
  }

  export interface iJobPlusPayload extends Omit<iJob, 'organisation' | 'department' | 'deliverables'> {
    organisation_eid: string | null
    department_eid: string | null
    deliverables?: Deliverable.iDeliverablePayload[]
    users?: User.iUserUpdatePayload[]
    invites?: Invite.iInviteCreatePayload[]
  }

  export interface iJobResponse {
    data: iJob
    status: number
  }

  export enum iJobType {
    ADDED = 'added',
    OWNED = 'owned',
    MANAGED = 'managed',
  }

  // Fetch All Jobs types
  // because UrlSearchParams only wants strings...
  export interface iJobListQueryParams {
    page?: string
    statuses?: Array<string>
    per_page?: string
    has_shortlisted?: string
    deliverable_statuses?: string[]
    deliverable_agreements_statuses?: string[]
    has_managed_shortlisted?: string
    talents?: string[]
    owners?: string[]
    is_lead?: boolean
    show_aggregations_only?: boolean
  }

  export interface iJobEventTypes {
    eid: string
    name: string
  }

  export interface iJobList
    extends Pick<
      iJob,
      | 'eid'
      | 'name'
      | 'description'
      | 'client'
      | 'number_of_talents_required'
      | 'created_at'
      | 'updated_at'
      | 'organisation'
      | 'department'
      | 'start_datetime'
      | 'end_datetime'
      | 'date_type'
      | 'budget_type'
      | 'cost_mode'
      | 'job_cost'
      | 'job_currency_code'
      | 'job_budget'
      | 'is_lead'
      | 'status'
      | 'event_types'
    > {
    source: string
    is_archived: boolean
    numberOfStarsBooked: number
    numberOfStarsInvited: number
    numberOfDeliverablesCompleted: number
    numberOfDeliverables: number
    location_city?: string
    location_state?: string
    location_country?: string
  }

  export interface iJobAggregates {
    deliverable_statuses: Meta.iBucket
    workspaces: Meta.iBucket
    status: Meta.iBucket
  }

  export interface iJobListMeta {
    aggregations: iJobAggregates
    pagination: Meta.iPagination
  }

  export interface iJobsResponse {
    data: iJobList[]
    meta: iJobListMeta
    status: number
  }

  export interface iJobOwner {
    eid: string
    name: string
    created_at?: string
    updated_at?: string
    profile_image: User.iProfileImage | null
  }

  export interface iJobStatusUpdateResponse {
    data: {
      status: string
    }
  }

  export interface iJobUsersResponse {
    users: Array<User.iUser>
    invites: Array<User.iUser & { note?: string }>
  }

  export interface iJobSyncPayload {
    owners: Array<string>
    viewers: Array<string>
    invites?: Array<Omit<Invite.iInviteCreatePayload, 'role'> & { ownership_type: User.iUser['ownership_type'] }>
  }

  export interface iDeliverableUsersResponse {
    users: Array<User.iUser>
    invites: Array<User.iUser & { note?: string }>
  }

  export interface iDeliverableSyncPayload {
    owners: Array<string>
    viewers: Array<string>
    invites?: Array<Omit<Invite.iInviteCreatePayload, 'role'> & { ownership_type: User.iUser['ownership_type'] }>
  }

  export enum StatusEnums {
    DEFAULT = 'DEFAULT',
    DRAFT = 'DRAFT',
    LIVE = 'LIVE',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    BOOKED = 'BOOKED',
    OVERDUE = 'OVERDUE',
    NEW = 'NEW',
    APPLIED = 'APPLIED',
    DECLINED = 'DECLINED',
    REMOVED = 'REMOVED',
    WITHDRAWN = 'WITHDRAWN',
    ARCHIVED = 'ARCHIVED',
  }

  export type StatusTypes =
    | 'DEFAULT'
    | 'DRAFT'
    | 'LIVE'
    | 'COMPLETED'
    | 'CANCELLED'
    | 'BOOKED'
    | 'OVERDUE'
    | 'NEW'
    | 'APPLIED'
    | 'DECLINED'
    | 'REMOVED'
    | 'WITHDRAWN'
    | 'ARCHIVED'

  export interface iExternalClient {
    eid?: string
    name: string
    email: string
    phone_number?: string | null
    organisation: string | null | Organisation.iOrganisation
    is_accepted?: boolean
  }

  export interface iJobExternal extends Omit<iJob, 'department' | 'organisation'> {
    external_client: iExternalClient
    department: string | null
    organisation: string | null | iJob['organisation']
  }

  export interface iJobAggregates {
    deliverable_statuses: Meta.iBucket
    is_archived: Meta.iBucket
    is_lead: Meta.iBucket
    owners: Meta.iBucket
    source: Meta.iBucket
    workspaces: Meta.iBucket
    deliverable_agreements_statuses: Meta.iBucket
    status: Meta.iBucket
    total_results: number
  }

  export interface iAssignTalentToJob {
    assign: Array<{
      job_eid: string
      agreement: Array<{ talent_eid: string; status?: string }>
    }>
  }
  export interface iFile {
    eid: string
    file_format: string
    original_file_name: string
    initial_file_type: string
    full_url: string
    upload_presign_url: string
    download_presign_url: string
  }

  export interface iAttachFilesPayload {
    files: string[]
  }

  export interface iJobSocial {
    eid: string
    name: string
    hashtags: string
    start_datetime: string
    end_datetime: string
    reach_num: number
    social_campaign_id: number
    impressions_num: number
    posts_num: number
    influencers_num: number
  }
  export interface iCreateCampaignPayload {
    start_datetime: string
    end_datetime: string
    hashtags: string
    requirements: {
      Twitter?: number
      Instagram?: number
      Tiktok?: number
    }
  }
}
