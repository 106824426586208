import { GlobalStyles } from '@andromeda'
import { IconButton, InputProps, formControlClasses, textFieldClasses } from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { removeTimeFormat } from '@utils/functions/helperFunctions'
import { Moment } from 'moment'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

const getError = (name: string = '', formState: any) =>
  Object.keys(formState.errors).length && !!name
    ? name.split('.').length === 1
      ? formState.errors[name]
      : name.split('.').reduce((acc, curr) => {
          return acc?.[curr]
        }, formState.errors)
    : undefined

export const FormDateRangePicker = ({
  startDateName,
  endDateName,
  InputProps,
  sx,
  format,
  ...props
}: DateRangePickerProps<Moment> & { InputProps?: InputProps; startDateName?: string; endDateName?: string }) => {
  const { settings: workspaceSettings } = useAppSelector(selectWorkspace)
  const { formState } = useFormContext()

  const startDateError = getError(startDateName, formState)
  const endDateError = getError(endDateName, formState)

  const dateFormat = useMemo(() => {
    if (format) {
      return removeTimeFormat(format)
    }

    return workspaceSettings?.date_format && removeTimeFormat(workspaceSettings?.date_format)
  }, [workspaceSettings?.date_format, format])

  return (
    <DateRangePicker
      format={dateFormat}
      sx={{
        [`&.${formControlClasses.root}.${textFieldClasses.root}`]: {
          minWidth: { xs: '230px' },
        },
        '.MuiInputBase-root.MuiOutlinedInput-root': {
          borderRadius: '6px',
        },
        '.MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '9px 0px 9px 12px',
          fontSize: '14px',
          minHeight: '40px',
          boxSizing: 'border-box',
        },
        '.MuiInputAdornment-positionEnd .MuiButtonBase-root.MuiIconButton-root': {
          paddingLeft: 0,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: GlobalStyles.SLATE_100,
        },
        ...sx,
      }}
      slots={{ field: SingleInputDateRangeField }}
      slotProps={{
        textField: {
          error: startDateError || endDateError,
          helperText: startDateError?.message ?? endDateError?.message ?? '',
        },
        actionBar: {
          actions: ['clear', 'accept'],
        },
        field: {
          // @ts-expect-error
          inputProps: {
            ...InputProps,
          },
          InputProps: {
            placeholder: '',
            endAdornment: (
              <IconButton sx={{ padding: 0 }}>
                <CalendarIcon
                  style={{
                    color: GlobalStyles.SLATE_500,
                  }}
                />
              </IconButton>
            ),
            ...InputProps,
          },
        },
        ...props.slotProps,
      }}
      {...props}
    />
  )
}
