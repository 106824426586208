import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { JobStatusTypes } from '@utils/types/jobs'
import { useRouter } from 'next/router'
import React from 'react'
import { FeatureFlagsContext } from './FeatureFlag.context'

type iNavigationContext = {
  goToRequests: (query?: any & { status?: JobStatusTypes | 'ALL' | 'LEAD'; statuses?: JobStatusTypes | 'ALL' | 'LEAD' }) => void
  goToCreateRequest: () => void
  goToRequestById: (requestId: string) => void
  goToDashboard: (slug?: string) => void
  goToClientRequestForm: (slug?: string) => void
  goToUserDirectory: (slug?: string) => void
  goToWorkBench: (slug?: string) => void
  goToSettings: (slug?: string) => void
  goToReportingReports: (slug?: string) => void
  goToReportingGoals: (slug?: string) => void
  workspace: string | null
  pathEnums: typeof PathEnums
}

export enum PathEnums {
  REQUESTS = '/[workspace]/jobs',
  REQUEST_BY_ID = '/[workspace]/jobs/[requestId]',
  REQUEST_BY_ID_BY_DELIVERABLE = '/[workspace]/jobs/[requestId]/deliverable/[deliverableId]',
  CREATE_REQUEST = '/[workspace]/jobs/create',
  DASHBOARD = '/[workspace]',
  CLIENT_REQUEST_FORM = '/[workspace]/external/job-request',
  DIRECTORY = '/[workspace]/directory',
  WORKBENCH = '/workbench',
  SETTINGS = '/[workspace]/settings',
  MY_ACCOUNT = '/[workspace]/my-account',
  HELP = '/[workspace]/help',
  SEARCH = '/[workspace]/search',
  IMPORT = '/[workspace]/import',
  IMPORT_SECTION = '/[workspace]/import/[section]',
  IMPORT_BY_ID = '/[workspace]/import/[section]/[importerId]',
  TALENT_PAGE = '/[workspace]/talent-profile/[talentId]',
  REPORTING_PAGE = '/[workspace]/reporting',
  REPORTING_PAGE_REPORTS = '/[workspace]/reporting/reports',
  REPORTING_PAGE_REPORTS_VIEW = '/[workspace]/reporting/reports/[reportEid]',
  REPORTING_PAGE_GOALS = '/[workspace]/reporting/goals',
}

export const NavigationContext = React.createContext<iNavigationContext>({
  goToRequests: () => {},
  goToCreateRequest: () => {},
  goToRequestById: () => {},
  goToDashboard: () => {},
  goToClientRequestForm: () => {},
  goToUserDirectory: () => {},
  goToWorkBench: () => {},
  goToSettings: () => {},
  goToReportingReports: () => {},
  goToReportingGoals: () => {},
  workspace: null,
  pathEnums: PathEnums,
})

export const NavigationProvider = ({ children }: { children: JSX.Element }) => {
  const { features } = React.useContext(FeatureFlagsContext)
  const { workspace } = useAppSelector(selectWorkspace)
  const router = useRouter()

  const goToRequests = (query = { page: 1, per_page: 20, status: 'ALL' }) => {
    router.push({
      pathname: PathEnums.REQUESTS,
      query: { workspace: workspace, ...query },
      slashes: false,
    })
  }
  const goToRequestById = (requestId: string) => {
    router.push({
      pathname: PathEnums.REQUEST_BY_ID,
      query: { workspace: workspace, requestId: requestId },
    })
  }
  const goToCreateRequest = (slug?: string) => {
    router.replace({
      pathname: PathEnums.CREATE_REQUEST,
      query: { workspace: workspace },
    })
  }

  const goToClientRequestForm = (slug?: string) => {
    router.replace({
      pathname: PathEnums.CLIENT_REQUEST_FORM,
      query: { workspace: workspace },
    })
  }
  const goToWorkBench = () => {
    router.replace({
      pathname: PathEnums.WORKBENCH,
    })
  }
  const goToSettings = () => {
    router.push({
      pathname: PathEnums.SETTINGS,
      // query: { workspace: workspace },
    })
  }

  const goToUserDirectory = (slug?: string) => {
    router.replace({
      pathname: PathEnums.DIRECTORY,
      query: { workspace: workspace },
    })
  }

  const goToDashboard = (slug?: string) => {
    /**
     * Remove this condition when dashboards are released
     */
    if (features.isDashboardEnabled) {
      router.push({
        pathname: PathEnums.DASHBOARD,
        query: { workspace: slug ?? workspace },
      })
    } else {
      router.push({
        pathname: '/[workspace]/jobs',
        query: { workspace: slug ?? workspace },
      })
    }
  }

  const goToReportingReports = (slug?: string) => {
    router.push({
      pathname: PathEnums.REPORTING_PAGE_REPORTS,
      query: { workspace: slug ?? workspace },
    })
  }

  const goToReportingGoals = (slug?: string) => {
    router.push({
      pathname: PathEnums.REPORTING_PAGE_GOALS,
      query: { workspace: slug ?? workspace },
    })
  }

  return (
    <NavigationContext.Provider
      value={{
        goToRequests,
        goToCreateRequest,
        goToRequestById,
        goToDashboard,
        goToClientRequestForm,
        goToUserDirectory,
        goToWorkBench,
        goToSettings,
        goToReportingReports,
        goToReportingGoals,
        workspace,
        pathEnums: PathEnums,
      }}>
      {children}
    </NavigationContext.Provider>
  )
}
