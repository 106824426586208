import { memo, forwardRef } from 'react'
import { GlobalStyles } from '../globalStyles'
import { Text } from '../Text'
import { ErrorCardProps } from './types'
import { Stack } from '@mui/material'

const ErrorCard = (props: ErrorCardProps, ref?: any) => {
  return (
    <Stack
      flex={1}
      padding="24px"
      borderRadius="10px"
      border={`1px solid ${GlobalStyles.PRIMARY_500}`}
      style={{ backgroundColor: GlobalStyles.MONO_WHITE, ...props.style }}>
      <Stack direction="column" alignItems="center" rowGap="12px">
        <Text>{props.errorIcon ?? null}</Text>
        <Text type="heading" size="sm" style={{ color: GlobalStyles.PRIMARY_500, textAlign: 'center', fontWeight: '500' }}>
          {props.heading}
        </Text>
        {typeof props.subHeading === 'string' ? (
          <Text type="paragraph" size="sm" style={Object.assign({ color: GlobalStyles.MONO_BLACK }, props?.subHeadingStyle || {})}>
            {props.subHeading}
          </Text>
        ) : (
          props.subHeading
        )}
        {props.actionButtons}
      </Stack>
    </Stack>
  )
}

export default memo(forwardRef(ErrorCard))
