import { Avatar, GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Stack, Typography, Button, Grid, useMediaQuery, tableCellClasses, StackProps } from '@mui/material'

import { PermissionWrapper } from '../hocs'
import { breakpoints } from 'src/andromeda/helpers/theme'
import { OVERFLOW_TEXT } from '../job-card/job-card'
import { MenuComponent } from 'src/pages/[workspace]/directory/users'

export const DirectoryContainer = ({
  children,
  drawer,
  isDrawerOpen = false,
}: {
  children: JSX.Element[] | JSX.Element
  drawer?: JSX.Element
  isDrawerOpen?: boolean
}) => (
  <Stack className="bg-mono-white paper-border" padding="12px" sx={{ marginRight: isDrawerOpen ? '450px' : 0 }}>
    <Stack className="paper-border">{children}</Stack>
    {drawer}
  </Stack>
)

type tDirectoryHeader = {
  IconComponent: (props: any) => JSX.Element
  title: string
  titleTooltip?: string
  createButtonLabel?: string
  listAction?: JSX.Element
  subHeader?: string
  onClick?: () => void
  searchComponent?: JSX.Element
  permissions?: string[]
  additionalButton?: JSX.Element
  startComponent?: JSX.Element
  containerSx?: StackProps['sx']
}
export const DirectorySubHeader = ({ subHeader, listAction }: Pick<tDirectoryHeader, 'subHeader' | 'listAction'>) => (
  <Stack direction={{ xxs: 'column', sm: 'row' }} justifyContent="space-between" alignItems={{ xxs: 'flex-start', sm: 'center' }}>
    {subHeader && (
      <Typography fontSize="14px" color={GlobalStyles.SLATE_700}>
        {subHeader}
      </Typography>
    )}
    {listAction}
  </Stack>
)

export const DirectoryHeader = ({
  IconComponent,
  title,
  titleTooltip,
  listAction,
  createButtonLabel,
  subHeader,
  onClick,
  searchComponent,
  permissions = [],
  additionalButton,
  startComponent,
  containerSx,
}: tDirectoryHeader) => (
  <Stack padding="22px 24px" rowGap="12px" sx={containerSx}>
    <Stack direction={{ xxs: 'column', sm: 'row' }} columnGap="12px" rowGap="12px" justifyContent="space-between" flexWrap="wrap">
      <Stack
        direction="row"
        alignItems="center"
        columnGap="12px"
        flexBasis="50%"
        flexGrow={1}
        justifyContent={{ xxs: 'space-between', md: 'unset' }}>
        <Stack direction="row" alignItems="center" columnGap="12px">
          {startComponent}
          <IconComponent size={20} color={GlobalStyles.SLATE_500} style={{ minWidth: '20px' }} />
          <Typography fontSize="14px" fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM} color={GlobalStyles.SLATE_500}>
            {title}
          </Typography>
          {titleTooltip && <Tooltip text={titleTooltip} />}
        </Stack>

        <Stack direction="row" alignItems="center" columnGap="12px">
          {createButtonLabel && (
            <PermissionWrapper allowedPermissions={permissions}>
              <Button variant="outlined" onClick={onClick} sx={{ marginLeft: { xxs: 'auto', sm: 0 }, minHeight: '45px' }}>
                <Stack direction="row" columnGap="8px" alignItems="center">
                  <Icon.PlusCircle size={16} style={{ minWidth: '16px' }} />
                  <Typography display={{ xxs: 'none', sm: 'initial' }} lineHeight="normal">
                    {createButtonLabel}
                  </Typography>
                </Stack>
              </Button>
            </PermissionWrapper>
          )}
          {additionalButton}
        </Stack>
      </Stack>
      {searchComponent}
    </Stack>
    <DirectorySubHeader subHeader={subHeader} listAction={listAction} />
  </Stack>
)

export const InviteUser = ({ onInviteUser }: { onInviteUser?: () => void }) => (
  <Button aria-label="invite user" variant="outlined" sx={{ minWidth: { md: '140px' }, margin: { md: '0 24px' } }} onClick={onInviteUser}>
    <Stack direction="row" columnGap="8px" alignItems="center">
      <Stack display={{ xxs: 'none', md: 'flex' }}>
        <Icon.PlusCircle size={16} />
      </Stack>
      <Stack display={{ xxs: 'flex', md: 'none' }}>
        <Icon.UserPlus size={16} />
      </Stack>
      <Typography display={{ xxs: 'none', md: 'initial' }}>Invite User</Typography>
    </Stack>
  </Button>
)

export const DirectoryRow = ({
  image = '',
  name,
  address,
  phone,
  isHeader = false,
  onClick,
  onInviteUser,
}: {
  image?: string
  name: string
  address?: string
  phone?: string | null
  isHeader?: boolean
  onClick?: () => void
  onInviteUser?: () => void
}) => {
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.sm}px)`)

  if (isHeader && isMobile) return <></>

  return isMobile ? (
    <Stack direction="row" className="mobile-directory-paper">
      <Stack maxWidth="50%">
        <Typography fontSize="14px" color={GlobalStyles.MONO_BLACK} fontWeight={500} {...OVERFLOW_TEXT}>
          {name}
        </Typography>
        <Typography variant={'caption'} fontSize="14px" color={GlobalStyles.SLATE_700} {...OVERFLOW_TEXT}>
          {address}
        </Typography>
      </Stack>
      <Stack direction="row" columnGap="16px">
        <InviteUser onInviteUser={onInviteUser} />
        <MenuComponent onClick={onClick} />
      </Stack>
    </Stack>
  ) : (
    <Stack
      direction="row"
      padding="16px 24px"
      borderTop={`1px solid ${GlobalStyles.SLATE_100}`}
      maxHeight={isHeader ? '48px' : 'auto'}
      minWidth="fit-content"
      boxSizing="border-box">
      <Stack
        marginRight="24px"
        justifyContent="center"
        visibility={isHeader ? 'hidden' : 'visible'}
        onClick={onClick}
        sx={{ cursor: 'pointer' }}>
        <Avatar size="lg" image={image} />
      </Stack>
      <Grid container sx={{ display: 'flex', alignItems: 'center' }} minWidth="300px">
        <Grid item xxs={4} role="button" aria-label={name} paddingX="6px" onClick={onClick} sx={{ cursor: 'pointer' }}>
          <Typography
            fontSize={isHeader ? '10px' : '14px'}
            color={isHeader ? GlobalStyles.SLATE_400 : GlobalStyles.MONO_BLACK}
            {...OVERFLOW_TEXT}>
            {name}
          </Typography>
        </Grid>
        <Grid item xxs={5} paddingX="6px">
          <Typography
            fontSize={isHeader ? '10px' : '14px'}
            color={isHeader ? GlobalStyles.SLATE_400 : GlobalStyles.SLATE_700}
            {...OVERFLOW_TEXT}>
            {address}
          </Typography>
        </Grid>
        <Grid item xxs={3} paddingX="6px">
          <Typography
            fontSize={isHeader ? '10px' : '14px'}
            color={isHeader ? GlobalStyles.SLATE_400 : GlobalStyles.PRIMARY_500}
            {...OVERFLOW_TEXT}>
            {phone}
          </Typography>
        </Grid>
      </Grid>
      <Stack justifyContent="center" paddingX="6px" visibility={isHeader ? 'hidden' : 'visible'}>
        <InviteUser onInviteUser={onInviteUser} />
      </Stack>
      <Stack justifyContent="center" paddingX="6px" visibility={isHeader ? 'hidden' : 'visible'}>
        <MenuComponent onClick={onClick} />
      </Stack>
    </Stack>
  )
}

export const TABLE_HEADER_STYLES = {
  borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
  borderTop: `1px solid ${GlobalStyles.SLATE_100}`,
  [`.${tableCellClasses.head}`]: { color: GlobalStyles.SLATE_400, fontSize: '10px', fontWeight: 700 },
  [`.${tableCellClasses.head}:first-child`]: {
    // container padding + avatar size + avatar right margin
    paddingLeft: 'calc(24px + 56px + 24px)',
  },
}
