import { GlobalStyles, Icon } from '@andromeda'
import { Transition } from '@components'
import { Types } from '@orbit'
import { AppStyles } from '@styles'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { passwordStrengthCalculate } from 'src/utils'
import {
  Stack,
  Button,
  inputBaseClasses,
  outlinedInputClasses,
  InputAdornment,
  formHelperTextClasses,
  InputBaseComponentProps,
  Typography,
  IconButton,
} from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { yupPasswordSchema } from '@utils/functions/passwordStrength'
import { Textfield } from '../form/Textfield'
import * as yup from 'yup'
import TnC from '@utils/constants/tnc.json'
import { ERROR_MESSAGES } from '@utils/constants/validation'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectInvite } from '@redux/reducers/inviteReducer'
import validateIDPEmail from '@utils/functions/validateIDPEmail'
import { listIdp, selectIdp } from '@redux/reducers/idpReducer'

interface iInviteAccount {
  name: string
  email: string
  password: string
  password_confirmation: string
  phone_number?: string | null
}
const InputField = ({
  label,
  type,
  name,
}: {
  name: string
  label: string
  type?: 'text' | 'password'
  inputProps?: InputBaseComponentProps
}) => {
  const [passwordUnmasked, setPasswordUnmasked] = useState(false)

  return (
    <>
      <label className="block w-full" style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.MONO_BLACK }}>
        {label}
      </label>
      <Textfield
        fullWidth
        name={name}
        type={type === 'password' && passwordUnmasked ? 'text' : type}
        sx={{
          [`.${inputBaseClasses.input}.${outlinedInputClasses.input}`]: {
            backgroundColor: 'white',
            padding: '16px 24px',
            height: '56px',
          },
          [`.${formHelperTextClasses.error}`]: {
            marginLeft: 0,
          },
        }}
        InputProps={
          type === 'password'
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setPasswordUnmasked(!passwordUnmasked)}
                      style={{
                        backgroundColor: GlobalStyles.COLOR_TRANSPARENT,
                        borderColor: GlobalStyles.SLATE_100,
                      }}>
                      {passwordUnmasked ? <Icon.EyeOff color={GlobalStyles.PRIMARY_500} /> : <Icon.Eye color={GlobalStyles.PRIMARY_500} />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: 'white',
                },
              }
            : {}
        }
        autoComplete={type === 'password' ? 'new-password' : ''}
      />
    </>
  )
}

const PasswordStrength = ({ password }: { password: string }) => {
  const { color, text } = useMemo(() => passwordStrengthCalculate(password), [password])
  passwordStrengthCalculate(password) // Weird Bug. Needs to call it again to get the proper value

  return (
    <Typography
      className="inline-block rounded"
      style={{
        color: GlobalStyles.MONO_WHITE,
        backgroundColor: color,
        padding: `${AppStyles.PADDING_SIZES['4XS']}px ${AppStyles.PADDING_SIZES.XS}px`,
        fontSize: GlobalStyles.FONT_SIZES.TINY,
        lineHeight: 'normal',
      }}>
      {text}
    </Typography>
  )
}

const AccountCreate = ({
  onSubmit,
  passwordError,
  emailError,
  isFromInvite = false,
  maxWidth = '100%',
}: {
  onSubmit?: (userDetails: Types.Invite.iInviteAcceptPayload) => void
  passwordError?: string
  emailError?: string
  isFromInvite?: boolean
  maxWidth?: string
}) => {
  const router = useRouter()

  const dispatch = useAppDispatch()
  const { invite } = useAppSelector(selectInvite)
  const { list } = useAppSelector(selectIdp)

  const [validatePassword, setValidatePassword] = useState('')

  const AccountSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required('The name field is required.'),
        email: yup
          .string()
          .email('This must be a valid email.')
          .required('The email field is required.')
          .test('idpRegex', ERROR_MESSAGES.REGISTER.CREATE, (value) => !validateIDPEmail(list, value))
          .test('inviteEmail', ERROR_MESSAGES.LOGIN.INVITE, (value) => {
            return invite && router.query.token && router.query.inviteEid ? invite.email === value : true
          }),
        password: yupPasswordSchema,
        password_confirmation: yup
          .string()
          .oneOf([yup.ref('password')], 'Password did not match')
          .required('Confirm Password is required.'),
        phone_number: yup.string().nullable(),
      }),
    [invite, router.query.inviteEid, router.query.token, list]
  )

  const form = useForm<iInviteAccount>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      phone_number: '',
    },
    resolver: yupResolver<iInviteAccount>(AccountSchema),
    mode: router.query.token && router.query.inviteEid ? 'onSubmit' : 'onChange',
  })

  const _openTerms = () => {
    window.open(TnC.terms_of_use, '_blank')
  }

  const _openPrivacy = () => {
    window.open(TnC.privacy_policy, '_blank')
  }

  const _handleSubmit: SubmitHandler<iInviteAccount> = (data) => {
    const payload = {
      ...data,
      phone_number: data.phone_number || null,
    } as Types.Invite.iInviteAcceptPayload

    onSubmit && onSubmit(payload)
  }

  const _handleFormChange = () => {
    const accountPassword = form.watch('password')

    if (validatePassword !== accountPassword) {
      setValidatePassword(accountPassword)
    }
  }

  useEffect(() => {
    const customErrors = [
      { field: 'email', error: emailError },
      { field: 'password', error: passwordError },
    ]

    for (const { field, error } of customErrors) {
      if (error) {
        form.setError(field as keyof iInviteAccount, { type: 'custom', message: error }, { shouldFocus: true })
      }
    }
  }, [passwordError, emailError, form])

  useEffect(() => {
    dispatch(listIdp())
  }, [dispatch])

  return (
    <Transition key={'create-account'} type="slide" style={{ maxWidth, width: '100%' }}>
      <DevTool control={form.control} />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(_handleSubmit)} onChange={_handleFormChange}>
          <Stack direction="column" alignItems="flex-start" height="auto" rowGap="16px">
            <InputField name="name" label="Your Name*" />

            <InputField name="email" label="Your Email*" />

            <InputField name="password" type="password" label="Your Password*" />

            <InputField name="password_confirmation" type="password" label="Confirm Your Password*" />

            <Stack direction="row" alignItems="center">
              <Typography style={{ marginRight: '8px' }}>Password strength:</Typography>
              <PasswordStrength password={validatePassword} />
            </Stack>

            <Stack style={{ width: '100%', marginTop: 38 }} rowGap="16px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ paddingTop: '16px', paddingBottom: '16px', maxWidth: '320px' }}>
                <Typography style={{ color: GlobalStyles.MONO_WHITE, fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                  Create an Account
                </Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={() => router.push('/login')}
                style={{ paddingTop: '16px', paddingBottom: '16px', maxWidth: '320px', marginBottom: '16px' }}>
                Back to Sign in
              </Button>
            </Stack>

            {!isFromInvite && (
              <>
                <Stack direction="row" alignItems="center" style={{ marginTop: '22px' }}>
                  <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.BASE }}>Already have an account?</Typography>
                  <Button type="button" color="primary" variant="text" onClick={() => router.push('/login')}>
                    <Typography style={{ color: GlobalStyles.PRIMARY_500, fontSize: GlobalStyles.FONT_SIZES.BASE }}>Sign in</Typography>
                  </Button>
                </Stack>

                <Typography style={{ fontSize: GlobalStyles.FONT_SIZES.TINY, marginTop: '30px', marginBottom: '30px' }}>
                  By signing up, signing in or continuing, I agree to the Terms of Use and{' '}
                  <Typography
                    className="inline cursor-pointer"
                    style={{ fontSize: GlobalStyles.FONT_SIZES.TINY, color: GlobalStyles.PRIMARY_500, textDecorationLine: 'underline' }}
                    onClick={_openPrivacy}>
                    Privacy Policy
                  </Typography>
                </Typography>
              </>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Transition>
  )
}

export { AccountCreate }
