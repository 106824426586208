import { Button, Text } from '@andromeda'
import { CenterModal, RightModal } from '@components'
import dynamic from 'next/dynamic'

import {
  AssignTalent,
  // DeliverableDetails,
  InvalidAccess,
  InviteUsers,
  JobProposal,
  KeyInfoMissing,
  MarkCompleteDeliverable,
  NotificationSettings,
  OwnerChange,
  OwnerRequired,
  ProfileManagement,
  ProfilePictureForm,
  RemoveTalent,
  ResetPasswordOptions,
  SendProposal,
  SentProposals,
  UnsavedChanges,
  UserPermission,
  UserProfile,
  AddUserToDeliverableModal,
  LeavePrompt,
  SocialCampaigns,
  ConfirmSavePublishModal,
} from '@modals'

const DynamicJobComplete = dynamic(() => import('../../modals/job/complete'), {
  loading: () => <Text>Loading...</Text>,
})
const DynamicMarkCompleteJobAgreement = dynamic(
  () => import('../../modals/agreements/mark-complete-job').then((mod) => mod.MarkCompleteJobAgreement),
  {
    loading: () => <Text>Loading...</Text>,
  }
)
const DynamicConfirmDeliverableDelete = dynamic(() => import('../../modals/deliverables').then((mod) => mod.ConfirmDeliverableDelete), {
  loading: () => <Text>Loading...</Text>,
})

import { Types } from '@orbit'
import { iDeliverableDetailModalProps } from '@utils/types/deliverables'
import { JobAgreementAssignTalentModalProps } from '@utils/types/job_agreements'
import { iJobCompleteModalProps } from '@utils/types/jobs'
import { createContext, useState } from 'react'
import { DialogModal } from 'src/components/modal'
import { AddJob } from 'src/modals/add-job'
import { CreateDepartment } from 'src/modals/create/department'
import { CreateOrganisation } from 'src/modals/create/organisation'
import { InviteUserModal } from 'src/modals/invite/invite-user'
import { ArchiveJob } from 'src/modals/job/archive'
import { UploadFile } from 'src/modals/upload-file'
import { AddTalent, AddTalentProps } from 'src/components/drawer/DeliverableDrawer/AddTalent'
import { AcceptInvite } from 'src/modals/accept-invite'
import { LeaveWorkspace } from 'src/modals/leaveWorkspace'
import { DeactivateAccount } from 'src/modals/deactivateAccount'
import { DeleteAccount } from 'src/modals/deleteAccount'
import ColumnControl, { ColumnControlProps } from 'src/modals/column-control'
import AddUserToJobModal from 'src/modals/add-users/add-users-job'
import { RemoveFromWorkspace } from 'src/modals/removeFromWorkspace'
import ConfirmRemoveDialog from 'src/modals/confirm-remove-dialog'
import JobSelectorModal from 'src/modals/selectors/job-selector'

/**
 * Default modal to use
 */
enum ModalDefaults {
  TYPE = 'deliverables',
}

/**
 * List of all pages that are displayed inside a modal
 */
export enum ModalPages {
  DELIVERABLES = 'deliverables',
  USERPERMISSION = 'userPermission',
  TAGS = 'tags',
  LOGIN = 'login',
  INVALID_ACCESS = 'invalidAccess',
  RESET_PASSWORD_FORM = 'resetPasswordForm',
  SEND_PROPOSAL = 'sendProposal',
  JOB_PROPOSAL = 'jobProposal',
  SENT_PROPOSALS = 'sentProposals',
  NOTIFICATION_SETTINGS = 'notificationSettings',
  PROFILE_PICTURE_FORM = 'profilePictureForm',
  USER_PROFILE = 'userProfile',
  PROFILE_MANAGEMENT = 'profileManagement',
  ASSIGN_TALENT = 'assignTalent',
  REMOVE_TALENT = 'removeTalent',
  KEY_INFO_MISSING = 'keyInfoMissing',
  MARK_COMPLETE_DELIVERABLE = 'markCompleteDel',
  INVITE_USER = 'inviteUser',
  OWNER_REQUIRED = 'ownerRequired',
  OWNER_CHANGE = 'ownerChange',
  LEAVE_JOB = 'leaveJob',
  UNSAVED_CHANGES = 'unsavedChanges',
  MARK_COMPLETE_JOB_AGREEMENT = 'markCompleteJobAgreement',
  DELETE_DELIVERABLE = 'deleteDeliverable',
  JOB_COMPLETE = 'jobComplete',
  ADD_JOB = 'addJob',
  CREATE_ORGANISATION = 'createOrganisation',
  CREATE_DEPARTMENT = 'createDepartment',
  INVITE_USERS = 'inviteUsers',
  ARCHIVE_JOB = 'archiveJob',
  UPLOAD_FILES = 'uploadFiles',
  ADD_USERS_TO_JOB = 'addUsersToJob',
  ADD_USERS_TO_DELIVERABLE = 'addUsersToDeliverable',
  LEAVE_DELIVERABLE = 'leaveDeliverable',
  SOCIAL_CAMPAIGNS = 'socialCampaigns',
  ACCEPT_INVITE = 'acceptInvite',
  CONFIRM_REMOVE_DIALOG = 'confirmRemoveDialog',
  CONFIRM_SAVE_PUBLISH = 'confimSavePublish',
  LEAVE_WORKSPACE = 'leaveWorkspace',
  REMOVE_FROM_WORKSPACE = 'removeFromWorkspace',
  DEACTIVATE_ACCOUNT = 'deactivateAccount',
  DELETE_ACCOUNT = 'deleteAccount',
  COLUMN_CONTROL = 'columnControl',
  JOB_SELECTOR = 'jobSelector',
}

/**
 * List of sizes we can use
 */
enum ModalSizes {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

/**
 * Types of modals we can use
 */
export type ModalTypes =
  | 'tags'
  | 'deliverables'
  | 'login'
  | 'deliverableDetails'
  | 'userPermission'
  | 'invalidAccess'
  | 'resetPasswordForm'
  | 'sendProposal'
  | 'jobProposal'
  | 'sentProposals'
  | 'notificationSettings'
  | 'profilePictureForm'
  | 'profileManagement'
  | 'userProfile'
  | 'talentFee'
  | 'assignTalent'
  | 'removeTalent'
  | 'keyInfoMissing'
  | 'markCompleteDel'
  | 'inviteUser'
  | 'ownerRequired'
  | 'ownerChange'
  | 'leaveJob'
  | 'unsavedChanges'
  | 'markCompleteJobAgreement'
  | 'deleteDeliverable'
  | 'jobComplete'
  | 'addJob'
  | 'createOrganisation'
  | 'createDepartment'
  | 'inviteUsers'
  | 'archiveJob'
  | 'uploadFiles'
  | 'addUsersToJob'
  | 'addUsersToDeliverable'
  | 'leaveDeliverable'
  | 'socialCampaigns'
  | 'acceptInvite'
  | 'confimSavePublish'
  | 'confirmRemoveDialog'
  | 'leaveWorkspace'
  | 'removeFromWorkspace'
  | 'deactivateAccount'
  | 'deleteAccount'
  | 'columnControl'
  | 'jobSelector'
/**
 * Types of Modal Sizes
 */
export type ModalSize = 'sm' | 'md' | 'lg'

/**
 * Types of values in the show state. Used to show/hide a particular modal
 */
type ModalShow = {
  deliverables: boolean
  deliverableDetails: boolean
  tags: boolean
  login: boolean
  userPermission: boolean
  invalidAccess: boolean
  resetPasswordForm: boolean
  sendProposal: boolean
  jobProposal: boolean
  sentProposals: boolean
  notificationSettings: boolean
  profilePictureForm: boolean
  profileManagement: boolean
  userProfile: boolean
  talentFee: boolean
  assignTalent: boolean
  removeTalent: boolean
  keyInfoMissing: boolean
  markCompleteDel: boolean
  inviteUser: boolean
  ownerRequired: boolean
  ownerChange: boolean
  leaveJob: boolean
  unsavedChanges: boolean
  markCompleteJobAgreement: boolean
  deleteDeliverable: boolean
  jobComplete: boolean
  addJob: boolean
  createOrganisation: boolean
  createDepartment: boolean
  inviteUsers: boolean
  archiveJob: boolean
  uploadFiles: boolean
  addUsersToJob: boolean
  addUsersToDeliverable: boolean
  leaveDeliverable: boolean
  socialCampaigns: boolean
  acceptInvite: boolean
  confimSavePublish: boolean
  confirmRemoveDialog: boolean
  leaveWorkspace: boolean
  removeFromWorkspace: boolean
  deactivateAccount: boolean
  deleteAccount: boolean
  columnControl: boolean
  jobSelector: boolean
}

export interface iModalProps {
  deliverables?: any
  deliverableDetails?: iDeliverableDetailModalProps
  tags?: any
  login?: any
  userPermission?: any
  invalidAccess?: any
  resetPasswordForm?: any
  sendProposal?: any
  jobProposal?: any
  sentProposals?: any
  notificationSettings?: any
  profilePictureForm?: any
  profileManagement?: any
  userProfile?: any
  talentFee?: any
  assignTalent?: AddTalentProps
  removeTalent?: any
  keyInfoMissing?: any
  markCompleteDel?: any
  inviteUser?: any
  ownerRequired?: any
  ownerChange?: any
  leaveJob?: any
  unsavedChanges?: any
  markCompleteJobAgreement?: any
  deleteDeliverable?: any
  jobComplete?: iJobCompleteModalProps
  addJob?: any
  createOrganisation?: any
  createDepartment?: any
  archiveJob?: any
  inviteUsers?: { onSubmit: (users: Types.User.iUser[]) => void; attachedUserEids?: string[] }
  uploadFiles?: { jobEid?: string; deliverableEid?: string; agreementEid?: string; expandedJobEid?: string; talentEid?: string }
  addUsersToJob?: unknown
  addUsersToDeliverable?: { deliverableEid?: string }
  leaveDeliverable?: unknown
  socialCampaigns?: any
  acceptInvite?: Types.Invite.iInvite
  confimSavePublish?: any
  confirmRemoveDialog?: {
    title: string
    message: string
    onConfirm?: (params: { isLoading: boolean; setIsLoading: (val: boolean) => void; close: () => void }) => void
    onCancel?: () => void
  }
  leaveWorkspace?: any
  removeFromWorkspace?: { user: string; workspace: string; userEid: string; page: string; onSuccess?: (eid: string) => void }
  deactivateAccount?: any
  deleteAccount?: any
  columnControl?: ColumnControlProps
  jobSelector?: {
    selected: Array<Types.Job.iJobList | Types.Job.iJob>
    onSelect: (selected: Array<Types.Job.iJobList | Types.Job.iJob>) => void
  }
}

/**
 * Types of Modal Options
 */

export type ModalOptions = {
  isScrollable?: boolean
  size?: ModalSize
  hideOthers?: boolean
  modalProps?: iModalProps
}

/**
 * Modal Context Interface
 */
export interface iModalContext {
  type?: ModalTypes
  show?: ModalShow
  toggleModal: (type: ModalTypes, options?: ModalOptions) => void
  closeModal: (type: ModalTypes) => void
  options?: ModalOptions
}

/**
 * Default modal context state
 */
const defaultState = {
  type: ModalDefaults.TYPE,
  show: {
    deliverables: false,
    deliverableDetails: false,
    tags: false,
    login: false,
    userPermission: false,
    invalidAccess: false,
    resetPasswordForm: false,
    sendProposal: false,
    jobProposal: false,
    sentProposals: false,
    notificationSettings: false,
    profilePictureForm: false,
    profileManagement: false,
    userProfile: false,
    talentFee: false,
    assignTalent: false,
    removeTalent: false,
    keyInfoMissing: false,
    markCompleteDel: false,
    inviteUser: false,
    ownerRequired: false,
    ownerChange: false,
    leaveJob: false,
    unsavedChanges: false,
    markCompleteJobAgreement: false,
    deleteDeliverable: false,
    jobComplete: false,
    addJob: false,
    createOrganisation: false,
    createDepartment: false,
    inviteUsers: false,
    archiveJob: false,
    uploadFiles: false,
    addUsersToJob: false,
    addUsersToDeliverable: false,
    leaveDeliverable: false,
    socialCampaigns: false,
    acceptInvite: false,
    confimSavePublish: false,
    confirmRemoveDialog: false,
    leaveWorkspace: false,
    removeFromWorkspace: false,
    deactivateAccount: false,
    deleteAccount: false,
    columnControl: false,
    jobSelector: false,
  },
  options: {
    size: ModalSizes.MEDIUM,
    isScrollable: false,
    hideOthers: true,
  },
  toggleModal: () => {},
  closeModal: () => {},
}

const ModalContext = createContext<iModalContext>(defaultState)

const ModalProvider = ({ children }: { children: JSX.Element }) => {
  const [type, setType] = useState<ModalTypes>()
  const [show, setShow] = useState<ModalShow>(defaultState.show)
  const [options, setOptions] = useState<ModalOptions>(defaultState.options)

  /**
   * Toggle the modal based on the type param
   * @param type
   */
  const toggleModal = (type: ModalTypes, options: ModalOptions = defaultState.options) => {
    /**
     * Update type state to the modal type
     */
    setType(type)
    /**
     * Update the show state based on the modal type
     */
    if (!options.hideOthers) {
      setShow({
        ...show,
        [type]: !show[type],
      })
    } else {
      setShow({
        ...show,
        tags: type === ModalPages.TAGS && !show.tags,
        login: type === ModalPages.LOGIN && !show.login,
        userPermission: type === ModalPages.USERPERMISSION && !show.userPermission,
        invalidAccess: type === ModalPages.INVALID_ACCESS && !show.invalidAccess,
        resetPasswordForm: type === ModalPages.RESET_PASSWORD_FORM && !show.resetPasswordForm,
        sendProposal: type === ModalPages.SEND_PROPOSAL && !show.sendProposal,
        jobProposal: type === ModalPages.JOB_PROPOSAL && !show.jobProposal,
        sentProposals: type === ModalPages.SENT_PROPOSALS && !show.sentProposals,
        notificationSettings: type === ModalPages.NOTIFICATION_SETTINGS && !show.notificationSettings,
        profilePictureForm: type === ModalPages.PROFILE_PICTURE_FORM && !show.profilePictureForm,
        profileManagement: type === ModalPages.PROFILE_MANAGEMENT && !show.profileManagement,
        userProfile: type === ModalPages.USER_PROFILE && !show.userProfile,
        assignTalent: type === ModalPages.ASSIGN_TALENT && !show.assignTalent,
        removeTalent: type === ModalPages.REMOVE_TALENT && !show.removeTalent,
        keyInfoMissing: type === ModalPages.KEY_INFO_MISSING && !show.keyInfoMissing,
        markCompleteDel: type === ModalPages.MARK_COMPLETE_DELIVERABLE && !show.markCompleteDel,
        inviteUser: type === ModalPages.INVITE_USER && !show.inviteUser,
        ownerRequired: type === ModalPages.OWNER_REQUIRED && !show.ownerRequired,
        ownerChange: type === ModalPages.OWNER_CHANGE && !show.ownerChange,
        leaveJob: type === ModalPages.LEAVE_JOB && !show.leaveJob,
        unsavedChanges: type === ModalPages.UNSAVED_CHANGES && !show.unsavedChanges,
        markCompleteJobAgreement: type === ModalPages.MARK_COMPLETE_JOB_AGREEMENT && !show.markCompleteJobAgreement,
        jobComplete: type === ModalPages.JOB_COMPLETE && !show.jobComplete,
        addJob: type === ModalPages.ADD_JOB && !show.addJob,
        createOrganisation: type === ModalPages.CREATE_ORGANISATION && !show.createOrganisation,
        createDepartment: type === ModalPages.CREATE_DEPARTMENT && !show.createDepartment,
        inviteUsers: type === ModalPages.INVITE_USERS && !show.inviteUsers,
        archiveJob: type === ModalPages.ARCHIVE_JOB && !show.archiveJob,
        uploadFiles: type === ModalPages.UPLOAD_FILES && !show.uploadFiles,
        addUsersToJob: type === ModalPages.ADD_USERS_TO_JOB && !show.addUsersToJob,
        addUsersToDeliverable: type === ModalPages.ADD_USERS_TO_DELIVERABLE && !show.addUsersToDeliverable,
        leaveDeliverable: type === ModalPages.LEAVE_DELIVERABLE && !show.leaveDeliverable,
        socialCampaigns: type === ModalPages.SOCIAL_CAMPAIGNS && !show.socialCampaigns,
        acceptInvite: type === ModalPages.ACCEPT_INVITE && !show.acceptInvite,
        confimSavePublish: type === ModalPages.CONFIRM_SAVE_PUBLISH && !show.confimSavePublish,
        leaveWorkspace: type === ModalPages.LEAVE_WORKSPACE && !show.leaveWorkspace,
        removeFromWorkspace: type === ModalPages.REMOVE_FROM_WORKSPACE && !show.removeFromWorkspace,
        deactivateAccount: type === ModalPages.DEACTIVATE_ACCOUNT && !show.deactivateAccount,
        deleteAccount: type === ModalPages.DELETE_ACCOUNT && !show.deleteAccount,
        columnControl: type === ModalPages.COLUMN_CONTROL && !show.columnControl,
        jobSelector: type === ModalPages.JOB_SELECTOR && !show.jobSelector,
      })
    }

    /**
     * Set modal options
     */
    setOptions(options)
  }

  const closeModal = (type: ModalTypes) => {
    const newOptions = { ...options }
    setShow({
      ...show,
      [type]: false,
    })

    if (newOptions.modalProps?.[type]) {
      delete newOptions.modalProps[type]
    }

    setOptions(Object.assign(defaultState.options, newOptions))
  }

  /**
   * Get and show the modal
   * @returns JSX.Element[]
   */
  const getModals = () => {
    const modals = Object.keys(show)
    return (
      <>
        {modals.map((modal) => {
          if (modal === ModalPages.TAGS && show[ModalPages.TAGS]) {
            return (
              <CenterModal key={modal} onBackdropPress={() => closeModal(ModalPages.TAGS)} show={show[ModalPages.TAGS]}>
                <p>Slide This Up</p>
                <Button
                  type="primary"
                  onPress={() => {
                    closeModal(ModalPages.TAGS)
                  }}>
                  Close
                </Button>
              </CenterModal>
            )
          }
          if (modal === ModalPages.USERPERMISSION && show[ModalPages.USERPERMISSION]) {
            return (
              <CenterModal key={modal} onBackdropPress={() => closeModal(ModalPages.USERPERMISSION)} show={show[ModalPages.USERPERMISSION]}>
                <UserPermission />
              </CenterModal>
            )
          }

          if (modal === ModalPages.ADD_USERS_TO_JOB && show[ModalPages.ADD_USERS_TO_JOB]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.ADD_USERS_TO_JOB)}
                show={show[ModalPages.ADD_USERS_TO_JOB]}>
                <AddUserToJobModal />
              </CenterModal>
            )
          }

          if (modal === ModalPages.ADD_USERS_TO_DELIVERABLE && show[ModalPages.ADD_USERS_TO_DELIVERABLE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.ADD_USERS_TO_DELIVERABLE)}
                show={show[ModalPages.ADD_USERS_TO_DELIVERABLE]}>
                <AddUserToDeliverableModal />
              </CenterModal>
            )
          }

          if (modal === ModalPages.INVALID_ACCESS && show[ModalPages.INVALID_ACCESS]) {
            // For demo purposes
            return (
              <RightModal key={modal} onBackdropPress={() => closeModal(ModalPages.INVALID_ACCESS)} show={show[ModalPages.INVALID_ACCESS]}>
                <InvalidAccess />
              </RightModal>
            )
          }
          if (modal === ModalPages.RESET_PASSWORD_FORM && show[ModalPages.RESET_PASSWORD_FORM]) {
            return (
              <DialogModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.RESET_PASSWORD_FORM)}
                show={show[ModalPages.RESET_PASSWORD_FORM]}
                options={{ size: ModalSizes.SMALL }}>
                <ResetPasswordOptions />
              </DialogModal>
            )
          }
          if (modal === ModalPages.JOB_PROPOSAL && show[ModalPages.JOB_PROPOSAL]) {
            return (
              <RightModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.JOB_PROPOSAL)}
                show={show[ModalPages.JOB_PROPOSAL]}
                // @ts-expect-error
                containerStyles={{ paddingHorizontal: 0, paddingTop: 0 }}>
                <JobProposal />
              </RightModal>
            )
          }
          if (modal === ModalPages.SEND_PROPOSAL && show[ModalPages.SEND_PROPOSAL]) {
            const modalOptions: ModalOptions = {
              hideOthers: false,
            }
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.SEND_PROPOSAL)}
                show={show[ModalPages.SEND_PROPOSAL]}
                options={modalOptions}>
                <SendProposal />
              </CenterModal>
            )
          }

          if (modal === ModalPages.SENT_PROPOSALS && show[ModalPages.SENT_PROPOSALS]) {
            return (
              <RightModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.SENT_PROPOSALS)}
                show={show[ModalPages.SENT_PROPOSALS]}
                // @ts-expect-error
                containerStyles={{ paddingHorizontal: 0, paddingTop: 0 }}>
                <SentProposals />
              </RightModal>
            )
          }

          if (modal === ModalPages.NOTIFICATION_SETTINGS && show[ModalPages.NOTIFICATION_SETTINGS]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.NOTIFICATION_SETTINGS)}
                show={show[ModalPages.NOTIFICATION_SETTINGS]}>
                <NotificationSettings />
              </CenterModal>
            )
          }

          if (modal === ModalPages.PROFILE_PICTURE_FORM && show[ModalPages.PROFILE_PICTURE_FORM]) {
            const opts: ModalOptions = { size: 'md' }
            return (
              <CenterModal
                key={'profile-picture-form'}
                onBackdropPress={() => closeModal(ModalPages.PROFILE_PICTURE_FORM)}
                show={show[ModalPages.PROFILE_PICTURE_FORM]}
                options={opts}>
                <ProfilePictureForm />
              </CenterModal>
            )
          }

          if (modal === ModalPages.PROFILE_MANAGEMENT && show[ModalPages.PROFILE_MANAGEMENT]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.PROFILE_MANAGEMENT)}
                show={show[ModalPages.PROFILE_MANAGEMENT]}>
                <ProfileManagement />
              </CenterModal>
            )
          }

          if (modal === ModalPages.USER_PROFILE && show[ModalPages.USER_PROFILE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.USER_PROFILE)}
                show={show[ModalPages.USER_PROFILE]}
                options={{ size: ModalSizes.SMALL }}>
                <UserProfile />
              </CenterModal>
            )
          }

          if (modal === ModalPages.ASSIGN_TALENT && show[ModalPages.ASSIGN_TALENT]) {
            if (options.modalProps?.assignTalent) {
              return (
                <CenterModal
                  key={modal}
                  onBackdropPress={() => closeModal(ModalPages.ASSIGN_TALENT)}
                  show={show[ModalPages.ASSIGN_TALENT]}
                  options={{ size: ModalSizes.SMALL }}>
                  <AddTalent />
                </CenterModal>
              )
            }
          }

          if (modal === ModalPages.REMOVE_TALENT && show[ModalPages.REMOVE_TALENT]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.REMOVE_TALENT)}
                show={show[ModalPages.REMOVE_TALENT]}
                options={{ size: ModalSizes.SMALL }}>
                <RemoveTalent />
              </CenterModal>
            )
          }

          if (modal === ModalPages.KEY_INFO_MISSING && show[ModalPages.KEY_INFO_MISSING]) {
            return (
              // <CenterModal
              // key={modal}
              // onBackdropPress={() => closeModal(ModalPages.KEY_INFO_MISSING)}
              // show={show[ModalPages.KEY_INFO_MISSING]}
              // options={{ size: ModalSizes.SMALL }}>
              <DialogModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.KEY_INFO_MISSING)}
                show={show[ModalPages.KEY_INFO_MISSING]}
                options={{ size: ModalSizes.SMALL }}>
                <KeyInfoMissing />
              </DialogModal>
              //{/* </CenterModal> */ }
            )
          }

          if (modal === ModalPages.MARK_COMPLETE_DELIVERABLE && show[ModalPages.MARK_COMPLETE_DELIVERABLE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.MARK_COMPLETE_DELIVERABLE)}
                show={show[ModalPages.MARK_COMPLETE_DELIVERABLE]}
                options={{ size: ModalSizes.SMALL }}>
                <MarkCompleteDeliverable />
              </CenterModal>
            )
          }

          if (modal === ModalPages.INVITE_USER && show[ModalPages.INVITE_USER]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.INVITE_USER)}
                show={show[ModalPages.INVITE_USER]}
                options={{ size: ModalSizes.MEDIUM }}>
                <InviteUserModal />
              </CenterModal>
            )
          }

          if (modal === ModalPages.OWNER_REQUIRED && show[ModalPages.OWNER_REQUIRED]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.OWNER_REQUIRED)}
                show={show[ModalPages.OWNER_REQUIRED]}
                options={{ size: ModalSizes.SMALL }}>
                <OwnerRequired />
              </CenterModal>
            )
          }

          if (modal === ModalPages.OWNER_CHANGE && show[ModalPages.OWNER_CHANGE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => {
                  return false
                }}
                show={show[ModalPages.OWNER_CHANGE]}
                options={{ size: ModalSizes.SMALL }}>
                <OwnerChange />
              </CenterModal>
            )
          }

          if (modal === ModalPages.LEAVE_JOB && show[ModalPages.LEAVE_JOB]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.LEAVE_JOB)}
                show={show[ModalPages.LEAVE_JOB]}
                options={{ size: ModalSizes.SMALL }}>
                <LeavePrompt subject="job" modal={ModalPages.LEAVE_JOB} />
              </CenterModal>
            )
          }

          if (modal === ModalPages.LEAVE_DELIVERABLE && show[ModalPages.LEAVE_DELIVERABLE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.LEAVE_DELIVERABLE)}
                show={show[ModalPages.LEAVE_DELIVERABLE]}
                options={{ size: ModalSizes.SMALL }}>
                <LeavePrompt subject="deliverable" modal={ModalPages.LEAVE_DELIVERABLE} />
              </CenterModal>
            )
          }

          if (modal === ModalPages.UNSAVED_CHANGES && show[ModalPages.UNSAVED_CHANGES]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.UNSAVED_CHANGES)}
                show={show[ModalPages.UNSAVED_CHANGES]}
                options={{ size: ModalSizes.SMALL }}>
                <UnsavedChanges />
              </CenterModal>
            )
          }

          if (modal === ModalPages.CONFIRM_SAVE_PUBLISH && show[ModalPages.CONFIRM_SAVE_PUBLISH]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.CONFIRM_SAVE_PUBLISH)}
                show={show[ModalPages.CONFIRM_SAVE_PUBLISH]}
                options={{ size: ModalSizes.SMALL }}>
                <ConfirmSavePublishModal />
              </CenterModal>
            )
          }

          if (modal === ModalPages.CONFIRM_REMOVE_DIALOG && show[ModalPages.CONFIRM_REMOVE_DIALOG]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.CONFIRM_REMOVE_DIALOG)}
                show={show[ModalPages.CONFIRM_REMOVE_DIALOG]}
                options={{ size: ModalSizes.SMALL }}>
                <ConfirmRemoveDialog />
              </CenterModal>
            )
          }

          if (modal === ModalPages.MARK_COMPLETE_JOB_AGREEMENT && show[ModalPages.MARK_COMPLETE_JOB_AGREEMENT]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.MARK_COMPLETE_JOB_AGREEMENT)}
                show={show[ModalPages.MARK_COMPLETE_JOB_AGREEMENT]}
                options={{ size: ModalSizes.SMALL }}>
                <DynamicMarkCompleteJobAgreement />
              </CenterModal>
            )
          }

          if (modal === ModalPages.DELETE_DELIVERABLE && show[ModalPages.DELETE_DELIVERABLE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.DELETE_DELIVERABLE)}
                show={show[ModalPages.DELETE_DELIVERABLE]}
                options={{ size: ModalSizes.SMALL }}>
                <DynamicConfirmDeliverableDelete />
              </CenterModal>
            )
          }

          if (modal === ModalPages.JOB_COMPLETE && show[ModalPages.JOB_COMPLETE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.JOB_COMPLETE)}
                show={show[ModalPages.JOB_COMPLETE]}
                options={{ size: ModalSizes.SMALL }}>
                <DynamicJobComplete onConfirm={options.modalProps?.jobComplete?.onConfirm} />
              </CenterModal>
            )
          }

          if (modal === ModalPages.ADD_JOB && show[ModalPages.ADD_JOB]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.ADD_JOB)}
                show={show[ModalPages.ADD_JOB]}
                options={{ size: ModalSizes.SMALL }}>
                <AddJob />
              </CenterModal>
            )
          }

          if (modal === ModalPages.CREATE_ORGANISATION && show[ModalPages.CREATE_ORGANISATION]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.CREATE_ORGANISATION)}
                show={show[ModalPages.CREATE_ORGANISATION]}
                options={{ size: ModalSizes.SMALL }}>
                <CreateOrganisation />
              </CenterModal>
            )
          }

          if (modal === ModalPages.CREATE_DEPARTMENT && show[ModalPages.CREATE_DEPARTMENT]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.CREATE_DEPARTMENT)}
                show={show[ModalPages.CREATE_DEPARTMENT]}
                options={{ size: ModalSizes.SMALL }}>
                <CreateDepartment />
              </CenterModal>
            )
          }

          if (modal === ModalPages.INVITE_USERS && show[ModalPages.INVITE_USERS]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.INVITE_USERS)}
                show={show[ModalPages.INVITE_USERS]}
                options={{ size: ModalSizes.SMALL }}>
                <InviteUsers />
              </CenterModal>
            )
          }

          if (modal === ModalPages.ARCHIVE_JOB && show[ModalPages.ARCHIVE_JOB]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.ARCHIVE_JOB)}
                show={show[ModalPages.ARCHIVE_JOB]}
                options={{ size: ModalSizes.SMALL }}>
                <ArchiveJob />
              </CenterModal>
            )
          }
          if (modal === ModalPages.UPLOAD_FILES && show[ModalPages.UPLOAD_FILES]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.UPLOAD_FILES)}
                show={show[ModalPages.UPLOAD_FILES]}
                options={{ size: ModalSizes.SMALL }}>
                <UploadFile />
              </CenterModal>
            )
          }
          if (modal === ModalPages.SOCIAL_CAMPAIGNS && show[ModalPages.SOCIAL_CAMPAIGNS]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.SOCIAL_CAMPAIGNS)}
                show={show[ModalPages.SOCIAL_CAMPAIGNS]}
                options={{ size: ModalSizes.SMALL }}>
                <SocialCampaigns />
              </CenterModal>
            )
          }
          if (modal === ModalPages.ACCEPT_INVITE && show[ModalPages.ACCEPT_INVITE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.ACCEPT_INVITE)}
                show={show[ModalPages.ACCEPT_INVITE]}
                options={{ size: ModalSizes.SMALL }}>
                <AcceptInvite />
              </CenterModal>
            )
          }
          if (modal === ModalPages.LEAVE_WORKSPACE && show[ModalPages.LEAVE_WORKSPACE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.LEAVE_WORKSPACE)}
                show={show[ModalPages.LEAVE_WORKSPACE]}
                options={{ size: ModalSizes.SMALL }}>
                <LeaveWorkspace />
              </CenterModal>
            )
          }
          if (modal === ModalPages.REMOVE_FROM_WORKSPACE && show[ModalPages.REMOVE_FROM_WORKSPACE]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.REMOVE_FROM_WORKSPACE)}
                show={show[ModalPages.REMOVE_FROM_WORKSPACE]}
                options={{ size: ModalSizes.SMALL }}>
                <RemoveFromWorkspace />
              </CenterModal>
            )
          }
          if (modal === ModalPages.DEACTIVATE_ACCOUNT && show[ModalPages.DEACTIVATE_ACCOUNT]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.DEACTIVATE_ACCOUNT)}
                show={show[ModalPages.DEACTIVATE_ACCOUNT]}
                options={{ size: ModalSizes.SMALL }}>
                <DeactivateAccount />
              </CenterModal>
            )
          }
          if (modal === ModalPages.DELETE_ACCOUNT && show[ModalPages.DELETE_ACCOUNT]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.DELETE_ACCOUNT)}
                show={show[ModalPages.DELETE_ACCOUNT]}
                options={{ size: ModalSizes.SMALL }}>
                <DeleteAccount />
              </CenterModal>
            )
          }

          if (modal === ModalPages.COLUMN_CONTROL && show[ModalPages.COLUMN_CONTROL]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.COLUMN_CONTROL)}
                show={show[ModalPages.COLUMN_CONTROL]}
                options={{ size: ModalSizes.SMALL }}>
                <ColumnControl />
              </CenterModal>
            )
          }

          if (modal === ModalPages.JOB_SELECTOR && show[ModalPages.JOB_SELECTOR]) {
            return (
              <CenterModal
                key={modal}
                onBackdropPress={() => closeModal(ModalPages.JOB_SELECTOR)}
                show={show[ModalPages.JOB_SELECTOR]}
                options={{ size: ModalSizes.SMALL }}>
                <JobSelectorModal />
              </CenterModal>
            )
          }
        })}
      </>
    )
  }

  return (
    <ModalContext.Provider value={{ type, toggleModal, closeModal, options }}>
      {children}
      {getModals()}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }
