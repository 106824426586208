import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import moment from 'moment'
import { InlineDeliverableCreate, tDeliverableForm } from './deliverable'
import { toast } from 'react-toastify'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { assignTalentToDeliverable, createDeliverable } from '@redux/reducers/deliverableReducer'
import { trackEvent } from '@utils/tracking/helpers'
import { assignTalents } from '@redux/reducers/jobsReducer'
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTalents } from '@utils/hooks/useTalents'
import { DevTool } from '@hookform/devtools'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getDefaultCurrencyCode } from '@utils/functions/helperFunctions'
import { useCurrencyCode } from '@utils/hooks/useCurrencyCode'
import { API_DATE_FORMAT } from '@utils/constants/date'
import { useTranslation } from '@utils/hooks/useTranslation'

export const DeliverableOnlyInlineCreate = ({
  costMode,
  deliverable,
  talents,
  job_eid,
  onCancel,
  onSuccess,
}: {
  job_eid: string
  deliverable?: Types.Deliverable.iDeliverable
  talents?: Types.Talent.iTalent[]
  costMode: Types.Job.iJob['cost_mode']
  onCancel: () => void
  onSuccess: (deliverable: Types.Deliverable.iDeliverable) => void
}) => {
  const dispatch = useAppDispatch()
  const { workspace, settings } = useAppSelector(selectWorkspace)

  const currencyCodeOptions = useCurrencyCode()
  const { t } = useTranslation('talent')

  const validationSchema = yup.object({
    deliverable: yup.object().shape({
      name: yup.string().required('Deliverable Title is required.'),
      deliverable_type_eid: yup.object().required('Deliverable Type is required.'),
      start_datetime: yup.object().required('Start date is required.'),
      end_datetime: yup.object().required('End date is required.'),
      deliverable_cost_per_talent: yup
        .number()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .test({
          message: 'Deliverable Cost is required.',
          test: (value) => {
            return !(costMode === 'PER_DELIVERABLE' && !value)
          },
        }),
    }),
  })

  const form = useForm<tDeliverableForm>({
    defaultValues: {
      cost_mode: { id: costMode, value: costMode },
      deliverable: {
        ...deliverable,
        // @ts-expect-error
        start_datetime: deliverable?.start_datetime ? moment(deliverable?.start_datetime, API_DATE_FORMAT) : undefined,
        // @ts-expect-error
        end_datetime: deliverable?.end_datetime ? moment(deliverable?.end_datetime, API_DATE_FORMAT) : undefined,
        deliverable_currency_code: getDefaultCurrencyCode(
          deliverable?.deliverable_currency_code ?? settings?.default_currency,
          currencyCodeOptions
        ),
        deliverable_type_eid: deliverable?.deliverable_type
          ? { value: deliverable?.deliverable_type.name, eid: deliverable?.deliverable_type.eid }
          : undefined,
      },
      talents: costMode === 'PER_JOB' ? undefined : [],
    },
    // @ts-expect-error
    resolver: yupResolver(validationSchema),
  })

  const { talentOptions } = useTalents()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (deliverable?.eid)
      form.reset({
        cost_mode: { id: costMode, value: costMode },
        deliverable: {
          ...deliverable,
          // @ts-expect-error
          start_datetime: deliverable?.start_datetime ? moment(deliverable?.start_datetime, API_DATE_FORMAT) : undefined,
          // @ts-expect-error
          end_datetime: deliverable?.end_datetime ? moment(deliverable?.end_datetime, API_DATE_FORMAT) : undefined,
          deliverable_currency_code: getDefaultCurrencyCode(
            deliverable?.deliverable_currency_code ?? settings?.default_currency,
            currencyCodeOptions
          ),
          deliverable_type_eid: deliverable?.deliverable_type
            ? { value: deliverable?.deliverable_type.name, eid: deliverable?.deliverable_type.eid }
            : undefined,
        },
        talents: costMode === 'PER_JOB' ? undefined : [],
      })
  }, [deliverable?.eid])

  const onSubmit: SubmitHandler<tDeliverableForm> = (data) => {
    const deliverable: Types.Deliverable.iDeliverablePayload = {
      eid: `D${data.deliverable.deliverable_type_eid.eid}-temp-eid`,
      name: data.deliverable.name as string,
      deliverable_type_eid: data.deliverable.deliverable_type_eid.eid,
      start_datetime: data.deliverable.start_datetime ? `${moment(data.deliverable.start_datetime).format('YYYY-MM-DD')} 00:00:00` : null,
      end_datetime: data.deliverable.end_datetime ? `${moment(data.deliverable.end_datetime).format('YYYY-MM-DD')} 23:59:59` : null,
      ...(costMode === 'PER_DELIVERABLE' && {
        deliverable_cost_per_talent: data.deliverable.deliverable_cost_per_talent,
        deliverable_currency_code: data.deliverable.deliverable_currency_code.value,
      }),
    }

    const successAction = (deliverable: Types.Deliverable.iDeliverable) => {
      toast.success('Deliverable has been created.')
      onSuccess(deliverable)
      setIsLoading(false)

      trackEvent({
        event: TrackingEventEnums.Deliverable.DELIVERABLE_CREATED,
        eventProperties: {
          component_name: 'Inline Deliverable Create',
          workspace: workspace,
        },
      })
    }

    toast.promise(
      dispatch(createDeliverable({ job_eid, ...deliverable })).then((deliverableResponse) => {
        if (deliverableResponse.meta.requestStatus === 'fulfilled') {
          if (data.talents?.length) {
            const agreement = data.talents?.reduce(
              (acc, talent) => (talent.eid ? [...acc, { talent_eid: talent.eid }] : acc),
              [] as Array<{ talent_eid: string }>
            )
            if (costMode === 'PER_DELIVERABLE') {
              dispatch(
                assignTalentToDeliverable({
                  assign: [
                    {
                      deliverable_eid: deliverableResponse.payload?.eid,
                      agreement,
                    },
                  ],
                })
              ).then((assignTalentResponse) => {
                if (assignTalentResponse.meta.requestStatus === 'fulfilled') {
                  successAction(deliverableResponse.payload)

                  trackEvent({
                    event: TrackingEventEnums.Deliverable.DELIVERABLE_ADD_TALENT,
                    eventProperties: {
                      section_name: 'job list',
                      component_name: 'Inline Deliverable Create',
                      workspace: workspace,
                    },
                  })
                } else {
                  toast.error(t('Failed to assign talent.') ?? `Failed to assign talent.`)
                  setIsLoading(false)
                }
              })
            } else {
              if (talents?.length || data.talents?.length) {
                dispatch(
                  assignTalents({
                    assign: [
                      {
                        job_eid,
                        agreement,
                      },
                    ],
                  })
                ).then((assignTalentResponse) => {
                  if (assignTalentResponse.meta.requestStatus === 'fulfilled') {
                    successAction(deliverableResponse.payload)

                    trackEvent({
                      event: TrackingEventEnums.Job.JOB_ADD_TALENT,
                      eventProperties: {
                        component_name: 'Inline Deliverable Create',
                        workspace: workspace,
                      },
                    })
                  } else {
                    toast.error(t('Failed to assign talent.') ?? `Failed to assign talent.`)
                    setIsLoading(false)
                  }
                })
              } else {
                successAction(deliverableResponse.payload)
              }
            }
          } else {
            successAction(deliverableResponse.payload)
          }
        }
      }),
      {
        pending: 'Creating deliverable...',
      }
    )
  }

  return (
    <FormProvider {...form}>
      <DevTool control={form.control} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack style={{ backgroundColor: 'white', padding: '12px 8px', boxSizing: 'border-box' }} alignItems="center">
          <InlineDeliverableCreate onCancelAddDeliverable={onCancel} isLoading={isLoading} hasTalent={!!talents?.length} />
        </Stack>
      </form>
    </FormProvider>
  )
}
