import { Stack, Typography, autocompleteClasses, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { iCustomFieldProps } from '../custom-fields.types'
import { FormAutoComplete } from 'src/components/form/AutoComplete'
import { useFormContext } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { GlobalStyles } from '@andromeda'
import HoverEditIcon from '../hover-edit-icon'
import { encodeQoutes } from '@utils/functions/helperFunctions'

const CustomFieldDropdown = ({ name, field, isEdit, onClick }: iCustomFieldProps) => {
  const options = useMemo(() => {
    if (field.list_options) {
      return field.list_options
        .split(',')
        .map((option) => option.trim())
        .filter((item, index, array) => array.indexOf(item) === index)
    }

    return []
  }, [field.list_options])

  const placeholderText = `Select ${name}`

  return (
    <Stack direction="row" padding="12px 24px" columnGap="8px" alignItems="center" onClick={() => onClick?.(field)}>
      <Typography>{field.display_name}</Typography>

      <HoverEditIcon isEdit={isEdit}>
        <FormAutoComplete
          name={encodeQoutes(name)}
          options={options}
          disabled={!isEdit}
          placeholder={placeholderText}
          defaultValue={field.value || field.default_value}
          style={{
            maxWidth: '100%',
          }}
          sx={{
            [`&.${autocompleteClasses.hasPopupIcon} .${outlinedInputClasses.root}`]: {
              paddingRight: '28px',
            },
            [`.${autocompleteClasses.input}.${inputBaseClasses.disabled}`]: {
              color: GlobalStyles.PRIMARY_500,
              WebkitTextFillColor: GlobalStyles.PRIMARY_500,
            },
            [`.${inputBaseClasses.disabled} .${autocompleteClasses.endAdornment} svg`]: {
              stroke: GlobalStyles.PRIMARY_500,
            },
            pointerEvents: !isEdit ? 'none' : 'all',
          }}
          textfieldProps={{
            placeholder: `Select ${field.display_name}`,
          }}
          isOptionEqualToValue={(option, value) => option === value}
          rules={{ required: field.required ? `${field.display_name} is required.` : false }}
        />
      </HoverEditIcon>
    </Stack>
  )
}

export default CustomFieldDropdown
