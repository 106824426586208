import axios from 'axios'
import markdown from 'remark-parse'
import slate, { BlockType, LeafType, serialize } from 'remark-slate'
import { Descendant } from 'slate'
import { DropdownOption } from '@andromeda'
import { Types } from '@orbit'
import { FieldValues } from 'react-hook-form'
import { unified } from 'unified'
import { deserializeMention, serializeMention } from 'src/components/rich-editor-plugin/mention'
import flattenListItemParagraphs from 'mdast-flatten-listitem-paragraphs'

export function capitalize(s: string | undefined | null) {
  if (!s || !s[0]) return ''
  return s[0].toUpperCase() + s.slice(1)
}

export function capitalizeFirstLetter(string: string) {
  if (!string || !string[0]) return ''
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function convertStringToInt(text: string) {
  const restrictedCharactersRegex = /[,$\D]/g
  const cleanedText = text.toString().trim().replace(restrictedCharactersRegex, '')

  if (isNaN(parseInt(cleanedText))) {
    return cleanedText
  }

  return parseInt(cleanedText)
}

export function currencyFormatter(amount: string | number, currencyCode: string = 'AUD') {
  const parsedAmount = convertStringToInt(amount as string)

  if (typeof parsedAmount === 'number' && !isNaN(parsedAmount)) {
    return Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(parsedAmount)
  }

  return amount
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()
  return new File([blob], fileName, { type: 'image/png' })
}

export async function uploadFileToUrl(file: File, url: string) {
  const dataUrl = URL.createObjectURL(file)

  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()
  const fileObj = new File([blob], file.name, { type: file.type })

  await axios.put(url, fileObj, {
    headers: {
      'Content-Type': file.type,
    },
  })
}

export const getInitials = (name?: string | null) => {
  if (name) {
    const words = name.split(' ')
    let initials = ''
    words.forEach((word) => {
      initials += word[0]
    })
    return initials.substring(0, 2).toUpperCase()
  }
  return ''
}

export function addDays(date: number, days: number) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result.getTime()
}

export function capitalizeWordsFirstLetter(str: string, separator: string) {
  const words = str.split(separator || ' ')
  if (!words || !words[0]) return ''
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  return capitalizedWords.join(' ')
}

export const urlParamsToJSON = (params: any) =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple
    if (acc.hasOwnProperty(key) || key.includes('statuses') || key.includes('owners')) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val]
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        if (acc[key]) {
          acc[key] = [acc[key], val]
        } else {
          acc[key] = [val]
        }
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val
    }

    return acc
  }, {})
export const toJSON = (str: string) => {
  try {
    return JSON.parse(str)
  } catch (error) {
    return false
  }
}

export const markdownToSlate = (md: string) => {
  const { result } = unified()
    // @ts-expect-error - unified types are not up to date
    .use(markdown)
    .use(flattenListItemParagraphs)
    .use(slate)
    .processSync(md)
  const slateContent: Array<BlockType | LeafType> = []

  if (result) {
    for (let i = 0; i < (result as Array<Descendant>).length; i++) {
      const deserialized = deserializeMention((result as Array<Descendant>)[i] as BlockType)

      if (Array.isArray(deserialized)) {
        slateContent.push(...deserialized)
      } else {
        slateContent.push(deserialized)
      }
    }
  }

  return slateContent
}

export const slateToMarkdown = (children: Descendant[]) => {
  function ensureDoubleNewline(str: string) {
    if (str.endsWith('\n\n')) {
      return str
    } else if (str.endsWith('\n')) {
      return str + '\n'
    } else {
      return str + '\n\n'
    }
  }

  function serializeListItems(nodeChild: BlockType, listType: string): string | undefined {
    if (nodeChild.type === 'list_item') {
      let listItemSerialized = serialize(serializeMention(nodeChild))

      // if the list item has multiple child the serializer will not add a new line
      // after the list item, so we need to add it manually
      if (listItemSerialized && !listItemSerialized.endsWith('\n\n')) {
        listItemSerialized = ensureDoubleNewline(listItemSerialized)
      }

      // check if it's an ordered list and replace the bullet with a number
      // because the markdown serializer treats all list items as unordered
      return listType === 'ol_list' ? listItemSerialized?.replace('-', '1.') : listItemSerialized
    }

    if (nodeChild.children) {
      return nodeChild.children.map((child) => serializeListItems(child as BlockType, listType)).join('')
    }

    return serialize(serializeMention(nodeChild))
  }

  return children
    .map((child) => {
      const nodeChild = child as BlockType
      const isList = nodeChild.type === 'ul_list' || nodeChild.type === 'ol_list'
      let serialized = serialize(serializeMention(nodeChild))

      // manually handle list serialization
      if (isList) {
        const serializedList = nodeChild.children
          .map((listChild) => {
            return serializeListItems(listChild as BlockType, nodeChild.type)
          })
          .join('')
        return serializedList
      }

      return serialized
    })
    .join('')
}

export const formatLocation = (location?: Types.Location.iLocation | string | null) => {
  if (!location) return ''

  if (typeof location === 'string') return location
  let formattedAddress = ''

  if (location?.address_1) {
    formattedAddress += location.address_1
  }
  if (location?.address_2) {
    formattedAddress += ` ${location.address_2}`
  }
  if (location?.address_3) {
    formattedAddress += `, ${location.address_3}`
  }
  if (location?.city) {
    formattedAddress += `, ${location.city}`
  }
  if (location?.state) {
    formattedAddress += `, ${location.state}`
  }
  if (location?.country) {
    formattedAddress += `, ${location.country}`
  }
  if (location?.postcode) {
    formattedAddress += ` ${location.postcode}`
  }
  return formattedAddress
}

export function numberWithCommas(x?: number | null) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
}

export function convertUnicode(input: string) {
  return input ? input.replace(/\\u([0-9a-fA-F]{4})/g, (match, hex) => String.fromCharCode(parseInt(hex, 16))) : ''
}

export const getDefaultCurrencyCode = (defaultCurrency: string | undefined, currencyCodeOptions: DropdownOption[]) =>
  defaultCurrency
    ? currencyCodeOptions?.find((currency) => (currency.id as String)?.toLowerCase() === defaultCurrency.toLowerCase()) ??
      currencyCodeOptions[0]
    : currencyCodeOptions[0]

export const getCurrencySymbolCode = (option: DropdownOption) => `${convertUnicode(option.symbol)} ${option.value}`

export function getFormDirtyValues<F extends FieldValues>(
  dirtyFields: Record<string, boolean | unknown>,
  values: F,
  parseValue?: (values: F) => Partial<typeof values>
): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    if (dirtyFields[key]) {
      return {
        ...prev,
        [key]:
          dirtyFields[key] && typeof dirtyFields[key] === 'object'
            ? getFormDirtyValues(dirtyFields[key] as Record<string, boolean | unknown>, values[key])
            : parseValue
            ? parseValue(values[key])
            : values[key],
      }
    }

    return prev
  }, {})

  return dirtyValues
}

export function getScrollParent(element: HTMLElement | null): HTMLElement | null {
  if (element === null) {
    return null
  }

  if (element.scrollHeight > element.clientHeight) {
    return element
  } else {
    return getScrollParent(element.parentNode as HTMLElement)
  }
}

export function encodeQoutes(str: string) {
  return str.replaceAll('"', '${dqt}').replaceAll("'", '${sqt}')
}

export function decodeQoutes(str: string) {
  return str.replaceAll('${dqt}', '"').replaceAll('${sqt}', "'")
}

export function formatHourAndMinutesToText({ hours, minutes }: { hours: number; minutes: number }): string {
  if (hours === 0 && minutes === 0) return '0 min'

  const hourStr = hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''}` : ''
  const minuteStr = minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : ''

  return [hourStr, minuteStr].filter(Boolean).join(' and ')
}

export function removeTimeFormat(dateTimeFormat: string): string {
  // Regular expression to match common time format parts with variations
  const timePattern = /\s?[Hh]{1,2}:[Mm]{1,2}(:[Ss]{1,2})?\s?[aA]?/g

  // Remove the time format parts from the date-time format string
  return dateTimeFormat.replace(timePattern, '').trim()
}
