import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectTalents } from '@redux/reducers/talentReducer'
import JobCard from '../job-card/job-card'
import { DeliverableDrawer, DeliverableDrawerContext } from '../drawer/DeliverableDrawer'
import { useContext, useState } from 'react'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useRouter } from 'next/router'
import { PathEnums } from '@utils/context/Navigation.context'
import { useQueryJob } from '@utils/hooks/useQueryJob'
import { useDeliverable } from '@utils/query/useDeliverable'
import { IsDirtyContext } from '@utils/context/isDirty.context'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useQueryClient } from '@tanstack/react-query'
import { tTalentProfile } from './profile'
import { useDeliverables } from '@utils/query/useDeliverables'
import { Types } from '@orbit'

export const TalentJobCards = ({ talentId, jobs }: tTalentProfile) => {
  const router = useRouter()

  const dispatch = useAppDispatch()
  const { workspace, settings } = useAppSelector(selectWorkspace)
  const { toggleModal, options: modalOptions } = useContext(ModalContext)

  const [isDirty, setIsDirty] = useState(false)
  const [expandedJob, setExpandedIndex] = useState('')
  const [selectedDeliverable, setSelectedDeliverable] = useState<string | undefined>()

  const queryClient = useQueryClient()

  useQueryJob({ expandedJob, setExpandedIndex })
  const deliverableQuery = useDeliverable()
  const deliverablesQuery = useDeliverables(expandedJob)

  const handleExitAction = (callback: () => void, drawerCtx?: DeliverableDrawerContext) => {
    if (isDirty) {
      toggleModal(ModalPages.UNSAVED_CHANGES, {
        hideOthers: false,
        modalProps: {
          [ModalPages.UNSAVED_CHANGES]: {
            exitWithoutSave: () => {
              callback()
              drawerCtx?.setIsDirty(false)
              drawerCtx?.setIsEditable(false)
            },
          },
        },
      })
    } else {
      callback()
    }
  }

  const handleExpand = (eid: string) => {
    setExpandedIndex(eid === expandedJob ? '' : eid)

    if (eid !== expandedJob) {
      queryClient.setQueryData(['deliverables', expandedJob], [])
    }

    setSelectedDeliverable(undefined)
    const { focusPanel, deliverableId, jobEid, ...restParams } = router.query
    router.push(
      {
        pathname: PathEnums.TALENT_PAGE,
        query: {
          ...restParams,
          ...(eid !== expandedJob ? { jobEid: eid } : {}),
        },
      },
      undefined,
      { shallow: true }
    )
  }

  return jobs?.length ? (
    <>
      {jobs?.map((job, index) => (
        // @ts-expect-error
        <JobCard
          key={`job-card-${index}`}
          {...job}
          expandedJob={expandedJob}
          onClickJob={(jobEid, cardContext) => {
            if (isDirty) {
              handleExitAction(() => {
                setIsDirty(false)
                handleExpand(jobEid)
                cardContext?.fetchJobDetails()
              })
            } else {
              handleExpand(jobEid)
            }
          }}
          onClickDeliverable={(deliverable, tabIndex) => {
            handleExitAction(() => {
              if (deliverable?.eid) {
                setSelectedDeliverable(deliverable?.eid)
                router.push(
                  {
                    query: {
                      ...router.query,
                      focusPanel: tabIndex,
                      ...(deliverable?.eid ? { deliverableId: deliverable?.eid } : {}),
                    },
                  },
                  undefined,
                  { shallow: true }
                )
              }
            })
          }}
        />
      ))}
      {(selectedDeliverable || deliverableQuery.isFetched) && (
        <IsDirtyContext.Provider value={{ isDirty, setIsDirty }}>
          <DeliverableDrawer
            onClose={() => {
              queryClient.setQueryData(['deliverable', selectedDeliverable || deliverableQuery.data?.eid], null)
              router.push(
                {
                  pathname: PathEnums.TALENT_PAGE,
                  query: {
                    workspace,
                    talentId,
                  },
                },
                undefined,
                { shallow: true }
              )
            }}
            onExit={handleExitAction}
            onSuccess={(deliverable) => {
              queryClient.invalidateQueries({
                queryKey: ['notification-unread-count'],
              })

              if (deliverable) {
                queryClient.setQueryData(['deliverable', deliverable.eid], (prevData: Types.Deliverable.iDeliverable) => ({
                  ...prevData,
                  ...deliverable,
                }))
                deliverablesQuery.updateDeliverable(deliverable)
              } else {
                deliverablesQuery.refetch({ cancelRefetch: true })
                router.query.deliverableId && queryClient.invalidateQueries({ queryKey: ['deliverable', router.query.deliverableId] })
              }
            }}
          />
        </IsDirtyContext.Provider>
      )}
    </>
  ) : (
    <></>
  )
}
