import { AxiosBaseInstance } from '@orbit/services'
import { useAppSelector } from '@redux/hooks'
import { selectAuth } from '@redux/reducers/authReducer'
import { useQuery } from '@tanstack/react-query'
import { getPersistedToken } from '@utils/query/useMe'
import { useWorkspace } from '@utils/query/useWorkspace'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useTranslation = (file: string) => {
  const router = useRouter()

  const { token, loggedOut } = useAppSelector(selectAuth)

  const { activeWorkspace } = useWorkspace()

  const { data: translations } = useQuery({
    queryKey: ['translations', file, router.query.workspace],
    queryFn: async () => {
      const response = await AxiosBaseInstance.get(`/translation`)
      return response.data
    },
    enabled: !!file && !!activeWorkspace && (getPersistedToken() || !!token?.access_token) && !loggedOut,
    initialData: JSON.parse(localStorage.getItem('translations') || '{}'),
  })

  useEffect(() => {
    if (translations) {
      localStorage.setItem('translations', JSON.stringify(translations))
    }
  }, [translations])

  const replacePlaceholders = (str: string, params: { [key: string]: any }) => {
    return str.replace(/{{\s*(\w+)\s*}}/g, (_, key) => {
      return params[key] !== undefined ? params[key] : `{{${key}}}`
    })
  }

  const t = (key: string, params?: { [key: string]: any }) => {
    if (translations && translations[key]) {
      let translation = translations[key]
      if (params) {
        translation = replacePlaceholders(translation, params)
      }
      return translation
    }
    return undefined
  }

  return { t }
}
