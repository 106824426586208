export namespace TrackingEventEnums {
  export enum Account {
    ACCOUNT_LOGIN = 'Account Login',
    ACCOUNT_CREATED = 'Account Created',
    ACCOUNT_SECTION_EDITED = 'Account Section Edited',
    ACCOUNT_LOGOUT = 'Account Logout',
  }

  export enum Activity {
    ACTIVITY_VIEWED = 'Activity Viewed',
  }

  export enum Feature {
    FEATURE_TOOLTIP_VIEWED = 'Tool Tip Viewed',
  }

  export enum Invite {
    INVITE_ROLE_SELECTED = 'Invite Role Selected',
    INVITE_STARTED = 'User Invite (inititate)',
    INVITE_JOIN_EXISTING = 'Join Invite With Existing Account',
    INVITE_CREATE_ACCOUNT = 'User Create Account From Invite',
    INVITE_DECLINE = 'User Declined Invite',
    INVITE_USER_TO_JOB = 'Invite User to Job',
    INVITE_USER_TO_JOB_CONFIRM = 'Confirm Invite User to Job',
    INVITE_USER_TO_DELIVERABLE = 'Invite User to Deliverable',
    INVITE_USER_TO_DELIVERABLE_CONFIRM = 'Confirm Invite User to Deliverable',
  }

  export enum Profile {
    PROFILE_CREATE_PROFILE = 'Create Talent Profile',
    PROFILE_CREATE_PROFILE_INITIATE = 'Create Talent Profile Initiated',
    PROFILE_UPDATE_PROFILE = 'Update Talent Profile',
    PROFILE_UPDATE_MANAGEMENT = 'Update Talent Management',
  }

  export enum Job {
    JOB_DRAFT_CREATED = 'Draft Job Created',
    JOB_ACCESS_LEVEL_CREATED = 'Job Access Level Selected',
    JOB_MARKED_COMPLETE = 'Job Marked Complete',
    JOB_SHORTLIST_UPDATED = 'Job Shortlist Updated',
    JOB_VIEWED = 'Job Viewed', //tested
    JOB_NOTIFICATION_PREFERENCE_UPDATED = 'Notification Preference Updated',
    JOB_PROFILE_ASSIGNED = 'Profile(s) Assigned',
    JOB_PROFILE_BOOKED = 'Profile Booked',
    JOB_PROFILE_MARKED_COMPLETE = 'Profile Marked Complete',
    JOB_PROFILE_MARKED_DONE = 'Profile Marked Done',
    JOB_PROFILE_MARKED_REMOVED = 'Profile Removed',
    JOB_PROFILE_SHORTLISTED = 'Profile Shortlisted',
    JOB_PROFILE_MARKED_SELECTED = 'Profile Selected', //ticking
    JOB_PROFILE_ASSIGN_UPDATED = 'Profile Assign Updated', // saving
    JOB_PROFILE_MARKED_UNSELECTED = 'Profile Unselected', //unticking
    JOB_PUBLISH = 'Publish Job', //tested
    JOB_USER_SELECTED = 'Job User Selected',
    JOB_USER_UNSELECTED = 'Job User Unselected',
    JOB_COST_MODE_SELECTED = 'Job Cost Mode Selected',
    JOB_ADD_TALENT = 'Add Talent to Job',
    JOB_TOGGLE_CARD = 'Job Card Toggled',
    JOB_UPDATED = 'Job Updated',
    JOB_INLINE_BUTTON_CLICK = 'User Started Inline job Start',
    JOB_GLOBAL_QUICK_START = 'User clicks the add job in global top menu',
    JOB_FILTER_BY_STATUS = 'Jobs Filtered by status',
    JOB_ADD_USER = 'Add User to Job',
    JOB_SHOW_DETAILS = 'Show Job Details',
    JOB_HIDE_DETAILS = 'Hide Job Details',
  }

  export enum Deliverable {
    DELIVERABLE_ADD = 'Deliverable Add',
    DELIVERABLE_UPDATE = 'Deliverable Update',
    DELIVERABLE_AGREEMENT_STATUS_UPDATED = 'Deliverable Agreement Status Updated',
    DELIVERABLE_PANEL_TOGGLED = 'Deliverable Panel Toggled',
    DELIVERABLE_CANCELLED = 'Deliverable Cancelled',
    DELIVERABLE_CATEGORY_SELECTED = 'Deliverable Category Selected',
    DELIVERABLE_COMPLETED = 'Deliverable Complete',
    DELIVERABLE_ARCHIVED = 'Deliverable Archived',
    DELIVERABLE_REMOVED = 'Deliverable Removed',
    DELIVERABLE_PUBLISH = 'Deliverable Published',
    DELIVERABLE_TYPE_SELECTED = 'Deliverable Type Selected',
    DELIVERABLE_CREATED = 'Deliverable(s) Created',
    DELIVERABLE_FEE_UPDATED = 'Fee Updated',
    DELIVERABLE_INVITE_USER = 'Invite User (complete)',
    DELIVERABLE_ADD_TALENT = 'Add Talent to Deliverable',
    DELIVERABLE_PANEL_TAB_SWITCH = 'Deliverable Panel Tab Switch',
    DELIVERABLE_DUPLICATE = 'Deliverable Duplicate',
    DELIVERABLE_ACTIONS_OPEN = 'Open Deliverable Actions Menu',
    DELIVERABLE_ACTIONS_CLOSE = 'Close Deliverable Actions Menu',
  }

  export enum ExternalForm {
    EXTERNAL_FORM_SENT = 'External Form Sent',
  }

  export enum Dashboard {
    DASHBOARD_REFINE = 'Refine',
    DASHBOARD_VIEW_MORE = 'View More',
  }

  export enum Role {
    ORGANISATION_CREATED = 'Create Role Organisation',
    DEPARTMENT_CREATED = 'Create Role Department',
    DEPARTMENT_ATTACH_USERS = 'Department Role Attach Users',
    ORGANISATION_ATTACH_USERS = 'Organisation Role Attach Users',
    ORGANISATION_UPDATED = 'Update Role Organisation',
    DEPARTMENT_UPDATED = 'Update Role Department',
  }

  export enum Others {
    EDIT_MODE = 'Edit mode',
    CANCEL_EDIT = 'Cancel edit mode',
    ATTACH_FILE = 'Attach file',
    DETACH_FILE = 'Detach file',
    ADD_COMMENT = 'Add comment',
    FOCUS_PANEL_TOGGLED = 'Focus Panel Toggled',
    GLOBAL_SEARCH = 'Global Search',
    GLOBAL_SEARCH_SWITCH_TAB = 'Global Search Switch Tab',
    GLOBAL_SEARCH_RESULT_CLICK = 'Global Search Result Click',
    GLOBAL_SEARCH_HOVER = 'Global Search Hover',
    GLOBAL_SEARCH_SHOW_MORE_RESULTS = 'Global Search Show More Results',
    SORT = 'sort',
    DIRECTORY_SEARCH = 'Directory Search',
  }
  export enum Notification {
    CLICKED_NOTIFICATION_BELL = ' Clicked Notification Bell',
    CLICKED_NOTIFICATION = 'Clicked Notification',
    MARK_ALL_NOTIFICATION_READ = 'Mark All Notification as Read',
  }

  export enum Import {
    SWITCH_IMPORT_TYPE = 'Switch Import Type',
    START_IMPORT = 'Start Import',
    DOWNLOAD_IMPORT_TEMPLATE = 'Download Import Template',
    CHANGE_FILE = 'Change Import File',
    REMOVE_FILE = 'Remove Import File',
    UPLOAD_IMPORT_FILE = 'Upload Import File',
    COMPLETE_IMPORT = 'Complete Import',
    VIEW_IMPORTER = 'View Importer Data',
  }

  export enum Mention {
    USER_MENTION = '@Mention',
  }

  export enum Settings {
    USER_SETTINGS_UPDATED = 'User Settings Updated',
    USER_NOTIFICATION_SETTINGS_UPDATED = 'User Notification Settings Updated',
    WORKSPACE_SETTINGS_UPDATED = 'Workspace Settings Updated',
    WORKSPACE_LOGO_UPDATED = 'Workspace Logo Updated',
  }

  export enum Socials {
    CREATE_JOB_CAMPAIGN = 'Create Job Social Campaign',
    FETCH_JOB_ANALYTICS = 'Fetch Job Social Campaign Analytics',
    ADD_TALENT_SOCIAL_PLATFORM = 'Add Talent Social Platform',
    FETCH_TALENT_ANALYTICS = 'Fetch Talent Social Platform Analytics',
  }

  export enum Reporting {
    CREATING_REPORT = 'Creating Report',
    REMOVING_REPORT = 'Removing Report',
    DOWNLOADING_REPORT = 'Downloading Report',
  }
}
