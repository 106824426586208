import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  GlobalStyles,
  Icon,
  Input,
  Loading,
  ScrollView,
  StackV2,
  Text,
  Tooltip,
  View,
  applyResponsiveProp,
  iCardUserSelect,
  useScreenDimensions as screenDimensions,
} from '@andromeda'
import { Chip, ModalHeader, ProfileCard } from '@components'
import { Button as MUIButton, Stack } from '@mui/material'
import MuiPagination from '@mui/material/Pagination'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectTalentProfile, setManagers, updateManagement } from '@redux/reducers/talentProfileReducer'
import { listManagementUsers, resetUserList, selectUsers } from '@redux/reducers/usersReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { AppStyles } from '@styles'
import { useQueryClient } from '@tanstack/react-query'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { MANAGEMENT_ROLES } from '@utils/enum/roles/managers'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { useMe } from '@utils/query/useMe'
import { useTalentQuery } from '@utils/query/useTalent'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { FilterOptions } from '@utils/types/filter'
import { iUser } from '@utils/types/user'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

const ROLES_OPTIONS = [
  {
    eid: MANAGEMENT_ROLES.PRIMARY,
    value: 'Primary Manager',
  },
  {
    eid: MANAGEMENT_ROLES.MANAGER,
    value: 'Profile Manager',
    label: 'Profile Manager',
  },
  {
    eid: MANAGEMENT_ROLES.OVERSIGHT,
    value: 'Profile Oversight',
    label: 'Profile Oversight',
  },
]

const ProfileManagement = () => {
  const router = useRouter()

  const queryClient = useQueryClient()

  const { closeModal, options } = useContext(ModalContext)

  const { data: details, isPending: isTalentPending } = useTalentQuery({
    eid: (router.query.talentId as string) || options?.modalProps?.profileManagement.eid,
  })
  const { apiStatus, managers } = useAppSelector(selectTalentProfile)
  const { workspace } = useAppSelector(selectWorkspace)

  const { screenSize } = useScreenDimensions()
  const { size } = screenDimensions()
  const screenIsMedium = screenSize.md
  const screenIsSmall = screenSize.sm

  const [overflow, setOverflow] = useState<boolean>(false)
  const scrollviewMaxHeight = 310
  const [usersList, setUsersList] = useState<iUser[]>([])
  const [hasInvalidRole, setHasInvalidRole] = useState<boolean>(false)
  const [showPermissionInfo, setShowPermissionInfo] = useState<boolean>(false)
  const [managersAdded, setManagersAdded] = useState<boolean>(false)
  const [searchKeys, setSearchKeys] = useState<string[]>([])
  const [selectedFilters, setSelectedFilters] = useState<FilterOptions[]>([])
  const [queryString, setQueryString] = useState<string | undefined>(undefined)
  const [hasChanges, setHasChanges] = useState<boolean>(false)

  const [selectedUsers, setSelectedUsers] = useState<iUser[]>(() => {
    if (managers?.length) {
      if (!details?.eid) setHasChanges(true)

      return managers.map((manager) => {
        const roleOption = ROLES_OPTIONS.find((role) => role.eid === manager.management?.management_level)
        if (!roleOption) setHasInvalidRole(true)
        const existingManager: iUser = {
          eid: manager.eid,
          name: manager.name,
          profile_image: manager.profile_image || null,
          permission: roleOption,
          roles: [],
          email: '',
        }

        return existingManager
      })
    }
    return []
  })

  const responsiveStyles = {
    button: {
      size: screenIsMedium ? 48 : AppStyles.BUTTON_SIZE_LARGE,
    },
  }

  const dispatch = useAppDispatch()
  const { managementUsers: users, listMeta, listStatus } = useAppSelector(selectUsers)

  const { data: loggedInUser } = useMe()

  const doFetchUsers = useCallback(
    (params: Types.User.iUserListParams) => {
      dispatch(listManagementUsers(params))
    },
    [dispatch]
  )

  const doReset = useCallback(() => {
    dispatch(resetUserList())
  }, [dispatch])

  useEffect(() => {
    doFetchUsers({ page: '1' })
    return () => {
      doReset()
    }
  }, [dispatch, doFetchUsers, doReset])

  const doPreselectUsers = useCallback(() => {
    const list = users?.map((user) => {
      const thisUser: iUser = {
        ...user,
        selected: false,
      }

      // check if user already has permission
      const hasPermission = selectedUsers.find((selected) => selected.eid === thisUser.eid)
      if (hasPermission) {
        thisUser.selected = true
        thisUser.permission = hasPermission.permission
      }

      return thisUser
    })

    setUsersList(list || [])
  }, [selectedUsers, users])

  useEffect(() => {
    if (listStatus === 'success') {
      doPreselectUsers()
    } else if (listStatus === 'pending') {
      setUsersList([])
    }
  }, [doPreselectUsers, listStatus])

  const _paginationData = useMemo(() => {
    if (listMeta) {
      const currentPage = listMeta?.pagination.current_page ?? 0
      const totalPage = listMeta?.pagination.total_number_of_pages
      const nextPage = currentPage + 1 > totalPage ? 0 : currentPage + 1
      const previousPage = currentPage - 1 <= 0 ? 0 : currentPage - 1
      const totalResult = listMeta?.pagination.total_results || 0

      return {
        currentPage,
        totalPage,
        nextPage,
        previousPage,
        totalResult,
      }
    }
  }, [listMeta])

  const _search = (e: any) => {
    if (e.nativeEvent.key === 'Enter') {
      const searchPillKey = e.target.value.toLowerCase().replace(/[^a-z-A-Z0-9]/g, '')
      const searchPill = { eid: searchPillKey, value: e.target.value }
      const key = e.target.value.trim()
      if (key && !selectedFilters.find((selected) => selected.eid === searchPillKey)) {
        setSelectedFilters([...selectedFilters, searchPill])
        const newSearchKeys = [...searchKeys, key]
        setSearchKeys(newSearchKeys)

        const params: Types.User.iUserListParams = { page: '1' }
        if (newSearchKeys.length) params.query = newSearchKeys
        doFetchUsers(params)
      }
    }
  }

  const _validateSelectedusers = (validateLists: iUser[]) => {
    const hasInvalidRoles = validateLists.filter((validate) => !validate.permission)
    setHasInvalidRole(hasInvalidRoles.length > 0)
  }

  const _handleSelectUser = (e: any) => {
    setHasChanges(true)
    if (e.selected && e.details) {
      const newSelected: iUser = {
        eid: e.details.eid,
        name: e.details.name,
        profile_image: e.details.profile_image,
        permission: ROLES_OPTIONS[0],
        roles: e.details.roles,
        email: '',
      }
      setSelectedUsers([...selectedUsers, newSelected])
      setHasInvalidRole(true)
    } else {
      const newSelectedUsers = selectedUsers.filter((u: iUser) => u.eid !== e.details?.eid)
      _validateSelectedusers(newSelectedUsers)
      setSelectedUsers(newSelectedUsers)
    }
  }

  const _handleRoleSelect = (role: iCardUserSelect) => {
    if (role) {
      setHasChanges(true)
      setHasInvalidRole(false)
      const { item, user } = role
      const updatedSelectedUsers = selectedUsers.map((selectedUser) => {
        if (selectedUser.eid === user.eid) {
          return {
            ...selectedUser,
            permission: item,
          }
        }
        if (!selectedUser.permission) setHasInvalidRole(true)
        return selectedUser
      })
      setSelectedUsers(updatedSelectedUsers)

      // apply the changes to the users list as well
      // to make the role already available when re-added (if previously applied)
      const updatedUsersList = usersList.map((listedUser) => {
        if (listedUser.eid === user.eid) {
          return {
            ...listedUser,
            permission: item,
          }
        }
        return listedUser
      })

      setUsersList(updatedUsersList)
    }
  }

  const _renderErrorMessage = () => {
    if (hasInvalidRole && selectedUsers.length > 0 && !screenIsSmall) {
      return (
        <View key={'profile-management-error-message'}>
          <Text
            style={{
              color: GlobalStyles.STATUS_NEGATIVE,
              fontSize: GlobalStyles.FONT_SIZES.SMALL,
            }}>
            {'Please select a role for all users above'}
          </Text>
        </View>
      )
    }
    return undefined
  }

  const _renderPermissionInfoLink = () => {
    return (
      <Text
        type={'paragraph'}
        onPress={() => setShowPermissionInfo((prevState) => !prevState)}
        style={{
          fontSize: GlobalStyles.FONT_SIZES.TINY,
          color: GlobalStyles.SLATE_500,
          fontWeight: '400',
          textDecorationLine: 'underline',
        }}>
        {!showPermissionInfo ? 'Learn more about the rules and permissions.' : 'Hide details.'}
      </Text>
    )
  }

  const _handleSubmit = () => {
    if (details?.eid) {
      trackEvent({ event: TrackingEventEnums.Profile.PROFILE_UPDATE_MANAGEMENT, eventProperties: { workspace } })
      const talentManagers: Types.Talent.iTalentManagementPayload = {
        management: {},
      }

      selectedUsers.forEach((user) => {
        if (user.permission) {
          talentManagers.management[user.eid] = user.permission.eid
        }
      })

      toast
        .promise(dispatch(updateManagement({ eid: details?.eid, payload: talentManagers })), {
          success: 'Changes have been saved.',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            dispatch(setManagers(response.payload.active_managers))
            queryClient.setQueryData(['talent', details?.eid], { ...details, active_managers: response.payload.active_managers })

            closeModal(ModalPages.PROFILE_MANAGEMENT)
          } else {
            toast.error(response.payload.message || 'Changes were not saved. Please try again.')
          }
        })
    }
  }

  const _renderBackButton = () => {
    return (
      <Button
        type={'secondary'}
        text={'Back'}
        onPress={() => setManagersAdded(false)}
        style={{
          height: responsiveStyles.button.size,
          borderRadius: AppStyles.BUTTON_RADIUS,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.xs,
        }}
      />
    )
  }

  const _getUserInitials = (user: iUser) => {
    const thisUser = users?.find((u: iUser) => u.eid === user.eid)
    const words = thisUser ? thisUser.name.split(' ') : user.name.split(' ')
    let initials = ''
    words.forEach((word: string) => {
      initials += word[0]
    })
    return initials.substring(0, 2).toUpperCase()
  }

  const _removeFilterFromDropdown = (index: number) => {
    const newSelectedFitlers: FilterOptions[] = []
    let key: FilterOptions | null = null
    selectedFilters.forEach((pill: any, pillIndex: number) => {
      if (index !== pillIndex) newSelectedFitlers.push(pill)
      else key = pill
    })

    let newParams: Types.User.iUserListParams = { page: '1' }
    if (key) {
      const newSearchKeys: string[] = []
      searchKeys.forEach((str) => {
        if (str !== key?.value) newSearchKeys.push(str)
      })
      setSearchKeys(newSearchKeys)
      if (newSearchKeys.length) newParams.query = newSearchKeys
    }

    setSelectedFilters(newSelectedFitlers)
    doFetchUsers(newParams)
  }

  return (
    <StackV2
      height="auto"
      direction="column"
      className="dialog-container"
      sx={{ backgroundColor: GlobalStyles.MONO_WHITE, width: { sm: '500px' } }}>
      <ModalHeader
        modalTitle={`Add Management ${details?.display_name ? `for ${details?.display_name}` : ''}`}
        modalContext={'profileManagement'}
        leftIcon={
          <View>
            {!managersAdded ? (
              <Icon.Briefcase strokeWidth={1} size={26} color={GlobalStyles.MONO_BLACK} />
            ) : (
              <Icon.CheckCircle strokeWidth={1} size={26} color={GlobalStyles.MONO_BLACK} />
            )}
          </View>
        }
      />

      <StackV2 className="dialog-content" direction={'column'} justifyContent={'flex-start'} rowGap="16px">
        {!managersAdded ? (
          <>
            <Stack style={{ backgroundColor: GlobalStyles.SLATE_50, borderRadius: '6px' }}>
              <Input
                placeholder={'Search for user'}
                placeholderTextColor={GlobalStyles.SLATE_700}
                style={{ borderWidth: 0 }}
                leftElement={
                  <View>
                    <Icon.Search size={GlobalStyles.ICON_SIZE} />
                  </View>
                }
                value={queryString || ''}
                onChangeText={(text) => setQueryString(text)}
                onKeyPress={_search}
              />
            </Stack>
            <Stack direction="row" columnGap="12px" alignItems="center">
              <Icon.Info size={AppStyles.ICON_SIZES.XS} color={GlobalStyles.SLATE_500} />
              <Text style={{ color: GlobalStyles.SLATE_500, fontSize: GlobalStyles.FONT_SIZES.TINY }} size={'tiny'}>
                To add new users return to the User Directory.&nbsp;
                {_renderPermissionInfoLink()}
              </Text>
            </Stack>

            <StackV2
              direction={'column'}
              alignItems={'flex-start'}
              spacing={2}
              sx={{
                paddingTop: '16px',
              }}>
              {showPermissionInfo && (
                <StackV2
                  // Needs key for react to know which component to show/hide
                  // Otherwise, it's gonna generate a new component
                  key="details"
                  direction={'row'}
                  flex={1}
                  height={'auto'}
                  alignItems={'flex-start'}
                  style={{
                    backgroundColor: GlobalStyles.SLATE_50,
                    padding: GlobalStyles.PADDING_SIZES.md,
                    borderRadius: GlobalStyles.BORDER_RADIUS,
                  }}>
                  <StackV2 direction={'column'} flex={1} style={{ paddingRight: GlobalStyles.PADDING_SIZES.xs }}>
                    <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                      <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {'The '}
                      </Text>
                      <Text type={'paragraph'} bold style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {'Admin '}
                      </Text>
                      <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {
                          'user is focused on helping all other users create jobs, find talent and delivery jobs. They will have oversight and edit rights across all jobs and deliverables that are created by Job Creators in the workspace. It’s best to only have one admin per workspace otherwise users will be overwhelmed with all the notifications across all jobs, especially when the number of jobs grow. Admin users will also be able to assign and book talent profiles but if you want them to act as the talent manager and receive specific talent updates you must also add them as a Profile Manager on the talent you prefer.'
                        }
                      </Text>
                    </Text>
                    <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                      <Text type={'paragraph'} bold style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {'Job Creators'}
                      </Text>
                      <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {
                          ' are focused on creating and managing the delivery of jobs in the workspace. They act as the owner of the job(s). They will only have access to edit jobs they own or have been added to with ‘edit’ rights. It is only these jobs that they will receive notifications and reminders for. They own the budget of the job and its deliverables but cannot book talent. They will be notified for actions on jobs and profiles related to jobs they own or have been given access rights to.'
                        }
                      </Text>
                    </Text>
                    <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                      <Text type={'paragraph'} bold style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {'Profile Managers'}
                      </Text>
                      <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {
                          ' are focused on the management of Talent Profiles. They are often the talent managers in the business. There can be multiple Profile Managers on a Talent Profile. This role can act on behalf of the talent, updating their profile, responding in chat, marking them as ‘done’, cancelling or removing their talent from jobs. In addition to that, '
                        }
                      </Text>
                      <Text type={'paragraph'} bold style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {'Primary Managers'}
                      </Text>
                      <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                        {' will be notified for all the messages that are sent to the talent profile.'}
                      </Text>
                    </Text>
                    {/* <Text type={'paragraph'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
                                        {
                                            'Note: A Talent Profile is considered as an asset in the system and is not always attached to a single account or person.'
                                        }
                                    </Text> */}
                  </StackV2>
                  <Button type="primary" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} onPress={() => setShowPermissionInfo(false)}>
                    <Text>
                      <Icon.X size={GlobalStyles.ICON_SIZE} color={GlobalStyles.PRIMARY_500} />
                    </Text>
                  </Button>
                </StackV2>
              )}

              {selectedFilters.length > 0 && (
                <StackV2
                  key={'selected-filters-section'}
                  direction={'row'}
                  height={'auto'}
                  style={{ paddingLeft: 0, paddingRight: 0, flexWrap: 'wrap' }}>
                  {selectedFilters.map((chip: FilterOptions, index: number) => {
                    return (
                      <Chip
                        key={`filter-${chip.eid}-${index}`}
                        text={chip.value}
                        textStyle={{ fontSize: GlobalStyles.FONT_SIZES.BASE, color: GlobalStyles.MONO_BLACK }}
                        rightElement={
                          <Button
                            type={'secondary'}
                            style={{
                              width: AppStyles.CHIP_BUTTON_SIZE,
                              height: AppStyles.CHIP_BUTTON_SIZE,
                              padding: 0,
                              backgroundColor: 'transparent',
                            }}
                            onPress={() => _removeFilterFromDropdown(index)}>
                            <Text style={{ color: GlobalStyles.SLATE_700 }}>
                              <Icon.XCircle size={AppStyles.ICON_SIZES.SM} />
                            </Text>
                          </Button>
                        }
                        style={{ marginBottom: AppStyles.MARGIN_SIZES.XS, backgroundColor: '#EEEEEE' }}
                      />
                    )
                  })}
                </StackV2>
              )}

              {listStatus !== 'success' ? (
                <View style={{ width: '100%', justifyContent: 'center' }}>
                  <Loading />
                </View>
              ) : (
                <>
                  <Text key={'search-result-count'} type={'paragraph'} size={'sm'}>
                    <strong>{_paginationData?.totalResult || 0}</strong>&nbsp;users match your criteria
                  </Text>

                  <ScrollView
                    style={{
                      width: '100%',
                      height: '100%',
                      marginBottom: 20,
                    }}
                    onLayout={(e) => {
                      if (e.nativeEvent.layout.height >= scrollviewMaxHeight) setOverflow(true)
                      else setOverflow(false)
                    }}
                    contentContainerStyle={{ minHeight: '100%' }}>
                    <StackV2 direction={'column'} style={{ maxHeight: scrollviewMaxHeight, paddingRight: overflow ? 10 : 0 }} spacing={2}>
                      {usersList.map((user: iUser) => {
                        return (
                          <Card.User
                            key={`user-${user.eid}`}
                            userData={user}
                            onSelect={_handleSelectUser}
                            selected={user.selected}
                            type={'manager'}
                            avatarPlaceholder={
                              <Text
                                style={{
                                  textAlign: 'center',
                                  fontSize: GlobalStyles.FONT_SIZES.BASE,
                                }}>
                                {_getUserInitials(user)}
                              </Text>
                            }
                            showPermissionSelect={true}
                            showContactButton={false}
                            showMessageButton={false}
                            dropdownOptions={ROLES_OPTIONS}
                            selectedItem={user.permission ?? ROLES_OPTIONS[0]}
                            onPermissionSelect={_handleRoleSelect}
                            disableMessage={true}
                            nameOverride={user.eid === loggedInUser?.eid ? 'You' : undefined}
                            style={{ borderRadius: AppStyles.BORDER_RADIUS_SIZES.XS }}
                            validateDropdown={true}
                            dropdownLength="162px"
                          />
                        )
                      })}
                    </StackV2>
                  </ScrollView>
                </>
              )}
            </StackV2>
          </>
        ) : (
          <StackV2
            direction={'column'}
            alignItems={'flex-start'}
            spacing={2}
            style={{
              paddingLeft: GlobalStyles.PADDING_SIZES.xl,
              paddingRight: GlobalStyles.PADDING_SIZES.xl,
              // marginBottom: AppStyles.MARGIN_SIZES.LG,
              ...applyResponsiveProp(
                {
                  xxs: { paddingLeft: GlobalStyles.PADDING_SIZES.sm, paddingRight: GlobalStyles.PADDING_SIZES.sm },
                  xs: { paddingLeft: GlobalStyles.PADDING_SIZES.sm, paddingRight: GlobalStyles.PADDING_SIZES.sm },
                  sm: { paddingLeft: GlobalStyles.PADDING_SIZES.sm, paddingRight: GlobalStyles.PADDING_SIZES.sm },
                  default: null,
                },
                size
              ),
            }}>
            <Text type={'paragraph'}>{`The users below have been added to ${details?.display_name}’s profile.`}</Text>

            {selectedUsers.map((user: iUser) => {
              let userRole = null
              const roleObj = ROLES_OPTIONS.find((role) => role.eid === user.permission?.eid)
              if (roleObj) userRole = roleObj.value
              return (
                <ProfileCard
                  key={user.eid}
                  name={user.name}
                  // department={user.department || undefined}
                  avatar={user.profile_image?.full_url}
                  role={userRole}
                  wrapperStyle={{
                    backgroundColor: GlobalStyles.SLATE_50,
                    padding: AppStyles.PADDING_SIZES.BASE,
                    borderRadius: AppStyles.BORDER_RADIUS_SIZES.XS,
                  }}
                />
              )
            })}
          </StackV2>
        )}
        {usersList && usersList.length > 0 && (
          <Stack alignItems="center" width="100%" paddingY="16px">
            <MuiPagination
              page={_paginationData?.currentPage}
              size="large"
              count={_paginationData?.totalPage}
              shape="rounded"
              onChange={(_, page) => {
                const params: Types.User.iUserListParams = {
                  page: page.toString(),
                }
                if (searchKeys.length) params.query = searchKeys
                doFetchUsers(params)
              }}
            />
          </Stack>
          // <Pagination
          //     onPaginate={(paginationData) => {
          //         const params: Types.User.iUserListParams = {
          //             page: paginationData.currentPageNumber.toString(),
          //         }
          //         if (searchKeys.length) params.query = searchKeys
          //         doFetchUsers(params)
          //     }}
          //     paginationData={{
          //         currentPageNumber: _paginationData?.currentPage || 0,
          //         prevPageNumber: _paginationData?.previousPage || 0,
          //         nextPageNumber: _paginationData?.nextPage || 0,
          //         totalPageCount: _paginationData?.totalPage || 0,
          //     }}
          //     style={[]}
          // />
        )}
      </StackV2>
      <Stack direction="row" justifyContent="space-between" className="dialog-footer">
        <StackV2 flex={1} direction="row" height={'auto'} alignItems="flex-start" width={'auto'} style={{ flexWrap: 'wrap' }}>
          <AvatarGroup offset={5} extraAvatarProps={{ size: 'xs' }}>
            {selectedUsers?.map((user, index) => (
              <Tooltip key={user.eid} text={user.name ?? ''} showIcon={false}>
                <Avatar
                  size={'md'}
                  style={{
                    borderWidth: 2,
                    borderColor: GlobalStyles.MONO_WHITE,
                    alignItems: 'center',
                  }}
                  placeHolder={
                    <Text style={{ textAlign: 'center', fontSize: GlobalStyles.FONT_SIZES.MEDIUM }}>{_getUserInitials(user)}</Text>
                  }
                  image={user.profile_image?.full_url}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        </StackV2>
        <StackV2 alignItems={'flex-start'} height={'auto'} width={screenIsMedium ? 'auto' : '100%'} justifyContent={'flex-end'}>
          <MUIButton variant="contained" onClick={_handleSubmit} disabled={apiStatus === 'pending'}>
            {`Confirm Selection (${selectedUsers?.length ?? 0})`}
          </MUIButton>
        </StackV2>
      </Stack>
    </StackV2>
  )
}

export { ProfileManagement }
