import { GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'

const ConfirmRemoveDialog = () => {
  const { closeModal, options } = useContext(ModalContext)
  const [isLoading, setIsLoading] = useState(false)
  const props = options?.modalProps?.confirmRemoveDialog

  const handleConfirm = () => {
    props?.onConfirm?.({ isLoading, setIsLoading, close: () => closeModal(ModalPages.CONFIRM_REMOVE_DIALOG) })
  }

  const handleCancel = () => {
    props?.onCancel?.()
    closeModal(ModalPages.CONFIRM_REMOVE_DIALOG)
  }

  return (
    <Stack minWidth={{ sm: '500px' }} maxWidth="500px">
      <ModalHeader
        modalContext={ModalPages.CONFIRM_REMOVE_DIALOG}
        modalTitle={props?.title || 'Remove Data'}
        onCancel={() => closeModal(ModalPages.CONFIRM_REMOVE_DIALOG)}
      />
      <Stack className="dialog-content" direction="row" flexWrap="wrap">
        <Typography>{props?.message || "Are you sure you want to remove this data?. You won't be able to undo this."}</Typography>
      </Stack>
      <Stack className="dialog-footer" justifyContent="end" direction="row" columnGap="8px">
        <Button variant="outlined" disabled={isLoading} onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isLoading} onClick={handleConfirm}>
          {isLoading ? <CircularProgress size={12} style={{ marginRight: `${GlobalStyles.MARGIN_SIZES['2xs']}px` }} /> : undefined}
          {isLoading ? 'Removing...' : 'Remove'}
        </Button>
      </Stack>
    </Stack>
  )
}

export default ConfirmRemoveDialog
