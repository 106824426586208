import { Avatar, AvatarGroup, Badge, GlobalStyles, Icon, Loading } from '@andromeda'
import { Box, Button, Chip, CircularProgress, Grid, Paper, Stack, Tooltip as MuiTooltip, Typography, Tooltip } from '@mui/material'
import { Types } from '@orbit'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { NavigationContext, PathEnums } from '@utils/context/Navigation.context'
import { useMenu } from '@utils/hooks/useMenu'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { JobProps, JobStatusTypes } from '@utils/types/jobs'
import moment from 'moment'
import Link from 'next/link'
import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { DeliverableList, tDeliverableList } from '../deliverable-list/DeliverableList'
import { ActionMenu } from './ActionMenu'
import { JobCell } from './JobCell'
import { QuickLook } from '../QuickLook'
import { useCurrencyCode } from '@utils/hooks/useCurrencyCode'
import { useCurrencyFormat } from '@utils/hooks/useCurrencyFormat'
import { useQueryClient } from '@tanstack/react-query'
import { useDeliverables } from '@utils/query/useDeliverables'
import { JOB_ROW_SIZES, RowGrid, TypeColumn } from './components'
import { removeTimeFormat } from '@utils/functions/helperFunctions'
import { useTranslation } from '@utils/hooks/useTranslation'

export const STATUS_BG_COLORS: { [key: string]: string } = {
  draft: GlobalStyles.STATUS_DRAFT,
  live: GlobalStyles.STATUS_POSITIVE,
  done: GlobalStyles.STATUS_INFO,
  completed: GlobalStyles.STATUS_INFO,
  cancelled: GlobalStyles.STATUS_NEGATIVE,
}
export const TYPE_CHIP_STYLES = {
  borderRadius: '5px',
  padding: 0,
  fontSize: '10px',
  backgroundColor: GlobalStyles.SLATE_50,
  color: GlobalStyles.SLATE_700,
  border: `1px solid ${GlobalStyles.SLATE_100}`,
}
export const STATUS_CHIP_STYLES = (status: string) => ({
  fontSize: '12px',
  backgroundColor: STATUS_BG_COLORS[status.toLowerCase()],
  color: ['live', 'done'].some((color) => status.toLowerCase() === color) ? 'white' : GlobalStyles.SLATE_700,
  borderRadius: '8px',
})

export const OVERFLOW_TEXT = {
  sx: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}
export const STATUS_MAPPING = (status: JobStatusTypes) => {
  switch (status) {
    case 'COMPLETED':
      return 'DONE'
    default:
      return status
  }
}
interface JobCardProps extends JobProps, Pick<tDeliverableList, 'onClickDeliverable'> {
  expandedJob: string
  onClickJob: (index: string, context: { fetchJobDetails: () => void }) => void
  selectedDeliverableEid?: string
  isDeliverableDirty?: boolean
}

function JobCard({
  eid,
  name,
  event_types,
  client,
  organisation,
  numberOfDeliverablesCompleted,
  numberOfDeliverables,
  onClickJob,
  onClickDeliverable,
  expandedJob,
  talents,
  selectedDeliverableEid,
  isDeliverableDirty,
  ...job
}: JobCardProps) {
  const queryClient = useQueryClient()
  const { viewedJobStatus } = useAppSelector(selectJobs)
  const { workspace, settings: workspaceSettings } = useAppSelector(selectWorkspace)

  const { goToRequestById } = useContext(NavigationContext)
  const { data: deliverables, updateDeliverable, isPending } = useDeliverables(expandedJob)

  const [isEditVisible, setIsEditVisible] = useState(false)

  const ref = useRef(null)
  const { anchorEl: jobMenuAnchorEl, handleClick: handleClickJobMenu, handleClose: handleCloseJobMenu, open: isJobMenuOpen } = useMenu()
  const currenyCodeOptions = useCurrencyCode()
  const formatCurrency = useCurrencyFormat()
  const { t } = useTranslation('talent')

  const isExpanded = useMemo(() => expandedJob === eid, [eid, expandedJob])

  const status = STATUS_MAPPING(job.status?.type ?? job.status)
  const jobDateTimeFormat = useMemo(() => {
    if (workspaceSettings?.date_format) {
      if (job.date_type !== 'FIXED') {
        return removeTimeFormat(workspaceSettings?.date_format)
      }

      return workspaceSettings?.date_format
    }

    return 'DD MMM YY'
  }, [job.date_type, workspaceSettings?.date_format])
  const start_datetime = job.start_datetime ? moment(job.start_datetime.split(' ')[0]).format(jobDateTimeFormat) : ''
  const end_datetime = job.end_datetime ? moment(job.end_datetime.split(' ')[0]).format(jobDateTimeFormat) : ''

  const uniqTalents = useMemo(() => {
    return talents?.filter((talent, index, self) => index === self.findIndex((t) => t.eid === talent.eid))
  }, [talents])

  const fetchJobDetails = useCallback(() => {
    if (!deliverables?.length) {
      queryClient.invalidateQueries({ queryKey: ['deliverables', eid], type: 'active' })
    }

    trackEvent({
      event: TrackingEventEnums.Job.JOB_TOGGLE_CARD,
      eventProperties: {
        component_name: 'Job Card',
        workspace: workspace,
        job_name: name,
      },
    })
  }, [deliverables, eid, name, queryClient, workspace])

  const handleClickJob = useCallback(() => {
    onClickJob(eid, { fetchJobDetails })

    if (!isDeliverableDirty) {
      fetchJobDetails()
    }
  }, [fetchJobDetails, isDeliverableDirty, onClickJob, eid])

  return (
    <Paper
      elevation={3}
      style={{
        // padding: '10px 0',
        boxShadow: 'none',
        border: `1px solid ${GlobalStyles.SLATE_100}`,
        borderRadius: '6px',
      }}
      ref={ref}
      role="listitem"
      aria-label="job list item">
      <Stack direction="row" alignItems="center">
        <Box
          sx={{
            alignSelf: 'flex-start',
            width: 0,
            height: 0,
            borderTopLeftRadius: '6px',
            borderTop: '0px solid transparent',
            borderBottom: '23px solid transparent',
            borderLeft: `23px solid ${STATUS_BG_COLORS[status.toLowerCase()]}}`,
          }}
        />
        <Grid
          container
          columnSpacing="6px"
          alignItems={'center'}
          sx={{ cursor: 'pointer', marginX: 0, overflow: 'auto' }}
          marginBottom={{ xxs: '8px', sm: 0 }}
          onMouseEnter={() => setIsEditVisible(true)}
          onMouseLeave={() => setIsEditVisible(false)}>
          <RowGrid
            expanded={!!selectedDeliverableEid}
            left={{
              style: {
                className: 'pt-2 pr-2',
                paddingBottom: selectedDeliverableEid ? '0' : '6px',
              },
              component: (
                <Link
                  href={{
                    pathname: PathEnums.REQUEST_BY_ID,
                    query: { workspace: workspace, requestId: eid },
                  }}
                  style={{ textDecoration: 'none' }}>
                  <Stack
                    direction="row"
                    borderRight={{ xxs: 'none', sm: `1px solid ${GlobalStyles.SLATE_100}` }}
                    paddingTop="5.5px"
                    marginBottom="5.5px"
                    columnGap="6px"
                    paddingRight="10px"
                    alignItems="center"
                    justifyContent="space-between">
                    <Stack overflow="hidden">
                      <Stack direction="row" alignItems="center">
                        <Typography
                          {...OVERFLOW_TEXT}
                          whiteSpace={{ xxs: 'normal', sm: 'nowrap' }}
                          fontWeight="700"
                          fontSize={{ xxs: '16px', sm: '14px' }}
                          letterSpacing="-0.14px"
                          color={GlobalStyles.MONO_BLACK}
                          aria-label={name}>
                          {(job as unknown as { _fromCache: boolean })['_fromCache'] ? (
                            <MuiTooltip
                              title={'Preparing your job. This may take a few minutes. Please refresh the page shortly.'}
                              style={{ marginRight: '8px', transform: 'translateY(2px)' }}>
                              <Icon.Clock size={16} color={GlobalStyles.SLATE_200} />
                            </MuiTooltip>
                          ) : (
                            ''
                          )}
                          {name}
                        </Typography>
                        <ActionMenu
                          jobMenuAnchorEl={jobMenuAnchorEl}
                          isJobMenuOpen={isJobMenuOpen}
                          handleClickJobMenu={handleClickJobMenu}
                          eid={eid}
                          handleCloseJobMenu={handleCloseJobMenu}
                          goToRequestById={goToRequestById}
                          sx={{ display: { xxs: 'none' } }}
                        />
                      </Stack>
                      <JobCell eid={eid} sx={{ display: { xxs: 'none', sm: 'flex' } }} />
                    </Stack>
                    <Box width="16px" display={{ xxs: 'none', sm: 'initial' }}>
                      <Icon.Edit2 size={16} visibility={isEditVisible ? 'visible' : 'hidden'} />
                    </Box>
                  </Stack>
                </Link>
              ),
            }}
            right={{
              style: {
                onClick: handleClickJob,
                paddingY: { xxs: 0, sm: '13.5px' },
              },
              component: (
                <Grid container alignItems={'center'} columnSpacing={{ xxs: 0, sm: '6px' }} rowSpacing={{ xxs: '6px', sm: 0 }}>
                  <TypeColumn expanded={!!selectedDeliverableEid}>
                    {!!event_types?.length && (
                      <QuickLook data={{ Event_Types: event_types }}>
                        <Stack direction="row" gap="4px" overflow="hidden" paddingY={{ xxs: '6px', sm: 0 }}>
                          {event_types?.slice(0, 2).map((item) => (
                            <Chip size="small" key={item.eid} label={item.name} style={TYPE_CHIP_STYLES} />
                          ))}
                          {event_types && event_types?.length > 2 && (
                            <Chip size="small" label={`+${event_types.length - 2}`} style={TYPE_CHIP_STYLES} />
                          )}
                        </Stack>
                      </QuickLook>
                    )}
                  </TypeColumn>
                  <Grid {...JOB_ROW_SIZES(!!selectedDeliverableEid).date}>
                    <Stack direction="row" alignItems="center" columnGap="10px">
                      <Stack display={{ xxs: 'flex', sm: 'none' }}>
                        <Icon.Calendar size={12} color={GlobalStyles.SLATE_200} />
                      </Stack>
                      {(() => {
                        let dateTimeValue = start_datetime

                        if (end_datetime && start_datetime !== end_datetime) {
                          dateTimeValue += ` ${selectedDeliverableEid ? '' : '-'} ${end_datetime}`
                        }

                        return (
                          <Tooltip title={dateTimeValue} arrow>
                            <Typography
                              {...OVERFLOW_TEXT}
                              whiteSpace={{ xxs: 'normal', md: selectedDeliverableEid ? 'normal' : 'nowrap' }}
                              fontSize={selectedDeliverableEid ? '12px' : '14px'}
                              variant="subtitle2"
                              className="text-slate-700 !font-normal">
                              {dateTimeValue}
                            </Typography>
                          </Tooltip>
                        )
                      })()}
                    </Stack>
                  </Grid>
                  <Grid {...JOB_ROW_SIZES(!!selectedDeliverableEid).client}>
                    <Stack direction="row" alignItems="center" columnGap="10px">
                      <Stack display={{ xxs: 'flex', sm: 'none' }}>
                        <Icon.Globe size={12} color={GlobalStyles.SLATE_200} />
                      </Stack>
                      <Typography
                        {...OVERFLOW_TEXT}
                        whiteSpace={{ xxs: 'normal', md: selectedDeliverableEid ? 'normal' : 'nowrap' }}
                        // style={{
                        //     display: '-webkit-box',
                        //     // @ts-expect-error
                        //     '-webkit-line-clamp': '2',
                        //     '-webkit-box-orient': 'vertical',
                        // }}
                        variant="subtitle2"
                        className="text-slate-700 !font-normal"
                        fontSize={selectedDeliverableEid ? '12px' : '14px'}>
                        {`${organisation?.name ? `${organisation?.name}` : ''} ${organisation?.name && client ? ' / ' : ''} ${
                          client ?? ''
                        }`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid {...JOB_ROW_SIZES(!!selectedDeliverableEid).talents}>
                    <Stack direction="row" alignItems="center" columnGap="6px" minHeight="30px">
                      {uniqTalents?.length ? (
                        <QuickLook data={{ [t('Talent') ?? 'Talent']: uniqTalents }}>
                          <Box>
                            <AvatarGroup offset={3} extraAvatarProps={{ size: 'xs' }}>
                              {uniqTalents?.map(({ name, profile_image, eid }) => (
                                <Avatar
                                  key={eid}
                                  size="xs"
                                  image={profile_image === '' ? undefined : profile_image}
                                  placeHolder={
                                    <Icon.User size={GlobalStyles.AVATAR_SIZES.XS - 5} color={GlobalStyles.SLATE_200} strokeWidth={1} />
                                  }
                                  style={{
                                    borderWidth: 2,
                                    borderColor: GlobalStyles.SLATE_200,
                                  }}
                                />
                              ))}
                            </AvatarGroup>
                          </Box>
                        </QuickLook>
                      ) : (
                        <></>
                      )}
                      {job.cost_mode === 'PER_JOB' && (
                        <Typography
                          {...OVERFLOW_TEXT}
                          fontSize="12px"
                          color={GlobalStyles.SLATE_700}
                          display={{ xxs: 'none', sm: 'initial' }}>
                          {!!job.job_currency_code && job.job_cost ? formatCurrency(job.job_currency_code, job.job_cost) : ''}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid {...JOB_ROW_SIZES(!!selectedDeliverableEid).progress}>
                    <Stack direction="row" gap="4px" width="100%" alignItems="center">
                      <Loading.Bar
                        accessibilityLabel={`job progress bar for ${name}`}
                        maxProgressCount={numberOfDeliverables}
                        progressCount={numberOfDeliverablesCompleted}
                        barWidth="60%"
                        size="small"
                        type="grey"
                      />
                      <Typography
                        {...OVERFLOW_TEXT}
                        variant="body2"
                        color={GlobalStyles.SLATE_700}
                        fontSize={'12px'}
                        fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM}>
                        {`${numberOfDeliverablesCompleted}/${numberOfDeliverables}`}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              ),
            }}
          />
          {/* <Grid {...JOB_ROW_SIZES(!!selectedDeliverableEid).action}> */}
          {/* </Grid> */}
        </Grid>
        <Stack
          direction="row"
          columnGap="6px"
          sx={{ cursor: 'pointer' }}
          onClick={handleClickJob}
          margin={{ xxs: 'auto 24px 15px 6px', sm: '0 17px 0 6px' }}>
          <Button variant="text" size="small" sx={{ padding: 0, justifyContent: 'center' }} aria-label="toggle job card">
            <Badge text="" style={{ height: '16px', width: '16px', display: 'none' }} />
            {isExpanded ? (
              <Icon.ChevronUp size={16} color={GlobalStyles.PRIMARY_500} />
            ) : (
              <Icon.ChevronDown size={16} color={GlobalStyles.PRIMARY_500} />
            )}
          </Button>
          {/* <ActionMenu
                        aria-label={`more actions for ${name}`}
                        jobMenuAnchorEl={jobMenuAnchorEl}
                        isJobMenuOpen={isJobMenuOpen}
                        handleClickJobMenu={handleClickJobMenu}
                        eid={eid}
                        handleCloseJobMenu={handleCloseJobMenu}
                        goToRequestById={goToRequestById}
                    /> */}
        </Stack>
      </Stack>
      {isExpanded ? (
        isPending ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignSelf: 'center', marginY: '6px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DeliverableList
              deliverables={deliverables || []}
              costMode={job.cost_mode}
              jobEid={eid}
              onClickDeliverable={onClickDeliverable}
              selectedDeliverableEid={selectedDeliverableEid}
              onSuccessCreate={(deliverable) => {
                const hasValidData = deliverable?.deliverable_agreements?.filter((agreement) => !!agreement.jobAgreement?.talent) || []
                queryClient.invalidateQueries({
                  queryKey: ['notification-unread-count'],
                })

                if (deliverable && hasValidData.length > 0) {
                  updateDeliverable(deliverable)
                } else {
                  queryClient.refetchQueries({
                    queryKey: ['deliverables', eid],
                  })
                }
              }}
              talents={talents as unknown as Types.Talent.iTalent[]}
            />
          </>
        )
      ) : (
        <></>
      )}
    </Paper>
  )
}
export default memo(JobCard)
