import { GlobalStyles, Icon } from '@andromeda'
import { Loader, ModalHeader } from '@components'
import {
  Button,
  Divider,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  inputBaseClasses,
} from '@mui/material'
import { Types } from '@orbit/index'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'
import { OVERFLOW_TEXT } from 'src/components/job-card/job-card'
import useJobListQuery from '@utils/query/useJobListQuery'

const JOB_STATUS = ['ALL', 'DRAFT', 'LIVE', 'COMPLETED']

const JobSelectorModal = () => {
  const { closeModal, options } = useContext(ModalContext)
  const { data, pagination, status, queryParams, setQueryParams } = useJobListQuery()
  const props = options?.modalProps?.jobSelector
  const [selected, setSelected] = useState<Array<Types.Job.iJobList | Types.Job.iJob>>(props?.selected || [])

  const _handleClose = () => {
    closeModal(ModalPages.JOB_SELECTOR)
    if (props && props.onSelect) props.onSelect(selected)
  }

  const handleJobSelect = (job: Types.Job.iJobList) => {
    const IS_SELECTED = !!selected.find((item) => item.eid === job.eid)

    if (IS_SELECTED) {
      setSelected(selected.filter((item) => item.eid !== job.eid))
    } else {
      setSelected([...selected, job])
    }
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '450px' }}>
      <ModalHeader modalTitle="Select Job" modalContext={ModalPages.JOB_SELECTOR} />
      <Stack className="dialog-content" rowGap="8px">
        <Stack direction="column" columnGap="12px" rowGap="12px">
          {/* JOB API does not support search yet... */}
          {/* <TextField
            placeholder="Search job..."
            InputProps={{ startAdornment: <Icon.Search size={18} /> }}
            value={queryParams.query || ''}
            sx={{ width: '100%' }}
            onChange={(e) => setQueryParams(prevParams => ({ ...prevParams, query: e.target.value }))}
          /> */}

          <Stack direction="row" columnGap="8px" alignItems="center">
            <Typography variant="h6">Status</Typography>
            <Select
              value={queryParams.statuses || 'ALL'}
              sx={{
                [`.${inputBaseClasses.input}`]: {
                  textTransform: 'capitalize',
                },
                maxWidth: '440px',
              }}
              onChange={(e) => setQueryParams((prevParams) => ({ ...prevParams, statuses: e.target.value }))}>
              {JOB_STATUS.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>

        <Divider light />

        <TableContainer>
          <Table style={{ tableLayout: 'fixed' }}>
            <colgroup>
              <col style={{ width: 'calc(1000% - 88px)' }}></col>
              <col style={{ width: '88px' }}></col>
            </colgroup>

            <TableHead style={{ position: 'sticky', top: 0, backgroundColor: GlobalStyles.MONO_WHITE, zIndex: 2 }}>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">TITLE</Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6">&nbsp;</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {status !== 'pending' ? (
                !!(data && data.length) ? (
                  data.map((job) => {
                    const IS_SELECTED = selected.find((item) => item.eid === job.eid)
                    let dateTimeValue = job.start_datetime

                    if (job.end_datetime && job.start_datetime !== job.end_datetime) {
                      dateTimeValue += ` - ${job.end_datetime}`
                    }

                    return (
                      <TableRow key={job.eid}>
                        <TableCell>
                          <Stack direction="row" alignItems="center" justifyContent="start" columnGap="12px" width="100%">
                            <Icon.Layers size="18px" style={{ marginLeft: '-12px', minWidth: '18px' }} />

                            <Stack direction="column" alignItems="start" rowGap="0" width="100%">
                              <Tooltip title={job.name}>
                                <Typography {...OVERFLOW_TEXT} lineHeight="normal" width="100%">
                                  {job.name}
                                </Typography>
                              </Tooltip>

                              <Tooltip title={job.eid}>
                                <Typography {...OVERFLOW_TEXT} lineHeight="normal" width="100%" color={GlobalStyles.SLATE_600}>
                                  <small>{job.eid}</small>
                                </Typography>
                              </Tooltip>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell>
                          <Button variant={IS_SELECTED ? 'contained' : 'outlined'} onClick={() => handleJobSelect(job)}>
                            {IS_SELECTED ? <Icon.Check size={14} /> : <Icon.Plus size={14} />}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={2} style={{ padding: '12px' }}>
                      No data
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {pagination.total_number_of_pages > 1 && (
        <Stack direction="row" justifyContent="center" padding="12px">
          <Pagination
            count={pagination.total_number_of_pages}
            size="medium"
            page={Number(queryParams.page) || 1}
            shape="rounded"
            onChange={(_, page) => setQueryParams({ ...queryParams, page })}
          />
        </Stack>
      )}

      <Divider light />

      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" onClick={() => closeModal(ModalPages.JOB_SELECTOR)}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!selected.length} onClick={_handleClose}>
          Confirm Selection
          {selected.length > 0 ? ` (${selected.length})` : ''}
        </Button>
      </Stack>
    </Stack>
  )
}

export default JobSelectorModal
