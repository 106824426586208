import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import {
  Autocomplete,
  autocompleteClasses,
  Button,
  outlinedInputClasses,
  Stack,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Textfield } from 'src/components/form/Textfield'
import { getIcon } from './components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Types } from '@orbit/index'
import { useAddTalentSocials, useSyncTalentSocials, useTalentSocialCampaign } from '@utils/query/useTalentSocialCampaign'
import { FormAutoComplete } from '../form/AutoComplete'
import { DevTool } from '@hookform/devtools'
import { useContext } from 'react'
import { isEditableContext } from '../talent/form'
import { trackEvent } from '@utils/tracking/helpers'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useRouter } from 'next/router'
import { useTalentQuery } from '@utils/query/useTalent'

const PLATFORM_OPTIONS = [
  // { value: 'Youtube' },
  { value: 'Instagram' },
  { value: 'Twitter' },
  { value: 'TikTok' },
]

const Label = (props: TypographyProps) => (
  <Typography sx={{ fontSize: '12px', color: GlobalStyles.SLATE_700, lineHeight: '16px', letterSpacing: '-0.12px' }} {...props} />
)

const MinorValue = (props: TypographyProps) => (
  <Typography
    sx={{ fontSize: '12px', fontWeight: 500, color: GlobalStyles.MONO_BLACK, lineHeight: '16px', letterSpacing: '-0.12px' }}
    {...props}
  />
)
type tFormData = { platform: { value: string }; handle: string }

export const SocialCampaign = () => {
  const router = useRouter()

  const { workspace } = useAppSelector(selectWorkspace)

  const { data: details } = useTalentQuery({ eid: router.query.talentId as string })

  const { state: isEditable, setState: handleSetIsEditable } = useContext(isEditableContext)

  const form = useForm<tFormData>({
    defaultValues: {
      platform: { value: '' },
      handle: '',
    },
  })

  const { mutate, isPending: isAddTalentPending } = useAddTalentSocials({
    onSuccess: () => {
      form.reset()
    },
  })
  const { data, isPending } = useTalentSocialCampaign()
  const { mutate: syncTalentSocials, isPending: isSyncTalentSocialsPending } = useSyncTalentSocials()

  const _handleSubmit: SubmitHandler<tFormData> = (formData) => {
    const payload: Types.Talent.iAddTalentSocialPayload = {
      network: formData.platform.value,
      handle: formData.handle,
    }

    trackEvent({
      event: TrackingEventEnums.Socials.ADD_TALENT_SOCIAL_PLATFORM,
      eventProperties: {
        workspace: workspace,
        talent_name: details?.display_name,
        ...payload,
      },
    })

    mutate(payload)
  }

  const fetchAnalytics = () => {
    trackEvent({
      event: TrackingEventEnums.Socials.FETCH_TALENT_ANALYTICS,
      eventProperties: {
        workspace: workspace,
        talent_name: details?.display_name,
      },
    })

    syncTalentSocials()
  }

  return (
    <FormProvider {...form}>
      <DevTool control={form.control} />
      <form onSubmit={form.handleSubmit(_handleSubmit)}>
        <Stack paddingBottom="24px">
          {!!details?.social_details && (
            <>
              <Stack direction="row" justifyContent={{ xxs: 'center', sm: 'end' }} flex={1} padding="12px">
                <Button
                  variant="outlined"
                  startIcon={<Icon.Repeat color={GlobalStyles.PRIMARY_500} size={16} />}
                  disabled={isAddTalentPending || isPending || isSyncTalentSocialsPending}
                  sx={{ textTransform: 'uppercase', fontSize: '13px', paddingY: '4px', lineHeight: '22px', minHeight: 'unset' }}
                  onClick={fetchAnalytics}>
                  Fetch Analytics
                </Button>
              </Stack>
              {data && !!data.length && (
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    ['>div']: {
                      marginTop: '-1px',
                      marginLeft: '-1px',
                      border: `1px solid ${GlobalStyles.SLATE_100}`,
                      boxSizing: 'border-box',
                    },
                    ['>div:last-child']: {
                      marginRight: '-1px',
                    },
                  }}>
                  {data.map(({ handle, network, eid, ...stats }) => (
                    <Stack key={eid} minWidth="184px" flex={1} alignItems="center" rowGap="8px" padding="24px">
                      {getIcon({ platform: network })}
                      <Typography
                        sx={{
                          color: GlobalStyles.PRIMARY_500,
                          textAlign: 'center',
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '20px' /* 150% */,
                          letterSpacing: '-0.14px',
                        }}>
                        {handle}
                      </Typography>
                      <Typography
                        sx={{
                          color: GlobalStyles.MONO_BLACK,
                          textAlign: 'center',
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '24px' /* 150% */,
                          letterSpacing: '-0.16px',
                        }}>
                        {stats.follower_num ?? 0}
                      </Typography>
                      <Label>Followers</Label>
                      {stats.engagement_rate_30 && (
                        <>
                          <MinorValue>{stats.engagement_rate_30}</MinorValue>
                          <Label>Engagement</Label>
                        </>
                      )}
                      {stats.likes_per_post_30 && (
                        <>
                          <MinorValue>{stats.likes_per_post_30}</MinorValue>
                          <Label>Likes per post</Label>
                        </>
                      )}
                      {stats.comments_per_post_30 && (
                        <>
                          <MinorValue>{stats.comments_per_post_30}</MinorValue>
                          <Label>Comments per post</Label>
                        </>
                      )}
                      {stats.views_per_post_30 && (
                        <>
                          <MinorValue>{stats.views_per_post_30}</MinorValue>
                          <Label>Views per post</Label>
                        </>
                      )}
                    </Stack>
                  ))}
                </Stack>
              )}
            </>
          )}
          <Stack
            direction="row"
            alignItems="center"
            columnGap="30px"
            rowGap="8px"
            padding="8px 24px"
            borderBottom={`1px solid ${GlobalStyles.SLATE_100}`}
            flexWrap="wrap">
            <Stack direction="row" alignItems="center" columnGap={{ xxs: '5px', sm: '30px' }} rowGap="8px" flex={1}>
              <Typography sx={{ minWidth: 'fit-content' }}>Social Platform</Typography>
              <FormAutoComplete
                name="platform"
                size="small"
                disabled={!isEditable}
                textfieldProps={{ placeholder: 'Select Platform' }}
                sx={{
                  minWidth: { sm: '190px' },
                  maxWidth: { xxs: '90%', sm: '190px' },
                  [`&.${autocompleteClasses.root} `]: {
                    [`.${outlinedInputClasses.notchedOutline}`]: {
                      borderWidth: 0,
                    },
                    [`.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
                      borderWidth: 1,
                    },
                    [`.${autocompleteClasses.input}`]: {
                      color: GlobalStyles.PRIMARY_500,
                    },
                  },
                }}
                disableClearable
                options={PLATFORM_OPTIONS}
                getOptionLabel={(option) => option.value}
              />
            </Stack>
            <Stack direction="row" alignItems="center" columnGap={{ xxs: '5px', sm: '30px' }} rowGap="8px" flex={1}>
              <Typography>Handle</Typography>
              <Tooltip text="Add a Social Account" />
              <Textfield
                disabled={!isEditable}
                name="handle"
                sx={{ width: { xxs: '100%', md: '300px' } }}
                registerOptions={{ required: false }}
              />
              <Button
                variant="outlined"
                startIcon={<Icon.Plus />}
                sx={{ minWidth: '80px' }}
                type="submit"
                disabled={isPending || isAddTalentPending || !isEditable}
                onClick={form.handleSubmit(_handleSubmit)}
                aria-label="Add Social Platform">
                Add
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
