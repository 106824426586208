import { GlobalStyles, Icon, StackV2 } from '@andromeda'
import IconButton from '@mui/material/IconButton'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'

export default function SearchInput({
  placeholder = 'Search jobs, deliverables and users within the workspace',
  ...props
}: InputBaseProps) {
  return (
    <StackV2
      direction={'row'}
      flex={1}
      border={`solid 1px ${GlobalStyles.SLATE_100}`}
      borderRadius="6px"
      sx={{
        '&:hover': {
          borderColor: GlobalStyles.SLATE_900,
        },
        '&:focus-within': {
          borderColor: GlobalStyles.PRIMARY_500,
          outline: `1px solid ${GlobalStyles.PRIMARY_500}`,
        },
      }}>
      <IconButton type="button" sx={{ sm: { padding: '10px' } }} aria-label="search">
        <Icon.Search color={GlobalStyles.SLATE_400} />
      </IconButton>
      <InputBase
        style={{ color: GlobalStyles.SLATE_500, flex: 1, fontSize: 14 }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'Search items' }}
        {...props}
      />
    </StackV2>
  )
}
