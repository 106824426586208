import { DropdownOption, GlobalStyles, Icon } from '@andromeda'
import { Autocomplete, TextField, TextFieldProps, AutocompleteProps, createFilterOptions, CircularProgress } from '@mui/material'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, FieldValues, RegisterOptions, useFormContext, useWatch } from 'react-hook-form'

const filter = createFilterOptions()

export type tFormAutocomplete = Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'options'> & {
  name: string
  options: DropdownOption[] | string[] | undefined
  textfieldProps?: TextFieldProps
  hasAdd?: boolean
  rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  fieldValue?: string
  isOptionsLoading?: boolean
  optionsStyle?: React.CSSProperties
  onScrollToBottom?: () => void
  onChangeCB?: () => void
}
export const FormAutoComplete = ({
  name,
  options = [],
  textfieldProps,
  multiple = false,
  hasAdd = false,
  isOptionsLoading = false,
  fieldValue,
  rules,
  isOptionEqualToValue,
  limitTags = 1,
  onScrollToBottom,
  onChangeCB,
  optionsStyle = {},
  ...props
}: tFormAutocomplete) => {
  const { control, formState } = useFormContext()

  const [open, setOpen] = useState(false)
  const optionsObserver = useRef<IntersectionObserver | null>(null)

  const lastOptionElementRef = useCallback(
    (node: HTMLLIElement | null) => {
      if (isOptionsLoading) return
      if (optionsObserver.current) optionsObserver.current.disconnect()
      optionsObserver.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting) {
          onScrollToBottom && onScrollToBottom()
        }
      })
      if (node) optionsObserver.current.observe(node)
    },
    [isOptionsLoading, onScrollToBottom]
  )

  const popupIcon = useMemo(() => {
    if (isOptionsLoading) {
      return <CircularProgress color="inherit" size={16} />
    }

    return open ? (
      <Icon.ChevronUp size={12} color={GlobalStyles.SLATE_500} />
    ) : (
      <Icon.ChevronDown size={12} color={GlobalStyles.SLATE_500} />
    )
  }, [isOptionsLoading, open])

  const error = Object.keys(formState.errors).length
    ? name.split('.').length === 1
      ? formState.errors[name]
      : // @ts-expect-error
        name.split('.').reduce((acc, curr) => {
          return acc?.[curr]
        }, formState.errors)
    : undefined

  const getValue = (value: Record<string, any> | string) => {
    if (value && typeof value === 'object' && fieldValue) {
      return value[fieldValue]
    }

    return value
  }

  const getOptionLabel = (option: any) => {
    // e.g. value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    if (option.inputValue) {
      return option.inputValue
    }
    if (props.getOptionLabel) return props.getOptionLabel(option)
    return option.value
  }

  const handleOptionEqualToValue = (option: any, value: any) => {
    const CURRENT_VALUE = getValue(value)

    if (!CURRENT_VALUE) {
      return false
    }

    if (isOptionEqualToValue) {
      return isOptionEqualToValue(option, value)
    }

    return getValue(option) === CURRENT_VALUE
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, onChange } }) => (
        <Autocomplete
          {...props}
          value={getValue(value)}
          onChange={(_e, newVal) => {
            if (newVal?.inputValue) {
              const { inputValue, ...val } = newVal
              onChange(getValue(val))
            } else {
              onChange(getValue(newVal))
            }

            if (onChangeCB) {
              onChangeCB()
            }
          }}
          multiple={multiple}
          limitTags={limitTags}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={handleOptionEqualToValue}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textfieldProps}
              inputRef={ref}
              placeholder={value?.length ? undefined : textfieldProps?.placeholder}
              error={!!error}
              // @ts-expect-error
              helperText={error?.message ?? ''}
            />
          )}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            if (params.inputValue !== '' && hasAdd) {
              filtered.push({
                id: params.inputValue,
                eid: params.inputValue,
                name: params.inputValue,
                inputValue: `Add "${params.inputValue}"`,
              })
            }
            return filtered
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          popupIcon={popupIcon}
          renderOption={({ style, ...optionProps }, option) => (
            <li ref={lastOptionElementRef} style={{ ...style, ...optionsStyle }} {...optionProps}>
              {getOptionLabel(option)}
            </li>
          )}
        />
      )}
    />
  )
}
