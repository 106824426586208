import { API, User, Workspaces } from '../types'
import { HttpClient } from './api.service'

export const workspaceService = {
  fetchAllWorkspaces: () => {
    return HttpClient.get<null, [Workspaces.iWorkspace]>({
      endpoint: API.WorkspaceEndpoints.WORKSPACE,
      payload: null,
    })
  },
  fetchCurrentWorkspaceSettings: () => {
    return HttpClient.get<null, Workspaces.iWorkspace>({
      endpoint: API.WorkspaceEndpoints.WORKSPACE_CURRENT_SETTINGS,
      payload: null,
    })
  },
  getWorkspaceSettings: (workspaceSlug?: string) => {
    return HttpClient.get<null, Workspaces.iWorkspaceSettings>({
      endpoint: API.WorkspaceEndpoints.WORKSPACE_SETTINGS,
      payload: null,
      ...(workspaceSlug ? { headers: { ['x-pickstar-workspace']: workspaceSlug } } : {}),
    })
  },
  updateWorkspaceSettings: (queryParams: Partial<Workspaces.iWorkspaceSettings>) => {
    return HttpClient.put<Partial<Workspaces.iWorkspaceSettings>, Workspaces.iWorkspaceSettings>({
      endpoint: API.WorkspaceEndpoints.WORKSPACE_SETTINGS,
      payload: queryParams,
    })
  },
  getUserSettings: () => {
    return HttpClient.get<null, Workspaces.iUserSettings>({
      endpoint: API.WorkspaceEndpoints.CURRENT_USER_SETTINGS,
    })
  },
  updateUserSettings: (payload: Workspaces.iUserSettings) => {
    return HttpClient.put<Workspaces.iUserSettings, Workspaces.iUserSettings>({
      endpoint: API.WorkspaceEndpoints.CURRENT_USER_SETTINGS,
      payload,
    })
  },
  generateUploadUrl: ({ file_name }: { file_name: string }) => {
    return HttpClient.post<null, User.iSignedImageUploadURLResponse>({
      endpoint: `${API.WorkspaceEndpoints.GENERATE_URL}`.replace(':file_name', file_name),
    })
  },
  leaveWorkspace: (workspaceEid: string) => {
    return HttpClient.delete<null, null>({
      endpoint: `${API.WorkspaceEndpoints.LEAVE_WORKSPACE}`.replace(':workspaceEid', workspaceEid),
    })
  },
  removeFromWorkspace: (eid: string, module: string) => {
    return HttpClient.delete<null, null>({
      endpoint: `${
        module === 'users' ? API.UserEndpoints.REMOVE_USER_FROM_WORKSPACE : API.TalentEndpoints.REMOVE_PROFILE_FROM_WORKSPACE
      }`.replace(':eid', eid),
    })
  },
}
