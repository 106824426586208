import React from 'react'

const isDashboardEnabled = Boolean(JSON.parse(process.env.isDashboardEnabled ?? 'false'))
const isJobsFilterEnabled = Boolean(JSON.parse(process.env.isJobsFilterEnabled ?? 'false'))
const isGlobalSearchEnabled = Boolean(JSON.parse(process.env.isGlobalSearchEnabled ?? 'false'))
const isImportUserEnabled = Boolean(JSON.parse(process.env.isImportUserEnabled ?? 'false'))
const isDeliverabeUsersEnabled = Boolean(JSON.parse(process.env.isDeliverabeUsersEnabled ?? 'false'))
const isGoogleMapsApiEnabled = Boolean(JSON.parse(process.env.isGoogleMapsApiEnabled ?? 'false'))
const isTalentCustomFieldsEnabled = Boolean(JSON.parse(process.env.isTalentCustomFieldsEnabled ?? 'false'))
const isJobCustomFieldsEnabled = Boolean(JSON.parse(process.env.isJobCustomFieldsEnabled ?? 'false'))
const isDeliverableCustomFieldsEnabled = Boolean(JSON.parse(process.env.isDeliverableCustomFieldsEnabled ?? 'false'))
const isSocialCampaignsEnabled = Boolean(JSON.parse(process.env.isSocialCampaignsEnabled ?? 'false'))
const isDeleteAccountEnabled = Boolean(JSON.parse(process.env.isDeleteAccountEnabled ?? 'false'))
const isNotificationsExtraSettingsEnabled = Boolean(JSON.parse(process.env.isNotificationsExtraSettingsEnabled ?? 'false'))
const isReportingPageEnabled = Boolean(JSON.parse(process.env.isReportingPageEnabled ?? 'false'))

export const FeatureFlagsContext = React.createContext<{
  features: {
    isDashboardEnabled: boolean
    isJobsFilterEnabled: boolean
    isGlobalSearchEnabled: boolean
    isImportUserEnabled: boolean
    isDeliverabeUsersEnabled: boolean
    isGoogleMapsApiEnabled: boolean
    isTalentCustomFieldsEnabled: boolean
    isJobCustomFieldsEnabled: boolean
    isDeliverableCustomFieldsEnabled: boolean
    isSocialCampaignsEnabled: boolean
    isDeleteAccountEnabled: boolean
    isNotificationsExtraSettingsEnabled: boolean
    isReportingPageEnabled: boolean
  }
}>({
  features: {
    isDashboardEnabled,
    isJobsFilterEnabled,
    isGlobalSearchEnabled,
    isImportUserEnabled,
    isDeliverabeUsersEnabled,
    isGoogleMapsApiEnabled,
    isTalentCustomFieldsEnabled,
    isJobCustomFieldsEnabled,
    isDeliverableCustomFieldsEnabled,
    isSocialCampaignsEnabled,
    isDeleteAccountEnabled,
    isNotificationsExtraSettingsEnabled,
    isReportingPageEnabled,
  },
})

export const FeatureFlagsContextProvider = ({ children }: { children: JSX.Element }) => {
  const [features, setFeatures] = React.useState({
    isDashboardEnabled,
    isJobsFilterEnabled,
    isGlobalSearchEnabled,
    isImportUserEnabled,
    isDeliverabeUsersEnabled,
    isGoogleMapsApiEnabled,
    isTalentCustomFieldsEnabled,
    isJobCustomFieldsEnabled,
    isDeliverableCustomFieldsEnabled,
    isSocialCampaignsEnabled,
    isDeleteAccountEnabled,
    isNotificationsExtraSettingsEnabled,
    isReportingPageEnabled,
  })

  // React.useEffect(() => {
  //     console.log("process.env.isDashboardEnabled: ", process.env.isDashboardEnabled)
  //     const featureList = {
  //         isDashboardEnabled: Boolean(JSON.parse(process.env.isDashboardEnabled ?? 'false')),
  //     }
  //     setFeatures(featureList)
  // }, [process.env])

  return <FeatureFlagsContext.Provider value={{ features }}>{children}</FeatureFlagsContext.Provider>
}
