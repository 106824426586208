export const ERROR_MESSAGES = {
  LOGIN: {
    SIGN_IN_WITH_IDP: 'Use Sign in with Identity Provider below instead',
    INVITE:
      'The invite email does not match the provided email address. Use the invited email or ask for a new Invite from your workspace to the correct email address',
  },
  REGISTER: {
    CREATE: 'Use Sign in with Identity Provider instead',
  },
}
