import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, Stack, Typography } from '@mui/material'
import { Types } from '@orbit/index'
import { useCreateJobSocialCampaign, useJobSocialCampaign, useSyncJobSocialCampaign } from '@utils/query/useJobSocialCampaign'
import { useContext, useState } from 'react'
import { FormProvider, SubmitHandler, useController, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Textfield } from '../form/Textfield'
import { DevTool } from '@hookform/devtools'
import { FormDateRangePicker } from '../form/DateRangePicker'
import { CommonGrid, Row } from '../job-details/components'
import { trackEvent } from '@utils/tracking/helpers'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { hasUpdatePermissionsContext, isEditingContext } from '../job-details/Details'

const TILES = [
  {
    value: '2M',
    label: 'Impressions',
    key: 'impressions_num',
  },
  {
    value: '1.9M',
    label: 'Reach',
    key: 'reach_num',
  },
  {
    value: '361',
    label: 'Posts & Videos',
    key: 'posts_num',
  },
  {
    value: '36',
    label: 'Creators',
    key: 'influencers_num',
  },
]

const validationSchema = yup.object().shape({
  hashtags: yup
    .string()
    .required('Hashtag is required')
    .test({
      message: 'Must start with #.(e.g. #hashtag)',
      test: (val) => {
        return val.includes('#')
      },
    }),
  requirements: yup
    .object()
    .shape({
      Twitter: yup.boolean(),
      Instagram: yup.boolean(),
      Tiktok: yup.boolean(),
    })
    .test({
      message: 'At least one platform is required',
      test: (val) => {
        return !Object.values(val).every((v) => !v)
      },
    }),
  start_datetime: yup.object().required('Start date is required.'),
  end_datetime: yup.object().required('End date is required.'),
})
export const Campaign = () => {
  const { workspace } = useAppSelector(selectWorkspace)

  const { isEditing, setIsEditing } = useContext(isEditingContext)
  const { isUpdatable } = useContext(hasUpdatePermissionsContext)

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      hashtags: undefined,
      requirements: undefined,
    },
  })
  const { field: startDateField } = useController({ name: 'start_datetime', control: form.control, rules: { required: true } })
  const { field: endDateField } = useController({ name: 'end_datetime', control: form.control, rules: { required: true } })

  const [isCampaignStatsVisible, setIsCampaignStatsVisible] = useState(true)
  const [hashtag, setHashtag] = useState('')

  const createJobCampaignMutation = useCreateJobSocialCampaign()
  const { data } = useJobSocialCampaign()
  const snycJobCampaignMutation = useSyncJobSocialCampaign()

  const onSubmit: SubmitHandler<yup.InferType<typeof validationSchema>> = (data) => {
    const payload: Types.Job.iCreateCampaignPayload = data as unknown as Types.Job.iCreateCampaignPayload

    Object.keys(data.requirements).forEach((key) => {
      const platform = key as keyof typeof data.requirements
      payload.requirements[platform] = payload.requirements[platform] ? 1 : 0
    })

    trackEvent({
      event: TrackingEventEnums.Socials.CREATE_JOB_CAMPAIGN,
      eventProperties: {
        workspace: workspace,
        hashtags: payload.hashtags,
      },
    })

    createJobCampaignMutation.mutate(payload)
  }

  const fetchAnalytics = () => {
    trackEvent({
      event: TrackingEventEnums.Socials.FETCH_JOB_ANALYTICS,
      eventProperties: {
        workspace: workspace,
        hashtags: data?.hashtags,
      },
    })
    snycJobCampaignMutation.mutate()
  }

  return (
    <FormProvider {...form}>
      <DevTool control={form.control} placement="top-left" />
      <Stack>
        <Grid container boxSizing="border-box" rowGap={{ xxs: '10px', sm: 0 }}>
          <CommonGrid
            noBorder
            label="Campaign # Hashtag"
            notEditable
            value={
              <Stack
                marginLeft="6px"
                direction="row"
                alignItems="center"
                flexBasis={{ xxs: '100%', md: '50%' }}
                columnGap="4px"
                onClick={() => (isUpdatable && !data?.hashtags ? setIsEditing(true) : () => {})}
                sx={{
                  cursor: isUpdatable && !data?.hashtags ? (isEditing ? '' : 'pointer') : '',
                }}>
                {data?.hashtags ? (
                  <Typography fontSize="14px" letterSpacing="-0.14px" color={GlobalStyles.SLATE_700}>
                    {data.hashtags}
                  </Typography>
                ) : (
                  <Textfield
                    name="hashtags"
                    onChange={(e) => {
                      setHashtag(e.target.value)
                    }}
                  />
                )}
                {!data && (
                  <Button onClick={form.handleSubmit(onSubmit)} disabled={createJobCampaignMutation.isPending}>
                    Create
                  </Button>
                )}
              </Stack>
            }
          />
          {data ? (
            <Stack direction="row" justifyContent={{ xxs: 'center', sm: 'end' }} flex={1} padding="10px 24px">
              {/* Hidden for now as per requirements */}
              {/* <Button
                variant="text"
                startIcon={<Icon.BarChart color={GlobalStyles.PRIMARY_500} size={20} />}
                onClick={() => setIsCampaignStatsVisible((prev) => !prev)}>
                {`${isCampaignStatsVisible ? 'Hide' : 'View'} Campaign Stats`}
              </Button> */}
              <Button
                variant="outlined"
                startIcon={<Icon.Repeat color={GlobalStyles.PRIMARY_500} size={16} />}
                disabled={snycJobCampaignMutation.isPending}
                sx={{ textTransform: 'uppercase', fontSize: '13px', paddingY: '4px', lineHeight: '22px', minHeight: 'unset' }}
                onClick={fetchAnalytics}>
                Fetch Analytics
              </Button>
            </Stack>
          ) : (
            <></>
          )}
        </Grid>
        {!data && (
          <Row>
            <CommonGrid
              label="Campaign Date Range"
              value={
                <>
                  <Stack marginLeft="6px" maxWidth="300px" minWidth="236px">
                    <FormDateRangePicker
                      startDateName="start_datetime"
                      endDateName="end_datetime"
                      InputProps={{
                        'aria-label': 'Campaign Date Range',
                      }}
                      onChange={([startDate, endDate]) => {
                        startDateField.onChange(startDate)
                        endDateField.onChange(endDate)
                      }}
                    />
                  </Stack>
                  {form.formState.errors.start_datetime?.root && (
                    <FormHelperText error>{form.formState.errors.start_datetime.root.message}</FormHelperText>
                  )}
                  {form.formState.errors.end_datetime?.root && (
                    <FormHelperText error>{form.formState.errors.end_datetime.root.message}</FormHelperText>
                  )}
                </>
              }
            />
            <CommonGrid
              label="Required Platforms"
              value={
                <>
                  <FormGroup sx={{ flexDirection: 'row', marginLeft: '6px' }}>
                    <FormControlLabel control={<Checkbox {...form.register('requirements.Twitter')} />} label="Twitter" />
                    <FormControlLabel control={<Checkbox {...form.register('requirements.Instagram')} />} label="Instagram" />
                    <FormControlLabel control={<Checkbox {...form.register('requirements.Tiktok')} />} label="Tiktok" />
                  </FormGroup>
                  {form.formState.errors.requirements?.root && (
                    <FormHelperText error>{form.formState.errors.requirements.root.message}</FormHelperText>
                  )}
                </>
              }
            />
          </Row>
        )}
        {data && isCampaignStatsVisible && (
          <Stack padding="12px 24px" borderBottom={`1px solid ${GlobalStyles.SLATE_100}`}>
            <Typography fontSize="18px" color={GlobalStyles.SLATE_700} textAlign="center" sx={{ paddingY: '6px' }}>
              Campaign Stats
            </Typography>
            <Stack
              direction="row"
              rowGap="8px"
              flexWrap="wrap"
              justifyContent="center"
              sx={{
                '>div': {
                  borderRight: `1px solid ${GlobalStyles.SLATE_100}`,
                },
                '>div:last-child': {
                  borderRight: 'none',
                },
              }}>
              {TILES.map(({ value, label, key }) => (
                <Stack key={label} minWidth="146px" boxSizing="border-box" alignItems="center" padding="12px 24px">
                  <Typography color={GlobalStyles.MONO_BLACK} fontSize="16px" fontWeight={500} letterSpacing="-0.16px">
                    {key ? data?.[key as keyof Types.Job.iJobSocial] : value}
                  </Typography>
                  <Typography color={GlobalStyles.SLATE_700} fontSize="14px" letterSpacing="-0.14px">
                    {label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  )
}
