import { Orbit } from '@orbit/index'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import useDebounce from '@utils/hooks/useDebounce'

const useJobListQuery = () => {
  const { workspace } = useAppSelector(selectWorkspace)
  const [queryParams, setQueryParams] = useState<Record<string, string | number>>({
    page: 1,
    per_page: 10,
    statuses: 'ALL',
  })
  const debouncedQueryParams = useDebounce(queryParams, 500)
  const { data, ...options } = useQuery({
    queryKey: ['selector-job.list', workspace, debouncedQueryParams],
    queryFn: () => {
      const listParams = { ...debouncedQueryParams }

      if (listParams.statuses === 'ALL') {
        delete listParams.statuses
      }

      return Orbit.Services.jobService.fetchAllJobs(listParams)
    },
    enabled: !!workspace,
  })

  return {
    ...options,
    data: data?.data || [],
    pagination: data?.meta?.pagination || { total_number_of_pages: 1, current_page: 1 },
    queryParams,
    setQueryParams,
  }
}

export default useJobListQuery
