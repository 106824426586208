import { Stack, Typography, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { iCustomFieldProps } from '../custom-fields.types'
import { Textfield } from 'src/components/form/Textfield'
import HoverEditIcon from '../hover-edit-icon'
import { GlobalStyles } from '@andromeda'
import { encodeQoutes } from '@utils/functions/helperFunctions'

const CustomFieldTextfield = ({ name, field, isEdit, onClick }: iCustomFieldProps) => {
  return (
    <Stack direction="row" padding="12px 24px" columnGap="8px" alignItems="center" onClick={() => onClick?.(field)}>
      <Typography>{field.display_name}</Typography>

      <HoverEditIcon isEdit={isEdit}>
        <Textfield
          placeholder={`Enter ${field.display_name}`}
          disabled={!isEdit}
          name={encodeQoutes(name)}
          className="flex-auto"
          defaultValue={field.value || field.default_value}
          registerOptions={{ required: field.required ? `${field.display_name} is required.` : false }}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            [`.${inputBaseClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: GlobalStyles.SLATE_100,
            },
          }}
          style={{ pointerEvents: !isEdit ? 'none' : 'all' }}
        />
      </HoverEditIcon>
    </Stack>
  )
}

export default CustomFieldTextfield
