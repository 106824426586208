import { Orbit, Types } from '@orbit/index'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const useTalentQuery = ({ eid }: { eid: string }) => {
  return useQuery({
    queryKey: ['talent', eid],
    queryFn: async () => {
      const response = await Orbit.Services.talentService.fetchTalentById(eid)
      return response.data
    },
    enabled: !!eid,
  })
}

const QUERY_KEY = 'updateTalent'

export const useTalentMutation = ({ eid, onSuccess }: { eid: string; onSuccess: () => void }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['updateTalent', eid],
    mutationFn: async (payload: Types.Talent.iTalentCreatePayload) => {
      const response = await Orbit.Services.talentService.update({ eid, payload })
      return response.data
    },
    onMutate() {
      {
        pending: 'Saving changes...'
      }
      toast.loading('Saving changes...', { toastId: QUERY_KEY })
    },
    onSuccess(data) {
      toast.update(QUERY_KEY, {
        render: 'Successfully saved changes.',
        type: 'success',
        autoClose: 5000,
        isLoading: false,
      })
      onSuccess()
      queryClient.setQueryData(['talent', eid], data)
    },
    onError(e: { response: { data: { message: string } }; message: string }, _variables) {
      toast.update(QUERY_KEY, {
        render: e?.response?.data?.message || 'Changes were not saved. Please try again.',
        type: 'error',
        autoClose: 5000,
        isLoading: false,
      })
    },
  })
}
