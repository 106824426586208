import { GlobalStyles, Icon } from '@andromeda'
import { BannerSettings, Container, Main, TabsV2, iTab } from '@components'
import { useAppSelector } from '@redux/hooks'
import { selectAuth } from '@redux/reducers/authReducer'
import { DirectoryPermissions } from '@utils/enum/permissions'
import { useTranslation } from '@utils/hooks/useTranslation'
import { useRouter } from 'next/router'
import { ReactElement, useEffect, useMemo } from 'react'

export default function DirectoryLayout<NextPage>({ children }: { children: ReactElement }) {
  const { t } = useTranslation('talent')

  const TABS: Array<iTab> = [
    {
      id: 0,
      title: `Users`,
      slug: 'users',
    },
    {
      id: 1,
      title: t('Talent Profiles') ?? `Talent Profiles`,
      slug: 'talent-profiles',
    },
    {
      id: 2,
      title: `Organisations`,
      slug: 'organisations',
    },
    {
      id: 3,
      title: `Departments`,
      slug: 'departments',
    },
  ]

  const router = useRouter()
  const { workspace } = router.query
  const [_, section] = router.pathname.split('directory/')

  const { permissions } = useAppSelector(selectAuth)

  const permissionFilteredTabs = useMemo(() => {
    let tabs = TABS
    if (!permissions.includes(DirectoryPermissions.USERS)) {
      tabs = tabs.filter((tab) => tab.title !== 'Users')
    }
    if (!permissions.includes(DirectoryPermissions.TALENTS)) {
      tabs = tabs.filter((tab) => tab.title !== 'Talent Profiles')
    }
    if (!permissions.includes(DirectoryPermissions.ORGANISATIONS)) {
      tabs = tabs.filter((tab) => tab.title !== 'Organisations')
    }
    if (!permissions.includes(DirectoryPermissions.DEPARTMENTS)) {
      tabs = tabs.filter((tab) => tab.title !== 'Departments')
    }
    return tabs
  }, [permissions])

  useEffect(() => {
    if (
      router.pathname.includes('directory') &&
      !permissions.some((permission) => Object.values(DirectoryPermissions).some((dirPerm) => dirPerm === permission))
    ) {
      router.push({
        pathname: '/[workspace]',
        query: {
          workspace,
        },
      })
    }
  }, [permissions, router, workspace])

  const _onSelectTab = (tab: iTab) => {
    router.push(`/${workspace}/directory/${tab.slug}`)
  }

  return (
    <Container banner={<BannerSettings IconComponent={(props) => <Icon.Users {...props} />} title={'Directory'} showLogo />}>
      <Main padding={{ xxs: '0', sm: '0 32px 32px', lg: '0 64px 64px' }}>
        <div className="w-full justify-around">
          <TabsV2
            pathname={router.pathname}
            onSelectTab={_onSelectTab}
            initialIndex={permissionFilteredTabs.findIndex((tab) => tab.slug === section)}
            tabs={permissionFilteredTabs}
            paddingTop={0}
          />
          {children}
        </div>
      </Main>
    </Container>
  )
}
