import { DropdownOption } from '@andromeda'
import { Types } from '@orbit'
import { useEventTypesQuery } from '@utils/query/useEventTypes'
import { useEffect, useState } from 'react'

export const useEventTypes = ({ selectedEvents }: { selectedEvents?: Types.Events.iEventTypes[] }) => {
  const { data: events, isPending } = useEventTypesQuery()

  const [eventTypeOptions, setEventTypeOptions] = useState<DropdownOption[]>()

  useEffect(() => {
    if (events && events.length > 0) {
      setEventTypeOptions(
        events
          .map((type: Types.Events.iEventTypes) => {
            return {
              eid: type.eid,
              value: type.name,
              name: type.name,
              subText: type.description,
            }
          })
          .filter((item) => !selectedEvents?.find((selected) => selected.eid === item.eid))
      )
    }
  }, [events, selectedEvents])

  return {
    eventTypeOptions,
    isPending,
  }
}
