import { GlobalStyles } from '@andromeda'
import { Stack, Typography } from '@mui/material'
import Image from 'next/image'
import LogoPlaceholder from 'public/pickstar-logo.png'
import { OVERFLOW_TEXT } from '../job-card/job-card'
import { useRouter } from 'next/router'
import { PathEnums } from '@utils/context/Navigation.context'
import { useWorkspace } from '@utils/query/useWorkspace'
import useBannerImage from './useBannerImage'
import { useContext } from 'react'
import { WorkspaceTheme, WorkspaceThemeContext } from '@utils/context/Theme.context'
import { useTranslation } from '@utils/hooks/useTranslation'

const BannerSettings = ({
  title,
  description,
  showLogo = true,
  useWorkspaceAsTitle,
  heightOverride,
  mini = false,
  IconComponent,
  logo,
  workspaceTheme,
}: {
  title?: string | JSX.Element
  description?: string
  showLogo?: boolean
  IconComponent?: (props: any) => JSX.Element
  useWorkspaceAsTitle?: boolean
  heightOverride?: string
  mini?: boolean
  logo?: string
  workspaceTheme?: WorkspaceTheme
}) => {
  const router = useRouter()

  const { secondaryColor, defaults } = useContext(WorkspaceThemeContext)

  const bannerImage = useBannerImage({ workspaceTheme })
  const { activeWorkspace } = useWorkspace()
  const { t } = useTranslation('talent')

  const bannerLogo = logo || (activeWorkspace?.logo_image?.full_url ?? LogoPlaceholder.src)

  const getTranslatedTitle = () => {
    if ('Talent Profile' === title) {
      return t('Talent Profile') ?? 'Talent Profile'
    }
    return title
  }

  return (
    <Stack
      direction={'row'}
      className="page-banner"
      height={{ xxs: heightOverride ?? '50px', sm: heightOverride ?? '168px' }}
      width="100%"
      sx={{
        backgroundSize: 'cover',
        backgroundImage: {
          xxs: `url(${bannerImage.mobile})`,
          sm: router.pathname === PathEnums.REQUEST_BY_ID ? `url(${bannerImage.jobDesktop})` : `url(${bannerImage.desktop})`,
        },
      }}>
      <Stack
        direction="row"
        boxSizing="border-box"
        padding={{ xxs: '4px 20px', sm: '0 24px', lg: '0 64px' }}
        sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack
          direction="column"
          justifyContent={{ xxs: 'start', sm: 'center' }}
          alignItems={{ sm: 'flex-start' }}
          width={{ xxs: '100%', sm: 'auto' }}>
          <Stack direction={'row'} alignItems={'center'} spacing={2} maxWidth={'100%'}>
            {IconComponent && (
              <IconComponent size={30} color={workspaceTheme?.secondaryColor || secondaryColor || defaults.secondaryColor} />
            )}
            <Typography
              sx={{
                color: GlobalStyles.MONO_WHITE,
                fontSize: { xxs: '24px', md: '40px' },
                ...OVERFLOW_TEXT.sx,
              }}>
              {useWorkspaceAsTitle ? activeWorkspace?.name : getTranslatedTitle()}
            </Typography>
          </Stack>
          <Typography
            sx={{
              display: { xxs: 'none', sm: 'initial' },
              fontSize: '12px',
              fontWeight: '400',
              color: GlobalStyles.MONO_WHITE,
            }}>
            {description}
          </Typography>
        </Stack>
        {!mini && showLogo && bannerLogo && (
          <div className="invisible md:visible" style={{ backgroundColor: 'white', width: 120, height: 120, borderRadius: 60 }}>
            <Image alt="WorkspaceLogo" src={bannerLogo} width={120} height={120} />
          </div>
        )}
      </Stack>
    </Stack>
  )
}

export { BannerSettings }
