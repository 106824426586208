import { API, Invite } from '../types'
import { HttpClient } from './api.service'

export const inviteService = {
  create: (payload: Invite.iInviteCreatePayload) => {
    return HttpClient.post<Invite.iInviteCreatePayload, Invite.iCreateInviteResponse>({
      endpoint: API.InviteEndpoints.CREATE_INVITE,
      payload: payload,
    })
  },
  get: ({ token, eid }: { token: string; eid: string }) => {
    return HttpClient.get<null, Invite.iInvite>({
      endpoint: `${API.InviteEndpoints.VIEW_INVITE.replace(':invite', eid).replace(':token', token)}`,
      payload: null,
    })
  },
  list: () => {
    return HttpClient.get<null, Invite.iInviteListResponse[]>({
      endpoint: API.InviteEndpoints.INVITES,
      payload: null,
    })
  },
  reject: ({ eid }: { eid: string }) => {
    return HttpClient.put<null, Invite.iInvite>({
      endpoint: `${API.InviteEndpoints.REJECT_INVITE.replace(':invite', eid)}`,
      payload: null,
    })
  },
  acceptAsNewUser: ({ token, eid, payload }: { token: string; eid: string; payload: Invite.iInviteAcceptPayload }) => {
    return HttpClient.put<Invite.iInviteAcceptPayload, Invite.iInvite>({
      endpoint: `${API.InviteEndpoints.ACCEPT_NEW_USER.replace(':invite', eid).replace(':token', token)}`,
      payload: payload,
    })
  },
  acceptAsExistingUser: ({ eid }: { eid: string }) => {
    return HttpClient.put<Invite.iInviteAcceptPayload, Invite.iInvite>({
      endpoint: `${API.InviteEndpoints.ACCEPT_EXISTING_USER.replace(':invite', eid)}`,
    })
  },
  resendInvite: ({ eid }: { eid: string }) => {
    return HttpClient.post<null, Invite.iInvite>({
      endpoint: API.InviteEndpoints.RESEND_INVITE.replace(':invite', eid),
    })
  },
}
