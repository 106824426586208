import { Orbit, Types } from '@orbit'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

export const useTalentSocialCampaign = () => {
  const router = useRouter()

  const talentEid = useMemo(() => router.query.talentId || router.query.jobEid, [router.query])

  return useQuery<Array<Types.Talent.iTalentSocial>>({
    queryKey: ['talent-social-campaign', talentEid],
    queryFn: async () => {
      const res = await Orbit.Services.talentService.getSocials(talentEid as string)
      return res.data
    },
    enabled: !!talentEid,
  })
}
export const useAddTalentSocials = ({ onSuccess }: { onSuccess: () => void }) => {
  const router = useRouter()

  const talentEid = useMemo(() => router.query.talentId || router.query.jobEid, [router.query])
  const queryClient = useQueryClient()
  const talentSocials = queryClient.getQueryData<Array<Types.Talent.iTalentSocial>>(['talent-social-campaign', talentEid as string])

  return useMutation({
    mutationKey: ['add-talent-social-campaign', talentEid],
    mutationFn: async (payload: Types.Talent.iAddTalentSocialPayload) => {
      const res = await Orbit.Services.talentService.addSocials({
        eid: talentEid as string,
        payload,
      })
      return res.data
    },
    onMutate() {
      toast.loading('Adding Social Platform...', { toastId: 'talentSocial' })
    },
    onSuccess(data) {
      toast.update('talentSocial', {
        render: 'Successfully added Social Platform.',
        type: 'success',
        autoClose: 5000,
        isLoading: false,
      })
      queryClient.setQueryData(['talent-social-campaign', talentEid as string], data)
      queryClient.invalidateQueries({ queryKey: ['talent', talentEid as string] })
      onSuccess()
    },
    onError(e) {
      toast.update('talentSocial', {
        // @ts-expect-error
        render: e?.response?.data?.error?.message || 'Error Adding Social Platform.',
        type: 'error',
        autoClose: 5000,
        isLoading: false,
      })
    },
  })
}
export const useSyncTalentSocials = () => {
  const router = useRouter()

  const talentEid = useMemo(() => router.query.talentId || router.query.jobEid, [router.query])
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['sync-talent-social-campaign', talentEid],
    mutationFn: async () => {
      const payload = queryClient.getQueryData<Types.Talent.iTalentSocial[]>(['talent-social-campaign', talentEid as string])
      const res = await Orbit.Services.talentService.syncSocials({
        eid: talentEid as string,
      })
      return res.data
    },
    onSuccess(data) {
      queryClient.setQueryData(['talent-social-campaign', talentEid as string], data)
    },
    onError(e) {
      toast.update('talentSocial', {
        // @ts-expect-error
        render: e?.response?.data?.error?.message || 'Error Fetching Social Platforms.',
        type: 'error',
        autoClose: 5000,
        isLoading: false,
      })
    },
  })
}
