import { Card, GlobalStyles, Icon } from '@andromeda'
import { Button, Stack } from '@mui/material'
import { useRouter } from 'next/router'
import { Main } from '../layout'

type NotFoundProps = {
  title: string
  description: string
  backButtonLabel: string
  onBack: () => void
  inline: boolean
}

const DEFAULTS = {
  title: 'Oops! Data Not Found',
  description:
    "It looks like the data you're looking for doesn't exist anymore. It might have been deleted, removed, or never existed in the first place.",
  backButtonLabel: 'Go Back',
}

function NotFoundContent({ title, description, backButtonLabel, onBack }: Partial<NotFoundProps>) {
  const router = useRouter()

  const handleBack = () => {
    if (!onBack) {
      router.back()
    } else {
      onBack()
    }
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" padding="1rem 0" height="100%" width="100%" minHeight="50vh">
      <Card.Error
        style={{ textAlign: 'center', border: 'none', padding: '4rem 2rem' }}
        errorIcon={<Icon.AlertCircle size={140} strokeWidth={1} color={GlobalStyles.SLATE_300} />}
        heading={title || DEFAULTS.title}
        subHeading={description || DEFAULTS.description}
        subHeadingStyle={{ textAlign: 'center' }}
        actionButtons={
          <Button type="button" variant="contained" style={{ width: '100px', maxWidth: '100%', marginTop: '24px' }} onClick={handleBack}>
            {backButtonLabel || DEFAULTS.backButtonLabel}
          </Button>
        }
      />
    </Stack>
  )
}

function NotFound({ inline = false, ...props }: Partial<NotFoundProps>) {
  if (inline) {
    return <NotFoundContent {...props} />
  }

  return (
    <Main padding={{ xxs: '12px', sm: '10px 24px 24px' }}>
      <NotFoundContent {...props} />
    </Main>
  )
}

export default NotFound
