import { ModalHeader } from '@components'
import { Button, Stack, Typography } from '@mui/material'
import { Orbit } from '@orbit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'
import { toast } from 'react-toastify'

export const RemoveFromWorkspace = () => {
  const queryClient = useQueryClient()

  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.removeFromWorkspace
  const page = props?.page

  const { mutate, isPending } = useMutation({
    mutationKey: ['removeFromWorkspace'],
    mutationFn: async () => {
      if (props?.userEid && page) {
        const response = await Orbit.Services.workspaceService.removeFromWorkspace(props.userEid, page)

        return response.data
      }
    },
    onMutate() {
      toast.loading('Removing from workspace...', { toastId: 'removeFromWorkspace' })
    },
    async onSuccess() {
      const queryKey = page === 'users' ? 'listUser' : 'listTalent'
      await queryClient.invalidateQueries({ queryKey: [queryKey], type: 'active' })

      toast.update('removeFromWorkspace', {
        render: 'Successfully removed from workspace.',
        type: 'success',
        autoClose: 5000,
        isLoading: false,
      })

      props?.onSuccess && props?.onSuccess(props?.userEid)

      closeModal(ModalPages.REMOVE_FROM_WORKSPACE)
    },
    onError(e) {
      toast.update('removeFromWorkspace', {
        // @ts-expect-error
        render: e?.response?.data?.message || 'Error removing from workspace.',
        type: 'error',
        autoClose: 5000,
        isLoading: false,
      })
    },
  })

  return (
    <Stack minWidth={{ sm: '500px' }} maxWidth="500px">
      <ModalHeader
        modalContext={ModalPages.REMOVE_FROM_WORKSPACE}
        modalTitle="Remove from Workspace"
        onCancel={() => closeModal(ModalPages.REMOVE_FROM_WORKSPACE)}
      />
      <Stack className="dialog-content" direction="row" flexWrap="wrap">
        <Typography>
          Are you sure you wish to remove <strong>{props?.user}</strong> from <strong>{props?.workspace}</strong>?
        </Typography>
      </Stack>
      <Stack className="dialog-footer" justifyContent="end" direction="row" columnGap="8px">
        <Button variant="outlined" disabled={isPending} onClick={() => closeModal(ModalPages.REMOVE_FROM_WORKSPACE)}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isPending} onClick={() => mutate()}>
          Yes
        </Button>
      </Stack>
    </Stack>
  )
}
