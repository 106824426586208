import { API } from '@orbit/types'
import { HttpClient } from './api.service'
import { Reporting } from '../types/reporting'

export const reportingService = {
  getReportTypes: (params?: { [key: string]: any }) => {
    return HttpClient.get<null, Reporting.iReportDataTypes>({
      endpoint: API.ReportingEndpoints.REPORT_TYPES,
      params: { per_page: params?.per_page || 999 },
    })
  },
  getReports: (params?: { [key: string]: any }) => {
    return HttpClient.get<null, Reporting.iReport[]>({
      endpoint: API.ReportingEndpoints.REPORT_LIST,
      params,
    })
  },
  getReportByEid: (eid: string) => {
    return HttpClient.get<null, Reporting.iReport>({
      endpoint: API.ReportingEndpoints.REPORT_BY_EID.replace(':eid', eid),
    })
  },
  generateReport: (type: string, payload: Reporting.iReportOptions) => {
    return HttpClient.put<Reporting.iReportOptions, Reporting.iReport>({
      endpoint: API.ReportingEndpoints.REPORT_BY_TYPE.replace(':type', type),
      payload,
    })
  },
  removeReport: (eid: string) => {
    return HttpClient.delete<null, null>({
      endpoint: API.ReportingEndpoints.REPORT_BY_EID.replace(':eid', eid),
    })
  },
}
