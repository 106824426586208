import { useController, useFormContext, useWatch } from 'react-hook-form'
import { Textfield } from '../form/Textfield'
import { Box, Button, FormControl, FormLabel, Stack, Switch, stackClasses } from '@mui/material'
import { Avatar, AvatarGroup, DropdownOption, GlobalStyles, Icon, Tooltip } from '@andromeda'
import { useContext, useEffect, useMemo } from 'react'
import { useCurrencyCode } from '@utils/hooks/useCurrencyCode'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { Types } from '@orbit'
import { FormDateRangePicker } from '../form/DateRangePicker'
import { useTalents } from '@utils/hooks/useTalents'
import { getTypes, selectDeliverable } from '@redux/reducers/deliverableReducer'
import { Moment } from 'moment'
import { CurrencyTextfield } from '../form/CurrencyTextfield'
import { FormAutoComplete } from '../form/AutoComplete'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { QuickLook } from '../QuickLook'
import { useTranslation } from '@utils/hooks/useTranslation'

export type tDeliverableForm = {
  deliverable: Types.Deliverable.iDeliverablePayload & {
    deliverable_type_eid: DropdownOption
    start_datetime?: Moment
    end_datetime?: Moment
    deliverable_currency_code: DropdownOption
  }
  cost_mode: DropdownOption
  talents?: Partial<Types.Talent.iTalent>[]
}

export const InlineDeliverableCreate = ({
  onCancelAddDeliverable,
  isLoading,
  hasTalent = false,
}: {
  onCancelAddDeliverable: () => void
  isLoading: boolean
  hasTalent?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { types, requestStatus } = useAppSelector(selectDeliverable)

  const { toggleModal, closeModal } = useContext(ModalContext)

  const { t } = useTranslation('talent')
  const currenyCodeOptions = useCurrencyCode()
  const { talentOptions } = useTalents()
  const deliverableTypeOptions = useMemo<DropdownOption[]>(
    () =>
      types?.length
        ? types?.reduce<DropdownOption[]>((acc, curr) => {
            return [
              ...acc,
              //   @ts-expect-error
              ...curr.children?.map(({ eid, name, category }) => ({ eid, value: name, category } as DropdownOption)),
            ]
          }, [])
        : [],
    [types]
  )

  const form = useFormContext<tDeliverableForm>()
  const { field: startDateField } = useController({ name: 'deliverable.start_datetime', control: form.control })
  const { field: endDateField } = useController({ name: 'deliverable.end_datetime', control: form.control })
  const { field: talentField } = useController({ name: 'talents', control: form.control })
  const costMode = useWatch({ name: 'cost_mode', control: form.control })

  useEffect(() => {
    if (!types) dispatch(getTypes())
  }, [types, dispatch])

  // Reset talents when cost mode changes
  useEffect(() => {
    form.setValue('talents', [])
  }, [costMode])

  const handleAddTalent = () => {
    toggleModal(ModalPages.ASSIGN_TALENT, {
      modalProps: {
        [ModalPages.ASSIGN_TALENT]: {
          context: 'inline-editor',
          assignedTalents: talentField.value,
          onSuccess: () => {
            closeModal(ModalPages.ASSIGN_TALENT)
          },
          onChange: (talents) => talentField.onChange(talents),
          costMode: costMode.id as Types.Job.iJobPayload['cost_mode'],
        },
      },
    })
  }

  return (
    <Stack width="100%" alignItems="center" columnGap="22px" flexDirection={'column'} rowGap="10px">
      <Stack direction="row" width="100%">
        <Stack width="40px" marginTop="12px" alignItems="center">
          <Icon.Target size={12} color={GlobalStyles.SLATE_400} />
        </Stack>

        <Stack
          flex={1}
          rowGap="10px"
          sx={{
            [`>.${stackClasses.root}`]: {
              flexWrap: 'wrap',
            },
          }}>
          <Stack direction="row" columnGap="6px" rowGap="10px" sx={{ [`>.${stackClasses.root}`]: { flex: 1 } }}>
            <Stack minWidth="200px">
              <Textfield name="deliverable.name" placeholder="Title" />
            </Stack>
            <Stack minWidth="200px">
              <FormAutoComplete
                name="deliverable.deliverable_type_eid"
                options={deliverableTypeOptions}
                textfieldProps={{ placeholder: 'Type' }}
                getOptionLabel={(option) => option.value}
                groupBy={(option) => option.category}
                isOptionsLoading={requestStatus.types === 'pending'}
              />
            </Stack>
            <Stack minWidth="236px" maxWidth="300px">
              <FormDateRangePicker
                startDateName="deliverable.start_datetime"
                endDateName="deliverable.end_datetime"
                InputProps={{
                  'aria-label': 'Deliverable Date Range',
                }}
                value={[startDateField.value ? startDateField.value : null, endDateField.value ? endDateField.value : null]}
                onChange={([startDate, endDate]) => {
                  startDateField.onChange(startDate)
                  endDateField.onChange(endDate)
                }}
              />
            </Stack>
            {costMode.id === 'PER_DELIVERABLE' ? (
              <>
                <Stack minWidth="150px" maxWidth="200px">
                  <CurrencyTextfield
                    currency={{
                      type: 'number',
                      name: 'deliverable.deliverable_cost_per_talent',
                      registerOptions: { required: false },
                    }}
                    currencyCode={{ name: 'deliverable.deliverable_currency_code', options: currenyCodeOptions }}
                  />
                </Stack>
                <FormControl sx={{ flexDirection: 'row', alignItems: 'center', minWidth: '80px', maxWidth: '80px', columnGap: '6px' }}>
                  <Switch />
                  <FormLabel sx={{ fontSize: '14px', color: GlobalStyles.SLATE_500 }}>Tax</FormLabel>
                </FormControl>
              </>
            ) : (
              <></>
            )}
            {!hasTalent || costMode.id === 'PER_DELIVERABLE' ? (
              <>
                <Stack maxWidth="42px" alignItems="center" justifyContent="center" flex="auto" display={{ xxs: 'none', sm: 'flex' }}>
                  <Stack sx={{ borderLeft: `1px solid ${GlobalStyles.SLATE_100}`, minHeight: '22px' }} />
                </Stack>
                <Stack minWidth="180px" maxWidth="280px" direction="row" alignItems="center">
                  {talentField.value?.length ? (
                    <QuickLook
                      data={{
                        [t('Talent') ?? 'Talent']: talentField.value.map(({ display_name, ...val }) => ({ ...val, name: display_name })),
                      }}>
                      <Box>
                        <AvatarGroup offset={3} extraAvatarProps={{ size: 'xs' }}>
                          {talentField.value?.map(({ profile_image, eid }) => (
                            <Avatar
                              key={eid}
                              size="xs"
                              image={profile_image?.full_url}
                              placeHolder={
                                <Icon.User size={GlobalStyles.AVATAR_SIZES.XS - 5} color={GlobalStyles.SLATE_200} strokeWidth={1} />
                              }
                              style={{
                                borderWidth: 2,
                                borderColor: GlobalStyles.SLATE_200,
                              }}
                            />
                          ))}
                        </AvatarGroup>
                      </Box>
                    </QuickLook>
                  ) : (
                    <></>
                  )}
                  <Button variant="text" onClick={handleAddTalent} startIcon={<Icon.PlusCircle />}>
                    {`Add ${t('Talent') ?? 'Talent'}`}
                  </Button>
                  {/* <FormTalentAutoComplete
                    name="talents"
                    options={talentOptions}
                    textfieldProps={{ placeholder: 'Talent' }}
                    multiple={costMode.id === 'PER_DELIVERABLE'}
                  /> */}
                </Stack>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" width="100%" alignItems="center" columnGap="22px">
        <Stack width="18px" marginTop="12px" alignItems="center" />
        <Stack
          height="1px"
          flex={1}
          style={{
            backgroundColor: GlobalStyles.SLATE_100,
          }}
        />
        <Stack direction="row" columnGap="6px">
          <Button variant="outlined" onClick={onCancelAddDeliverable} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
