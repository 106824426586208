import { DropDownItemV2, DropdownV2, GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Avatar, Box, Button, Grid, Grid2Props, Pagination, Stack, Typography } from '@mui/material'
import { Orbit, Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { resetManagers } from '@redux/reducers/talentProfileReducer'
import { resetTalentJobs } from '@redux/reducers/talentReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Loader } from 'src/components/loader'
import { TalentForm, iProfileForm, isEditableContext } from 'src/components/talent/form'
import { Paper } from '../job-details/components'
import { Attachment } from '../attachments/attachments'
import CustomFields, { getCustomFieldsDefaultValue } from '../custom-fields'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { FormProvider, useForm } from 'react-hook-form'
import { TalentJobCards } from './job-cards'
import useDirectoryQuery from '@utils/hooks/useDirectoryQuery'
import { useTalentQuery } from '@utils/query/useTalent'
import { NotFound } from '../errors'

export const BreakpointContext = createContext({ isMobileWidth: false })

const TALENT_JOB_SORT_OPTIONS = [
  { value: { sort_by: 'created_at', sort_order: 'asc' }, label: 'Sort Jobs by Most Recent' },
  { value: { sort_by: 'created_at', sort_order: 'desc' }, label: 'Sort Jobs by Oldest' },
  { value: { sort_by: 'name', sort_order: 'asc' }, label: 'Sort Jobs by Name Ascending' },
  { value: { sort_by: 'name', sort_order: 'desc' }, label: 'Sort Jobs by Name Descending' },
]

export const Row = ({
  children,
  noBorder = false,
  ...props
}: { children: JSX.Element | JSX.Element[]; noBorder?: boolean } & Grid2Props) => {
  const { state: isEditable, setState } = useContext(isEditableContext)
  const { isMobileWidth } = useContext(BreakpointContext)

  return (
    <Grid
      container
      onClick={() => setState(true)}
      sx={{
        cursor: isEditable ? '' : 'pointer',
        borderBottom: {
          sm: noBorder ? 'none' : `1px solid ${GlobalStyles.SLATE_100}`,
        },
      }}
      boxSizing="border-box"
      {...props}>
      {children}
    </Grid>
  )
}

export const Managers = ({ label, managers }: { label: string; managers: Types.Talent.iTalentManager[] }) => {
  const { isMobileWidth } = useContext(BreakpointContext)

  const [showAll, setshowAll] = useState(false)

  return (
    <Grid item xxs={12} lg={isMobileWidth ? 12 : 6}>
      <Stack rowGap="12px">
        <Stack direction="row" columnGap="4px">
          <Typography color={GlobalStyles.SLATE_700} fontSize="14px" fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM}>
            {label}
          </Typography>
          <Tooltip text="" />
        </Stack>
        <Stack
          border={`1px solid ${GlobalStyles.SLATE_100}`}
          borderRadius="6px"
          sx={{
            '>div': {
              borderBottom: `1px solid ${GlobalStyles.SLATE_100}`,
            },
            '>div:last-child': {
              borderBottom: 'none',
            },
          }}>
          {managers.length ? (
            <>
              {managers.slice(0, showAll ? managers.length : 4).map(({ eid, name, profile_image, management }) => (
                <Stack direction="row" key={eid} padding="16px" columnGap="6px">
                  <Avatar
                    // size="md"
                    src={profile_image?.full_url}
                    // placeHolder={
                    //     <Icon.User size={GlobalStyles.AVATAR_SIZES.XS - 5} color={GlobalStyles.SLATE_200} strokeWidth={1} />
                    // }
                  />
                  <Stack>
                    <Typography color={GlobalStyles.MONO_BLACK} fontSize={`${GlobalStyles.FONT_SIZES.BASE}px`} fontWeight={500}>
                      {name}
                    </Typography>
                    <Typography color={GlobalStyles.SLATE_700} textTransform="capitalize">
                      {management?.management_level.toLowerCase()}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
              {managers.length > 4 &&
                (showAll ? (
                  <Button
                    variant="text"
                    sx={{ alignSelf: 'baseline', padding: '16px 24px', columnGap: '24px' }}
                    onClick={() => setshowAll(false)}>
                    Hide some managers <Icon.ChevronsUp size={24} />
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={{ alignSelf: 'baseline', padding: '16px 24px', columnGap: '24px' }}
                    onClick={() => setshowAll(true)}>
                    Show all managers <Icon.ChevronsDown size={24} />
                  </Button>
                ))}
            </>
          ) : (
            <Stack padding={{ xxs: '12px', sm: '24px' }}>
              <Typography color={GlobalStyles.SLATE_500} fontSize="14px" fontWeight={500}>
                {`All your ${label.toLowerCase()} will be listed here`}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Grid>
  )
}

export type tTalentProfile = {
  talentId?: string
  isMobileWidth?: boolean
  onSuccess?: () => void
  agreementEl?: JSX.Element
  jobs?: Types.Talent.iTalentJobsParams[]
}

export const TalentProfile = ({ talentId, isMobileWidth = false, onSuccess, agreementEl }: tTalentProfile) => {
  const { query, ...router } = useRouter()

  const dispatch = useAppDispatch()
  const { workspace, settings } = useAppSelector(selectWorkspace)

  const { data: details, status: talentStatus } = useTalentQuery({ eid: talentId as string })
  const managers = useMemo(() => details?.active_managers || [], [details?.active_managers])

  const { features } = useContext(FeatureFlagsContext)
  const { toggleModal, options: modalOptions } = useContext(ModalContext)

  const [sorting, setSorting] = useState(TALENT_JOB_SORT_OPTIONS[0])
  const [isDirty, setIsDirty] = useState(false)

  const [isEditable, setIsEditable] = useState(!talentId)
  const handleSetIsEditable = (editable: boolean) => !agreementEl && setIsEditable(editable)

  const { profileManagers, profileOversight } = useMemo(() => {
    let profileManagers: Types.Talent.iTalentManager[] = []
    let profileOversight: Types.Talent.iTalentManager[] = []

    if (managers && managers.length) {
      managers.forEach((manager) => {
        if (manager.management?.management_level !== 'OVERSIGHT') {
          profileManagers.push(manager)
        } else {
          profileOversight.push(manager)
        }
      })
    }
    return { profileManagers, profileOversight }
  }, [managers])

  const customFieldsDefaultValue = useCallback(() => {
    return getCustomFieldsDefaultValue('talent', settings?.custom_field_settings || [], details?.custom_field_data || {})
  }, [settings, details])

  const form = useForm<iProfileForm>({
    defaultValues: {
      ...details,
      custom_fields: customFieldsDefaultValue(),
    },
  })

  const paddingX = { xxs: '12px', sm: isMobileWidth ? '12px' : '24px' }

  const {
    data: jobs,
    paginationData,
    queryParams,
    setQueryParams,
  } = useDirectoryQuery<Types.Talent.iTalentJobsParams[]>({
    key: 'listTalentJob',
    dataFetchFn: (params: Types.Talent.iTalentJobsParams) => Orbit.Services.talentService.listTalentJobs(talentId || '', params),
    context: 'talentJob',
    per_page: 20,
    enabled: !!talentId,
  })

  const getBreakpointValues = ({ xxs, sm }: { xxs: string | number; sm: string | number }) => ({
    xxs,
    sm: isMobileWidth ? xxs : sm,
  })

  useEffect(() => {
    return () => {
      dispatch(resetTalentJobs())
      dispatch(resetManagers())
    }
  }, [dispatch])

  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...sorting.value }))
  }, [sorting])

  useEffect(() => {
    if (details || (details && isDirty && !isEditable)) {
      form.reset({
        ...details,
        custom_fields: customFieldsDefaultValue(),
      })
    }
  }, [details, form, customFieldsDefaultValue, isEditable, isDirty])

  if (!router.pathname.includes('create')) {
    if (talentStatus === 'pending') {
      return <Loader />
    } else if (talentStatus === 'error') {
      return <NotFound />
    }
  }

  return (
    <BreakpointContext.Provider value={{ isMobileWidth }}>
      <Stack rowGap={getBreakpointValues({ xxs: '38px', sm: '10px' })} paddingX={isMobileWidth ? '24px' : 0} paddingBottom="24px">
        <FormProvider {...form}>
          <isEditableContext.Provider value={{ state: isEditable, setState: handleSetIsEditable }}>
            <TalentForm onSuccess={onSuccess} talentId={talentId} agreementEl={agreementEl} />
            {!router.pathname.includes('create') && (
              <Paper>
                <Row paddingY="22px">
                  <Stack direction={{ sm: isMobileWidth ? 'column' : 'row' }} alignItems={{ sm: 'center' }} paddingX={paddingX} gap="12px">
                    <Stack direction="row" alignItems="center" columnGap="12px">
                      <Icon.Users size={20} color={GlobalStyles.SLATE_500} />
                      <Typography
                        color={GlobalStyles.SLATE_500}
                        fontSize={`${GlobalStyles.FONT_SIZES.BASE}px`}
                        fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM}>
                        PROFILE MANAGEMENT
                      </Typography>
                      <Box display={{ xxs: 'none', sm: 'initial' }}>
                        <Tooltip text="" />
                      </Box>
                    </Stack>
                    <Button
                      variant="outlined"
                      sx={{ columnGap: '8px' }}
                      onClick={() =>
                        toggleModal(ModalPages.PROFILE_MANAGEMENT, {
                          modalProps: {
                            ...modalOptions,
                            [ModalPages.PROFILE_MANAGEMENT]: {
                              name: details?.display_name,
                              eid: talentId,
                            },
                          },
                        })
                      }>
                      <Icon.PlusCircle size={16} />
                      Add Profile Management Roles
                    </Button>
                  </Stack>
                </Row>
                <Grid
                  container
                  paddingY="12px"
                  paddingX={getBreakpointValues({ xxs: '12px', sm: '24px' })}
                  columnSpacing="48px"
                  rowGap="14px">
                  <Managers label="Profile Managers" managers={profileManagers} />
                  <Managers label="Profile Oversight" managers={profileOversight} />
                </Grid>
              </Paper>
            )}
            {!agreementEl && <Attachment talentEid={query.talentId as string} files={details?.files} />}

            {features && !!settings?.custom_field_settings?.length && (
              <CustomFields
                section="talent"
                columnNo={isMobileWidth ? 1 : 2}
                defaultValues={details?.custom_field_data}
                isEdit={isEditable}
                onInputFieldClick={() => handleSetIsEditable(true)}
              />
            )}

            {query.talentId && (
              <>
                <Stack direction="row" padding={{ xxs: '0 12px', sm: '22px 24px' }} justifyContent="space-between">
                  <Stack direction="row" columnGap="4px" alignItems="center">
                    <Icon.Clock color={GlobalStyles.SLATE_500} size={16} />
                    <Typography color={GlobalStyles.SLATE_500}>JOB HISTORY</Typography>
                    <Tooltip text="" />
                  </Stack>
                  <DropdownV2
                    title={sorting.label}
                    menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
                    {TALENT_JOB_SORT_OPTIONS.map((option) => (
                      <DropDownItemV2
                        key={option.label}
                        onClick={() => {
                          setSorting(option)
                          trackEvent({
                            event: TrackingEventEnums.Others.SORT,
                            eventProperties: { workspace, context: 'Talent Profile Job History', sorting },
                          })
                        }}>
                        {option.label}
                      </DropDownItemV2>
                    ))}
                  </DropdownV2>
                </Stack>
                <Stack rowGap="10px" role="list" aria-label="job list">
                  {router.pathname.includes('talent-profile') && <TalentJobCards talentId={talentId} jobs={jobs || []} />}
                </Stack>
                <Stack alignItems="center" width="100%">
                  {jobs && !!jobs?.length && (
                    <Pagination
                      page={queryParams.page}
                      onChange={(_, page) => {
                        router.push({ query: { ...query, page } }, undefined, { shallow: true })
                        setQueryParams((q) => ({ ...q, page }))
                      }}
                      count={paginationData?.totalPage}
                    />
                  )}
                </Stack>
              </>
            )}
          </isEditableContext.Provider>
        </FormProvider>
      </Stack>
    </BreakpointContext.Provider>
  )
}
